import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { AccountProjectsListEmpty, AccountProjectsListPagination, AccountProjectsListInfo, AccountProjectsListFooter } from '~/components/account/accountProjects'
import { AccountProjectsItems } from '~/components/account/accountProjectsItems'
import { AccountHeader } from '~/components/account/accountHeader'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { getCustomerPlannerProjects } from '~/actions/account'
import { INITIAL_PAGINATION_PARAMS } from '~/hooks/pagination'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'

const AccountProjectsList = (): ReactElement => {
	const { t } = useTranslation(['account'])
	const { content, pageable } = useSelector((state: IRootState) => state.account.projects.list, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const { preventAlert } = useAlert()
	const { sendLogError } = useLogError()

	const { totalElements } = pageable

	useEffect(() => {
		(async () => {
			try {
				await dispatch(getCustomerPlannerProjects(INITIAL_PAGINATION_PARAMS))
			} catch (e: unknown) {
				preventAlert(e)
				sendLogError(e)
			}
		})()
	}, [])

	const renderContent = useCallback((): ReactElement => {
		if (isEmpty(content)) {
			return (
				<>
					<AccountProjectsListInfo />

					<AccountProjectsListEmpty />

					<AccountProjectsListFooter />
				</>
			)
		}

		return (
			<>
				<AccountProjectsListInfo />

				<AccountProjectsItems items={ content } />

				<AccountProjectsListPagination />

				<AccountProjectsListFooter />
			</>
		)
	}, [content])

	return (
		<>
			<AccountHeader counter={ totalElements } title={ t('projects.list.pageHeader') } />

			{ renderContent() }
		</>
	)
}

export { AccountProjectsList }
