import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isUndefined } from 'lodash'

import { PRODUCT_LIST_PER_PAGE_DEFAULT_VALUE, PRODUCT_LIST_PER_PAGE_OPTIONS } from '~/components/productList/constants'
import { IProductListSortAndPaginationProps } from '~/components/productList/productListSortAndPagination'
import { ListingSort } from '~/components/core/listing'
import { PerPage } from '~/components/core/perPage'
import { PaginationSmall } from '~/components/core/paginationSmall'
import { useProductList } from '~/hooks/productList'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'

import styles from './ProductListSortAndPagination.module.scss'

const ProductListSortAndPagination = (props: IProductListSortAndPaginationProps): ReactElement => {
	const {
		pageable, sortOptions, node, onChangePage,
		templateUrl = '',
		params = {},
	} = props
	const { t } = useTranslation(['productList'])
	const { getProductListData, handleSetIsPending, getPaginationUrl, getPaginationUrls } = useProductList()

	const { pageNumber, totalPages } = pageable

	const handleTemplateUrl = useCallback((page: number): string => (
		getPaginationUrl(page, templateUrl)
	), [templateUrl])

	const paginationUrls = getPaginationUrls(handleTemplateUrl(pageNumber), node?.wwwUrl || '')

	const selectedPageSize: number = !isUndefined(params) && params.size ? params.size : PRODUCT_LIST_PER_PAGE_DEFAULT_VALUE

	const handleChangePageSize = useCallback(async (size: number): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			size,
			page: 1,
		}

		await getProductListData(newFilterParams)
	}, [getProductListData])

	return (
		<div className={ styles.wrapper }>
			<PerPage
				label={ t('pagination.results') }
				options={ PRODUCT_LIST_PER_PAGE_OPTIONS }
				value={ selectedPageSize }
				onChange={ handleChangePageSize }
			/>

			<ListingSort
				sortOptions={ sortOptions }
				nodeId={ node?.id }
				onSetIsPending={ handleSetIsPending }
			/>

			<PaginationSmall
				paginationUrls={ [paginationUrls.prev, paginationUrls.next] }
				initialPage={ pageNumber }
				totalPages={ totalPages }
				additionalClass={ styles.pagination }
				onPageChange={ onChangePage }
			/>
		</div>
	)
}

export { ProductListSortAndPagination }
