import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { ProductListContext } from '~/providers/productListProvider'
import { IProductListData } from '~/components/productList/types'
import { IProductListHookData } from '~/hooks/productList'
import { SearchListContext } from '~/providers/searchProvider'
import { usePagination } from '~/hooks/pagination'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'
import { getCatalogSearchProducts, getCatalogSearchProductsFilters, getCatalogSearchSeries } from '~/api/requests/productList'

export const useProductList = (isSearchView: boolean = false): IProductListHookData => {
	const productListContextData = useContext(isSearchView ? SearchListContext : ProductListContext)
	const { getPaginationUrl, getPaginationUrls } = usePagination({ pageable: productListContextData.itemPage.pageable })
	const router = useRouter()

	const { handleSetData, handleSetIsPending, isPending, params, catalogChildNodeData } = productListContextData

	const getSearchProductsData = useCallback(async (params: ISearchProductsParams): Promise<IProductListData> => {
		const [{ data }, { data: filtersData }] = await Promise.all([
			getCatalogSearchProducts(false, params),
			getCatalogSearchProductsFilters(false, params),
		])

		let productListData: IProductListData = { ...data, params, filters: filtersData, catalogChildNodeData }

		if (data.seriesCount > 0) {
			const { data: seriesData } = await getCatalogSearchSeries(false, params)

			productListData = { ...productListData, series: seriesData }
		}

		return productListData
	}, [catalogChildNodeData])

	const getProductListData = useCallback(async (newFilterParams: ISearchProductsParams, withChangeUrl: boolean = true): Promise<void> => {
		const filterParams: ISearchProductsParams = {
			...params,
			...newFilterParams,
		}

		handleSetIsPending(true)

		const productListData = await getSearchProductsData(filterParams)
		const { wwwUrl } = productListData.searchData

		if (withChangeUrl) {
			await router.push(wwwUrl)
		}

		handleSetData({ ...productListData, catalogChildNodeData })

		handleSetIsPending(false)
	}, [params, catalogChildNodeData, handleSetData, handleSetIsPending])

	return {
		getProductListData,
		isPending,
		handleSetIsPending,
		productListData: productListContextData,
		getPaginationUrl,
		getPaginationUrls,
	}
}
