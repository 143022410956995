import React, { ReactElement, useCallback, useEffect } from 'react'
import { useSearchParams } from 'next/navigation'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { Input } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { IAccountProjectsListAddingFormProps, INPUT_NAME } from '~/components/account/accountProjects'
import { usePlannerProject } from '~/hooks/plannerIframe'
import { useMozaic } from '~/hooks/mozaic'

import styles from './AccountProjectsListAddingForm.module.scss'

const AccountProjectsListAddingForm = (props: IAccountProjectsListAddingFormProps): ReactElement => {
	const {
		theme = {},
	} = props
	const { t } = useTranslation(['account'])
	const { addProjectFormControl, setProjectFormValue, handleAddProjectFormSubmit } = usePlannerProject()
	const { getShouldUseMozaicFlag } = useMozaic()
	const searchParams = useSearchParams()
	const { replace } = useRouter()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const search = searchParams.get(INPUT_NAME)

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)
	const titleClass = classNames(styles.title, theme.title, {
		[styles.isMozaic]: shouldUseMozaic,
	})
	const inputClass = classNames(styles.input, theme.input)
	const buttonClass = classNames(styles.button, theme.button)
	const informationClass = classNames(styles.information, theme.information)

	const handleAddProjectWithQueryParam = useCallback(() => {
		if (!isNull(search)) {
			setProjectFormValue(INPUT_NAME, search)
			handleAddProjectFormSubmit()

			replace(window.location.pathname, undefined, { shallow: true })
		}
	}, [search, replace, setProjectFormValue, handleAddProjectFormSubmit])

	useEffect(() => {
		handleAddProjectWithQueryParam()
	}, [])

	return (
		<div className={ wrapperClass }>
			<div className={ titleClass }>
				{ t('projects.list.addProjectByNumber') }
			</div>

			<Input
				label={ t('projects.list.projectNumber') }
				control={ addProjectFormControl }
				name={ INPUT_NAME }
				additionalClass={ inputClass }
			/>

			<Button
				text={ t('projects.list.addProjectToList') }
				variant="secondary"
				additionalClass={ buttonClass }
				onClick={ handleAddProjectFormSubmit }
			/>

			<div className={ informationClass }>
				{ t('projects.list.addProjectDescription') }
			</div>
		</div>
	)
}

export { AccountProjectsListAddingForm }
