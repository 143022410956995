import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { FieldValues } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'
import classNames from 'classnames'
import { isNull, noop } from 'lodash'

import { PreferredStoreModal } from '~/components/core/preferredStore'
import { IListingFiltersItemProps } from '~/components/core/listing'
import { Checkbox } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { IRootState } from '~/state/types'
import { useMounted } from '~/hooks/mounted'
import { useModal } from '~/hooks/modal'

import styles from './ListingFiltersItem.module.scss'

const ListingFiltersItem = <FormFields extends FieldValues = FieldValues>(props: IListingFiltersItemProps<FormFields>): ReactElement | null => {
	const { field, name: inputName, control, tabIndex } = props
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { t } = useTranslation(['common'])
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')
	const { isMounted } = useMounted()

	const { disabled, count, name, selected } = field
	const showChooseStoreButton = isNull(preferredStore) && isNull(count)
	const isCheckboxDisabled = disabled && !selected

	const handleOpenStoreSelectionModal = useCallback((): void => (
		showChooseStoreButton ? handleOpen() : noop()
	), [showChooseStoreButton])

	const labelClass = classNames(styles.label, {
		[styles.disabled]: isCheckboxDisabled,
	})

	if (!isMounted) {
		return null
	}

	return (
		<>
			<Checkbox
				name={ inputName }
				control={ control }
				isDisabled={ isCheckboxDisabled }
				theme={ {
					wrapper: styles.wrapper,
					label: styles.labelWrapper,
				} }
				tabIndex={ tabIndex }
			>
				<div>
					<span className={ labelClass }>
						{ name }{ ' ' }
					</span>

					{ !isNull(count) && (
						<span className={ styles.count }>
							({ count })
						</span>
					) }

					{ showChooseStoreButton && (
						<Button
							variant="neutral"
							additionalClass={ styles.chooseStore }
							onClick={ handleOpenStoreSelectionModal }
						>
							({ t('preferredShop.chooseYourStore') })
						</Button>
					) }
				</div>
			</Checkbox>

			{ showChooseStoreButton && <PreferredStoreModal isOpen={ isOpen } onClose={ handleClose } /> }
		</>
	)
}

export { ListingFiltersItem }
