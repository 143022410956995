import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { Collapse } from 'react-collapse'
import { map } from 'lodash'

import { ISearchResultsCategoriesProps, SearchResultsCategoriesItem } from '~/components/search'
import { ListingAsideHeader } from '~/components/core/listing'
import { ICatalogSearchCategoryData } from '~/api/dataTypes/catalog'

import styles from './SearchResultsCategories.module.scss'

const SearchResultsCategories = (props: ISearchResultsCategoriesProps): ReactElement => {
	const { categories } = props
	const { t } = useTranslation(['search'])
	const [isOpen, setIsOpen] = useToggle(true)

	const handleToggleOpen = useCallback((): void => {
		setIsOpen()
	}, [])

	const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

	const renderCategories = useCallback((): ReactElement[] => (
		map(categories, (category: ICatalogSearchCategoryData) => {
			const { id } = category

			return (
				<SearchResultsCategoriesItem key={ id } category={ category } />
			)
		})
	), [categories])

	return (
		<div className={ styles.wrapper }>
			<ListingAsideHeader
				isOpen={ isOpen }
				title={ t('results.categories.title') }
				additionalClass={ styles.header }
				onToggleOpen={ handleToggleOpen }
			/>

			<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
				<ul className={ styles.categories }>
					{ renderCategories() }
				</ul>
			</Collapse>
		</div>
	)
}

export { SearchResultsCategories }
