import { MultiValue, SingleValue } from 'react-select'
import { find, isArray, map } from 'lodash'

import { ISelectOption } from '~/components/core/form'

export const createSelectOptions = (options: number[]): ISelectOption[] => (
	map(options, (option: number): ISelectOption => ({ label: `${ option }`, value: `${ option }` }))
)

export const createSelectedOption = (selectOptions: ISelectOption[], currentValue: number): ISelectOption | undefined => (
	find(selectOptions, (option: ISelectOption) => option.value === `${ currentValue }`)
)

export const isMultiValue = (value: SingleValue<ISelectOption> | MultiValue<ISelectOption>): value is MultiValue<ISelectOption> => (
	isArray(value)
)
