import React, { ReactElement } from 'react'

import { IProductListContentWrapperProps } from '~/components/productList/productListContentWrapper'

import styles from './ProductListContentWrapper.module.scss'

const ProductListContentWrapper = (props: IProductListContentWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { ProductListContentWrapper }
