import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import { isUndefined, isNull } from 'lodash'

import { IRootState } from '~/state/types'
import { ProductListDescription } from '~/components/productList/productListDescription'
import { ProductListWrapper } from '~/components/productList/productListWrapper'
import { ProductListAside } from '~/components/productList/productListAside'
import { ProductListContentWrapper } from '~/components/productList/productListContentWrapper'
import { ProductListContent } from '~/components/productList/productListContent'
import { SeoDescription } from '~/components/core/seoDescription'
import { ProductListRelatedCategories } from '~/components/productList/productListRelatedCategories'
import { tmpSeriesProductListMapper } from '~/components/productList/helpers'
import { ProductListFiltersButton } from '~/components/productList/productListFilters'
import { ProductListCab } from '~/components/productList/productListCab'
import { ProductListLoader } from '~/components/productList/productListLoader'
import { ProductListSeoFiltersCloud } from '~/components/productList/productListSeoFiltersCloud'
import { Container } from '~/components/core/layout/container'
import { SeriesCarousel } from '~/components/core/seriesCarousel'
import { WatermarkPlaceholder } from '~/components/core/watermarkPlaceholder'
import { SkipContentAnchor, SkipContentList } from '~/components/core/skipContent'
import { Breadcrumbs } from '~/components/core/breadcrumbs'
import { PRODUCT_LIST_MOCK } from '~/mocks/productListMock'
import { ISeriesItemData } from '~/api/dataTypes/series'
import { ProductListFiltersModalProvider } from '~/providers/productListFiltersModalProvider'
import { getBreadcrumbs } from '~/utils/breadcrumbs'
import { SKIP_LISTING, SKIP_LISTING_BREADCRUMBS, SKIP_LISTING_FILTERS, SKIP_LISTING_PRODUCTS, useSkipElements } from '~/hooks/skipElements'
import { useProductList } from '~/hooks/productList'
import { getGoogleAnalyticsImpressionFromCatalog, useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { PageType } from '~/statics'
import { useSare } from '~/hooks/sare'

const ProductList = (): ReactElement => {
	const { t } = useTranslation(['productList'])
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { getProductListData, productListData } = useProductList()
	const { productListSkip } = useSkipElements()
	const { GA_contentGroup, GA_impressions } = useGoogleAnalytics()
	const { SARE_productsView } = useSare()

	const { levelOneHeader, node, series, filters, params, cabNavigation, breadCrumb, isPending, catalogPath, searchData: { wwwUrl } } = productListData

	const { relatedCategories } = PRODUCT_LIST_MOCK
	const breadcrumbs = getBreadcrumbs(breadCrumb)
	const pageType: PageType = 'PRODUCT_LIST'

	// todo do usunięcia po uspójnieniu modelu PLEDEV-3143
	const tmpSeriesList: ISeriesItemData[] = !isUndefined(series) ? tmpSeriesProductListMapper(series.productSeriesPage.content, node) : []

	useUpdateEffect(() => {
		(async () => {
			await getProductListData(params, false)
		})()
	}, [preferredStore?.storeCode])

	useEffect(() => {
		GA_contentGroup(pageType, catalogPath)
	}, [catalogPath])

	useEffect(() => {
		GA_impressions(getGoogleAnalyticsImpressionFromCatalog(productListData.itemPage, wwwUrl))
		SARE_productsView(productListData.itemPage)
	}, [wwwUrl])

	return (
		<Container>
			<SkipContentList skipElements={ productListSkip } />

			<ProductListLoader isLoading={ isPending } />

			<Breadcrumbs breadcrumbs={ breadcrumbs } />

			<SkipContentAnchor elementId={ SKIP_LISTING_BREADCRUMBS } />

			<SkipContentAnchor elementId={ SKIP_LISTING_FILTERS } />

			<ProductListFiltersModalProvider>
				<ProductListWrapper>
					<ProductListAside
						node={ node }
						params={ params }
						initialFilters={ filters }
					/>

					<ProductListContentWrapper>
						{ !isNull(node) && <ProductListDescription title={ levelOneHeader } description={ node.description } /> }

						<ProductListFiltersButton />

						<ProductListCab cabNavigation={ cabNavigation } />

						<SkipContentAnchor elementId={ SKIP_LISTING_PRODUCTS } />

						<ProductListContent productList={ productListData } />
					</ProductListContentWrapper>
				</ProductListWrapper>
			</ProductListFiltersModalProvider>

			<SkipContentAnchor elementId={ SKIP_LISTING } />

			<SeriesCarousel title={ t('series.title') } series={ tmpSeriesList } />

			{ !isUndefined(filters) && (
				<ProductListSeoFiltersCloud items={ filters.filterLinkList } />
			) }

			{ !isNull(node) && !isUndefined(node.seoDescription) && (
				<SeoDescription text={ node.seoDescription } />
			) }

			<WatermarkPlaceholder text="Post MVP" size="md">
				<ProductListRelatedCategories categories={ relatedCategories } />
			</WatermarkPlaceholder>
		</Container>
	)
}

export { ProductList }
