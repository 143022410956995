import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { Collapse } from 'react-collapse'

import { IProductListFiltersCollapseProps } from '~/components/productList/productListFilters'
import { ProductListFiltersProvider } from '~/providers/productListFiltersProvider'

import styles from './ProductListFiltersCollapse.module.scss'

const ProductListFiltersCollapse = (props: IProductListFiltersCollapseProps): ReactElement => {
	const { isOpen, children } = props

	const collapseClass = classNames('ReactCollapse--collapse', styles.wrapper)

	return (
		<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
			<ProductListFiltersProvider isParentOpen={ isOpen }>
				{ children }
			</ProductListFiltersProvider>
		</Collapse>
	)
}

export { ProductListFiltersCollapse }
