import React, { ReactElement, useCallback, useContext } from 'react'

import { useMounted } from '~/hooks/mounted'
import { PlannerContext } from '~/providers/plannerProvider'
import { Container } from '~/components/core/layout/container'
import { Breadcrumbs } from '~/components/core/breadcrumbs'
import { BREADCRUMBS, PlannerAvailability, PlannerIframe, PlannerScreenSaver } from '~/components/planner'

import styles from './Planner.module.scss'

const Planner = (): ReactElement => {
	const { isMounted } = useMounted()
	const { isScreenSaverVisible, isShopVersion } = useContext(PlannerContext)

	const renderScreenSaver = useCallback((): ReactElement | null => {
		if (!isScreenSaverVisible) {
			return null
		}

		return (
			<PlannerScreenSaver />
		)
	}, [isMounted, isScreenSaverVisible])

	return (
		<>
			{ !isShopVersion && (
				<Container additionalClass={ styles.wrapper }>
					<Breadcrumbs breadcrumbs={ BREADCRUMBS } />

					<PlannerAvailability />

					<div className={ styles.content }>
						{ renderScreenSaver() }
					</div>
				</Container>
			) }

			<PlannerIframe />
		</>
	)
}

export { Planner }
