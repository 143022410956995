import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ISearchResultsCategoriesHeaderProps } from '~/components/search'
import { Link } from '~/components/core/link'
import { Icon } from '~/components/core/icon'

import styles from './SearchResultsCategoriesHeader.module.scss'

const SearchResultsCategoriesHeader = (props: ISearchResultsCategoriesHeaderProps): ReactElement => {
	const { category, isOpen } = props

	const { name, wwwUrl, count, selected } = category

	const iconClass = classNames({
		[styles.icon]: true,
		[styles.open]: isOpen,
	})

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: selected,
	})

	return (
		<Link
			passHref
			href={ wwwUrl }
			theme={ { wrapper: wrapperClass } }
			ariaLabel={ `${ name } ${ count }` }
		>
			<div>
				<span>
					{ name }
				</span>

				<span className={ styles.count }>
					{ ` (${ count })` }
				</span>
			</div>

			<Icon
				name="arrowFull"
				height={ 10 }
				width={ 8 }
				additionalClass={ iconClass }
			/>
		</Link>
	)
}

export { SearchResultsCategoriesHeader }
