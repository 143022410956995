import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { ISearchResultsProductBlockProps } from '~/components/search'
import { ProductBlock } from '~/components/core/productBlock'

import styles from './SearchResultsProductBlock.module.scss'

const SearchResultsProductBlock = (props: ISearchResultsProductBlockProps): ReactElement | null => {
	const { product, position } = props

	if (isNull(product)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<ProductBlock product={ product } position={ position } />
		</div>
	)
}

export { SearchResultsProductBlock }
