import React, { ReactElement, useCallback, useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'next-i18next'
import { debounce, noop, toNumber, toString } from 'lodash'

import { IPageInputProps } from '~/components/core/paginationSmall'

import styles from './PageInput.module.scss'

export const PageInput = (props: IPageInputProps): ReactElement => {
	const {
		initialValue, totalPages,
		onPageChange = noop,
	} = props
	const [value, setValue] = useState<string>(toString(initialValue))
	const { t } = useTranslation(['common'])

	const setInputValue = (value: number | string): void => setValue(toString(value))

	const handleInputCommitValue = useCallback(debounce((value: number): void => {
		if (!value || value < 0) {
			setInputValue(1)

			return onPageChange(1)
		}

		if (value > totalPages) {
			setInputValue(totalPages)

			return onPageChange(totalPages)
		}

		onPageChange(value)
	}, 500), [onPageChange])

	const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(e.target.value)
		handleInputCommitValue(toNumber(e.target.value))
	}, [handleInputCommitValue])

	useEffect((): void => {
		if (initialValue !== toNumber(value)) {
			setInputValue(initialValue)
		}
	}, [initialValue])

	return (
		<input
			type="text"
			value={ toString(value) }
			className={ styles.input }
			aria-label={ t('pagination.pageOf', { currentPage: value, totalPages }) }
			onChange={ handleInputChange }
		/>
	)
}
