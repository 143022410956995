import React, { useEffect, createContext, useState, useCallback, ReactElement, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isUndefined } from 'lodash'

import { getUpdatedFilters } from '~/providers/productListProvider'
import { IProductListData } from '~/components/productList/types'
import { IRootState } from '~/state/types'
import { INITIAL_SEARCH_LIST_PROVIDER, ISearchProviderContextProps, ISearchProviderProps } from '~/providers/searchProvider'
import { ICatalogSearchProductFilterResponse } from '~/api/dataTypes/catalog'

export const SearchListContext = createContext(INITIAL_SEARCH_LIST_PROVIDER)

export const SearchProvider = (props: ISearchProviderProps): ReactElement => {
	const { children, value } = props
	const [productListData, setProductListData] = useState<IProductListData>(value)
	const [filtersData, setFiltersData] = useState<ICatalogSearchProductFilterResponse | undefined>(value.filters)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const [isPending, setIsPending] = useState<boolean>(false)

	const handleSetIsPending = useCallback((state: boolean): void => {
		setIsPending(state)
	}, [])

	const handleSetData = useCallback((data: IProductListData): void => {
		setProductListData(data)

		setFiltersData(data.filters)
	}, [])

	const providerValue: ISearchProviderContextProps = useMemo(() => {
		const { breadCrumb, searchData, keyword, levelOneHeader, availableSortList, itemPage, node, seoCategoryDescription, seriesCount, series, cabNavigation, articleBanner, catalogPath, redirectUrl, params = {}, catalogChildNodeData } = productListData

		return ({
			breadCrumb,
			searchData,
			keyword,
			levelOneHeader,
			availableSortList,
			itemPage,
			node,
			seoCategoryDescription,
			seriesCount,
			series,
			filters: getUpdatedFilters(preferredStore?.storeCode, filtersData),
			params,
			cabNavigation,
			articleBanner,
			handleSetData,
			isPending,
			catalogPath,
			handleSetIsPending,
			redirectUrl,
			catalogChildNodeData: isUndefined(catalogChildNodeData) ? null : catalogChildNodeData,
		})
	}, [value, productListData, filtersData, isPending, preferredStore?.storeCode])

	useEffect(() => {
		handleSetData(value)
	}, [value])

	return (
		<SearchListContext.Provider value={ providerValue }>
			{ children }
		</SearchListContext.Provider>
	)
}
