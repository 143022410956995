import { isNull, map } from 'lodash'

import { ICatalogSearchProductItemData } from '~/api/dataTypes/catalog'

export const countVisibleSlots = (items: ICatalogSearchProductItemData[]): number => {
	let visibleSlots = 0

	map(items, (item: ICatalogSearchProductItemData) => {
		const { catalogItemType, listingBanner } = item

		if (isNull(listingBanner)) {
			return visibleSlots
		}

		switch (catalogItemType) {
			case 'PRODUCT':
				visibleSlots += 1
				break
			case 'BANNER': {
				const { listingBannerType } = listingBanner
				const isDoubleSlot = listingBannerType === 'CAMPAIGN' || listingBannerType === 'PROMOTING_SUBPAGES'

				visibleSlots += isDoubleSlot ? 2 : 1
				break
			}
		}
	})

	return visibleSlots
}
