import React, { ReactElement } from 'react'

import { AccountProjectsListAddingForm, AccountProjectsListCreateProject } from '~/components/account/accountProjects'

import styles from './AccountProjectsListFooter.module.scss'

const AccountProjectsListFooter = (): ReactElement => {
	return (
		<div className={ styles.wrapper }>
			<AccountProjectsListAddingForm />

			<AccountProjectsListCreateProject />
		</div>
	)
}

export { AccountProjectsListFooter }
