import React, { ReactElement } from 'react'
import { isEmpty } from 'lodash'

import { SeriesCarouselWrapper, SeriesCarouselItems, ISeriesCarouselProps } from '~/components/core/seriesCarousel'
import { SectionHeader } from '~/components/core/sectionHeader'

const SeriesCarousel = (props: ISeriesCarouselProps): ReactElement | null => {
	const {
		title, series,
		dataTestId = '',
	} = props

	if (isEmpty(series)) {
		return null
	}

	return (
		<SeriesCarouselWrapper dataTestId={ dataTestId }>
			<SectionHeader title={ title } />

			<SeriesCarouselItems items={ series } />
		</SeriesCarouselWrapper>
	)
}

export { SeriesCarousel }
