import React, { ReactElement } from 'react'

import { ISearchResultsLoaderProps } from '~/components/search'
import { Loader } from '~/components/core/loader'

import styles from './SearchResultsLoader.module.scss'

const SearchResultsLoader = (props: ISearchResultsLoaderProps): ReactElement | null => {
	const { isLoading } = props

	if (!isLoading) {
		return null
	}

	return (
		<Loader fullPage theme={ { overlay: styles.overlay } } />
	)
}

export { SearchResultsLoader }
