import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isEqual, map, noop } from 'lodash'

import { IPaginationProps, PaginationItem, NextPageButton, PrevPageButton } from '~/components/core/pagination'
import { useMozaic } from '~/hooks/mozaic'

import { getPagesSliceEnd, getPagesSliceStart, getTemplateUrl } from './helpers'

import styles from './Pagination.module.scss'

export const Pagination = (props: IPaginationProps): ReactElement => {
	const {
		totalPages,
		firstPage = false,
		lastPage = false,
		initialPage = 1,
		visiblePages = 5,
		paginationUrls = [],
		templateUrl = undefined,
		baseUrl = '',
		additionalClass = '',
		onPageChange = noop,
	} = props
	const [currentPage, setCurrentPage] = useState(initialPage)
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const pages = Array.from(new Array(totalPages), (_: unknown, index: number) => index + 1)
	const sliceStart = visiblePages >= totalPages ? 0 : getPagesSliceStart(currentPage, totalPages, visiblePages)
	const sliceEnd = getPagesSliceEnd(sliceStart, totalPages, visiblePages)
	const isFirstPage = firstPage ?? currentPage === 1
	const isLastPage = lastPage ?? currentPage === totalPages
	const pagesSlice = pages.slice(sliceStart, sliceEnd)

	const handlePageChange = useCallback((page: number): void => {
		setCurrentPage(page)
		onPageChange(page)
	}, [currentPage, onPageChange])

	const handlePrevPageClick = useCallback((): void => {
		if (!isFirstPage) {
			handlePageChange(currentPage - 1)
		}
	}, [currentPage, isFirstPage, handlePageChange])

	const handleNextPageClick = useCallback((): void => {
		if (!isLastPage) {
			handlePageChange(currentPage + 1)
		}
	}, [currentPage, isLastPage, handlePageChange])

	useEffect((): void => {
		if (currentPage !== initialPage) {
			setCurrentPage(initialPage)
		}
	}, [initialPage])

	const paginationClasses = classNames(styles.pagination, additionalClass, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderPaginationItem = useCallback((): ReactElement[] => (
		map(pagesSlice, (page: number): ReactElement => (
			<PaginationItem
				key={ page }
				value={ page }
				isActive={ page === currentPage }
				url={ getTemplateUrl(page, baseUrl, templateUrl) }
				onClick={ handlePageChange }
			/>
		))
	), [currentPage, baseUrl, handlePageChange, templateUrl])

	return (
		<div className={ paginationClasses }>
			{ !isFirstPage && (
				<PrevPageButton url={ paginationUrls[0] } onClick={ handlePrevPageClick } />
			) }

			{ renderPaginationItem() }

			<span className={ styles.totalPagesText }>
				{ t('pagination.of') }
			</span>

			<PaginationItem
				value={ totalPages }
				isActive={ isEqual(currentPage, totalPages) }
				url={ getTemplateUrl(totalPages, baseUrl, templateUrl) }
				onClick={ handlePageChange }
			/>

			{ !isLastPage && (
				<NextPageButton url={ paginationUrls[1] } onClick={ handleNextPageClick } />
			) }
		</div>
	)
}
