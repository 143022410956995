import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { gt, isNull } from 'lodash'

import { IProductListCategoriesItemProps } from '~/components/productList/productListCategories'
import { Link } from '~/components/core/link'

import styles from './ProductListCategoriesItem.module.scss'

const ProductListCategoriesItem = (props: IProductListCategoriesItemProps): ReactElement => {
	const { wwwUrl, count, name, isActive } = props
	const { t } = useTranslation(['productList'])

	const isCountVisible = !isNull(count) && gt(count, 0)

	const itemClass = classNames({
		[styles.item]: true,
		[styles.active]: isActive,
	})

	return (
		<li>
			<Link
				href={ wwwUrl }
				theme={ { wrapper: itemClass } }
				ariaLabel={ `${ t('wcag.productCategoryPrefix') } ${ name } ${ isCountVisible ? count : '' }` }
			>
				<span>
					{ `${ name } ` }
				</span>

				{ isCountVisible && (
					<span className={ styles.count }>
						({ count })
					</span>
				) }
			</Link>
		</li>
	)
}

export { ProductListCategoriesItem }
