import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import ReactSelect, { ControlProps, SingleValue, components as ReactSelectComponents } from 'react-select'
import { find, isNull, isUndefined, upperFirst } from 'lodash'

import { IListingSortProps, buildSelectSortOptions } from '~/components/core/listing'
import { ISelectOption } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { IProductListSortOption } from '~/mocks/types'
import { useDropdownSelect } from '~/hooks/dropdownSelect'
import { useProductList } from '~/hooks/productList'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'

import styles from './ListingSort.module.scss'

const ListingSort = (props: IListingSortProps): ReactElement => {
	const {
		sortOptions,
		isSearchView = false,
	} = props
	const { t } = useTranslation(['form'])
	const [sortValue, setSortValue] = useState<ISelectOption | null>(null)
	const { getProductListData } = useProductList(isSearchView)
	const { handleFocusAria, guidance } = useDropdownSelect()

	const options: ISelectOption[] = buildSelectSortOptions(sortOptions)

	const handleChange = useCallback(async (selectedOption: SingleValue<ISelectOption>): Promise<void> => {
		if (!isNull(selectedOption)) {
			const activeSortOption: IProductListSortOption | undefined = find(sortOptions, (option: IProductListSortOption) => option.sortOption === selectedOption.value)

			if (!isUndefined(activeSortOption)) {
				const activeOption = find(options, (option: ISelectOption) => option.value === activeSortOption.sortOption)

				if (!isUndefined(activeOption)) {
					setSortValue(activeOption)
				}

				const newFilterParams: ISearchProductsParams = {
					sort: activeSortOption.sortOption,
				}

				await getProductListData(newFilterParams)
			}
		}
	}, [sortOptions, getProductListData])

	const setInitialSortOption = useCallback((): void => {
		const activeSortOption: IProductListSortOption | undefined = find(sortOptions, (option: IProductListSortOption) => option.active)

		if (activeSortOption) {
			const activeOption = find(options, (option: ISelectOption) => option.value === activeSortOption.sortOption)

			if (!isUndefined(activeOption)) {
				setSortValue(activeOption)
			}
		}
	}, [sortOptions, options])

	const renderCustomControl = useCallback((props: ControlProps<ISelectOption>): ReactElement => {
		const { children } = props

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<ReactSelectComponents.Control { ...props }>
				<Button
					variant="neutral"
					size="inherit"
					additionalClass={ styles.controlButton }
					ariaLabel={ t('select.placeholder') }
					isContentAriaHidden={ false }
				>
					{ children }
				</Button>
			</ReactSelectComponents.Control>
		)
	}, [])

	useEffect(() => {
		setInitialSortOption()
	}, [sortOptions])

	const selectClass = classNames({
		[styles.select]: true,
		'react-select': true,
	})

	return (
		<div className={ styles.wrapper }>
			<label htmlFor="react-select-product-list-sort-input" className={ styles.label }>
				{ t('sortOptions.label') }
			</label>

			<ReactSelect
				instanceId="product-list-sort"
				isMulti={ false }
				isSearchable={ false }
				options={ options }
				className={ selectClass }
				classNamePrefix="react-select"
				getOptionLabel={ (option: ISelectOption) => upperFirst(option.label) }
				components={ {
					IndicatorSeparator: () => null,
					Control: renderCustomControl as typeof ReactSelectComponents.Control,
				} }
				ariaLiveMessages={ {
					onFocus: handleFocusAria,
					guidance,
				} }
				placeholder={ t('select.placeholder') }
				value={ sortValue }
				onChange={ handleChange }
			/>
		</div>
	)
}

export { ListingSort }
