import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { ILastViewedProductsProps, LastViewedProductsWrapper } from '~/components/core/lastViewedProducts'
import { ProductsCarousel } from '~/components/core/productsCarousel'
import { SectionHeader } from '~/components/core/sectionHeader'
import { IRootState } from '~/state/types'
import { initLastViewedProducts } from '~/actions/lastViewed'
import { AppDispatch } from '~/state/store'

const LastViewedProducts = (props: ILastViewedProductsProps): ReactElement | null => {
	const {
		dataTestId = '',
	} = props
	const { products } = useSelector((state: IRootState) => state.lastViewed, shallowEqual)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const { t } = useTranslation(['common'])

	useEffect(() => {
		(async () => {
			await dispatch(initLastViewedProducts())
		})()
	}, [preferredStore?.storeCode])

	if (isEmpty(products)) {
		return null
	}

	return (
		<LastViewedProductsWrapper dataTestId={ dataTestId }>
			<SectionHeader title={ t('lastViewed.caption') } />

			<ProductsCarousel products={ products } />
		</LastViewedProductsWrapper>
	)
}

export { LastViewedProducts }
