import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isUndefined } from 'lodash'

import { ISearchResultsPaginationProps, SEARCH_RESULTS_PER_PAGE_OPTIONS, SEARCH_RESULTS_PER_PAGE_DEFAULT_VALUE } from '~/components/search'
import { Pagination } from '~/components/core/pagination'
import { PaginationSmall } from '~/components/core/paginationSmall'
import { PerPage } from '~/components/core/perPage'
import { useProductList } from '~/hooks/productList'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'

import styles from './SearchResultsPagination.module.scss'

const SearchResultsPagination = (props: ISearchResultsPaginationProps): ReactElement => {
	const {
		pageable, onChangePage,
		baseUrl = '',
		templateUrl = '',
		params = {},
	} = props
	const { t } = useTranslation(['search'])
	const { isTablet } = useMediaQuery()
	const { getProductListData, getPaginationUrls, getPaginationUrl } = useProductList(true)

	const paginationUrls = getPaginationUrls(templateUrl, baseUrl)

	const { pageNumber, totalPages } = pageable
	const selectedPageSize: number = !isUndefined(params) && params.size ? params.size : SEARCH_RESULTS_PER_PAGE_DEFAULT_VALUE

	const handleChangePageSize = useCallback(async (size: number): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			size,
			page: 1,
		}

		await getProductListData(newFilterParams)
	}, [getProductListData])

	const handlePaginationUrl = useCallback((pageNumber: number): string => (
		getPaginationUrl(pageNumber, templateUrl)
	), [templateUrl])

	const renderPagination = useCallback((): ReactElement => {
		if (isTablet) {
			return (
				<Pagination
					baseUrl={ baseUrl }
					templateUrl={ handlePaginationUrl }
					paginationUrls={ [paginationUrls.prev, paginationUrls.next] }
					initialPage={ pageNumber }
					totalPages={ totalPages }
					onPageChange={ onChangePage }
				/>
			)
		}

		return (
			<PaginationSmall
				initialPage={ pageNumber }
				totalPages={ totalPages }
				onPageChange={ onChangePage }
			/>
		)
	}, [pageNumber, totalPages, isTablet, handlePaginationUrl, baseUrl, paginationUrls])

	return (
		<div className={ styles.wrapper }>
			<PerPage
				label={ t('results.pagination.results') }
				options={ SEARCH_RESULTS_PER_PAGE_OPTIONS }
				value={ selectedPageSize }
				onChange={ handleChangePageSize }
			/>

			{ renderPagination() }
		</div>
	)
}

export { SearchResultsPagination }
