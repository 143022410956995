import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { isNil } from 'lodash'

import { IProductListContentEmptyProps } from '~/components/productList/productListContent'
import { ListingContentEmpty } from '~/components/core/listing'

const ProductListContentEmpty = (props: IProductListContentEmptyProps): ReactElement => {
	const { name, wwwUrl } = props
	const { t } = useTranslation(['productList'])
	const router = useRouter()

	const buttonLabel = isNil(name) ? t('empty.showAll') : `${ t('empty.showAll') } - ${ name }`

	const handleResetFilters = useCallback(async (): Promise<void> => {
		if (!isNil(wwwUrl)) {
			await router.push(wwwUrl)
		}
	}, [wwwUrl])

	return (
		<ListingContentEmpty
			tip={ t('empty.tip') }
			title={ t('empty.title') }
			buttonLabel={ buttonLabel }
			onClick={ handleResetFilters }
		/>
	)
}

export { ProductListContentEmpty }
