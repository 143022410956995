import React, { ReactElement, useCallback, useEffect } from 'react'
import { useToggle } from 'react-use'
import { useFormContext } from 'react-hook-form'
import { Collapse } from 'react-collapse'
import classNames from 'classnames'
import { map } from 'lodash'

import { ISearchResultsFiltersAttributesItemProps, ISearchResultsFiltersData, sortProductListFiltersPosition } from '~/components/search'
import { IFilterAttributeValueData } from '~/api/dataTypes/catalog'
import { Icon } from '~/components/core/icon'
import { ListingFiltersItem } from '~/components/core/listing'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './SearchResultsFiltersAttributesItem.module.scss'

const SearchResultsFiltersAttributesItem = (props: ISearchResultsFiltersAttributesItemProps): ReactElement => {
	const { groupKey, name, values, isAnyFilterSelected } = props
	const [isOpen, setIsOpen] = useToggle(isAnyFilterSelected)
	const { control } = useFormContext<ISearchResultsFiltersData>()
	const { isDesktop } = useMediaQuery()

	const sortValues: IFilterAttributeValueData[] = sortProductListFiltersPosition(values)

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const renderFilters = useCallback((): ReactElement[] => {
		const tabIndex = isDesktop || isOpen ? 0 : -1

		return map(sortValues, (value: IFilterAttributeValueData): ReactElement => {
			const { name, id } = value

			return (
				<ListingFiltersItem
					key={ name }
					control={ control }
					name={ `attributes.${ groupKey }[${ id }]` }
					field={ value }
					tabIndex={ tabIndex }
				/>
			)
		})
	}, [values, isOpen, isDesktop])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.open]: isOpen,
	})

	const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

	useEffect(() => {
		setIsOpen(isAnyFilterSelected)
	}, [isAnyFilterSelected])

	return (
		<div className={ wrapperClass }>
			<div
				className={ styles.headerWrapper }
				aria-hidden="true"
				onClick={ handleToggleOpen }
			>
				<span className={ styles.header }>
					{ name }
				</span>

				<Icon
					name="arrowFull"
					color="green"
					width={ 12 }
					height={ 10 }
					additionalClass={ styles.icon }
				/>
			</div>

			<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
				{ renderFilters() }
			</Collapse>
		</div>
	)
}

export { SearchResultsFiltersAttributesItem }
