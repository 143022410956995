import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useToggle } from 'react-use'
import { yupResolver } from '@hookform/resolvers/yup'

import { buildAttributesDefaultValues, buildAvailabilitiesDefaultValues, buildPromotionLabelsDefaultValues, IProductListFiltersData, IProductListFiltersFormData, IProductListFiltersProps, ProductListFiltersActions,
	ProductListFiltersAttributes, ProductListFiltersAvailability, ProductListFiltersCollapse, ProductListFiltersPrice, ProductListFiltersPromotions } from '~/components/productList/productListFilters'
import { SkipContentAnchor } from '~/components/core/skipContent'
import { ListingAsideHeader } from '~/components/core/listing'
import { ProductListFilterPriceProvider } from '~/providers/productListFilterPriceProvider'
import { SKIP_TO_FILTERS } from '~/hooks/skipElements'
import { useMounted } from '~/hooks/mounted'

import styles from './ProductListFilters.module.scss'

const ProductListFilters = (props: IProductListFiltersProps): ReactElement => {
	const { filters, params } = props
	const { t } = useTranslation(['productList, registration', 'form', 'common'])
	const { isMounted } = useMounted()
	const [isOpen, setIsOpen] = useToggle(true)

	const { filterAttributeList, filterAttributePromotionList, filterAttributeValueAvailabilityList, minAndMaxProductPrice } = filters

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const schema: yup.SchemaOf<IProductListFiltersFormData> = useMemo(() => yup.object().shape({
		promotionLabels: yup.object(),
		availabilities: yup.object(),
		attributes: yup.object(),
	}), [])

	const formProps = useForm<IProductListFiltersData>({
		resolver: yupResolver(schema),
		defaultValues: {
			availabilities: { ...buildAvailabilitiesDefaultValues(filterAttributeValueAvailabilityList) },
			promotionLabels: { ...buildPromotionLabelsDefaultValues(filterAttributePromotionList) },
			attributes: { ...buildAttributesDefaultValues(filterAttributeList) },
		},
	})

	useEffect(() => {
		const { setValue } = formProps

		setValue('availabilities', buildAvailabilitiesDefaultValues(filterAttributeValueAvailabilityList))
		setValue('promotionLabels', buildPromotionLabelsDefaultValues(filterAttributePromotionList))
		setValue('attributes', buildAttributesDefaultValues(filterAttributeList))
	}, [filters])

	const filterValues = formProps.watch()

	const handleResetTmpFilters = useCallback(async (): Promise<void> => {
		const { setValue } = formProps
		const { availabilities, promotionLabels, attributes } = filterValues

		Object.keys(availabilities).forEach((key: string) => {
			setValue(`availabilities.${ key }`, false)
		})

		Object.keys(promotionLabels).forEach((key: string) => {
			setValue(`promotionLabels.${ key }`, false)
		})

		Object.keys(attributes).forEach((key: string) => {
			Object.keys(attributes[key]).forEach((value: string) => {
				setValue(`attributes.${ key }[${ value }]`, false)
			})
		})
	}, [formProps, filterValues])

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<FormProvider { ...formProps }>
			<form className={ styles.wrapper }>
				<SkipContentAnchor elementId={ SKIP_TO_FILTERS } />

				<ListingAsideHeader
					title={ t('filters.title', { ns: 'productList' }) }
					isOpen={ isOpen }
					onToggleOpen={ handleToggleOpen }
				/>

				{ isMounted && (
					<ProductListFiltersCollapse isOpen={ isOpen }>
						<ProductListFiltersPromotions promotions={ filterAttributePromotionList } />

						<ProductListFiltersAvailability availabilities={ filterAttributeValueAvailabilityList } />

						<ProductListFilterPriceProvider value={ { priceFrom: params.priceFrom, priceTo: params.priceTo } }>
							<ProductListFiltersPrice price={ minAndMaxProductPrice } params={ params } />

							<ProductListFiltersAttributes attributes={ filterAttributeList } />

							<ProductListFiltersActions
								filterValues={ filterValues }
								params={ params }
								price={ minAndMaxProductPrice }
								onResetTmpFilters={ handleResetTmpFilters }
							/>
						</ProductListFilterPriceProvider>
					</ProductListFiltersCollapse>
				) }
			</form>
		</FormProvider>
	)
}

export { ProductListFilters }
