import React, { ReactElement, useCallback } from 'react'
import { SwiperOptions } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { map, isNull } from 'lodash'

import { ISeriesItemData } from '~/api/dataTypes/series'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { DATA_TESTID } from '~/utils/dataTestId'
import { Carousel } from '~/components/core/carousel'
import { ISeriesCarouselItemsProps } from '~/components/core/seriesCarousel'
import { SeriesBlock } from '~/components/core/seriesBlock'

import styles from './SeriesCarouselItems.module.scss'

const SeriesCarouselItems = (props: ISeriesCarouselItemsProps): ReactElement => {
	const { items } = props
	const { isTablet } = useMediaQuery()

	const params: SwiperOptions = {
		spaceBetween: isTablet ? 27 : 16,
		slidesPerView: 'auto',
		slidesPerGroup: isTablet ? 2 : 1,
	}

	const renderItems = useCallback((): (ReactElement | undefined)[] => (
		map(items, (item: ISeriesItemData, index: number) => {
			const { id, imageUrl } = item

			if (!isNull(imageUrl)) {
				return (
					<SwiperSlide key={ id } className={ styles.slide }>
						<SeriesBlock
							series={ item }
							dataTestId={ DATA_TESTID.CORE.SERIES_ITEM(index) }
						/>
					</SwiperSlide>
				)
			}
		})
	), [items])

	return (
		<div className={ styles.carousel }>
			<Carousel params={ params }>
				{ renderItems() }
			</Carousel>
		</div>
	)
}

export { SeriesCarouselItems }
