import { map } from 'lodash'

import { IBreadcrumbData, IBreadcrumb, IBreadcrumbItem, IBreadcrumbMapData } from '~/api/dataTypes/breadcrumb'

export const getBreadcrumbs = (breadcrumb: IBreadcrumbData): IBreadcrumb[] => {
	const { breadCrumbItemList } = breadcrumb

	return map(breadCrumbItemList.slice(1), ({ name, url }: IBreadcrumbItem) => ({
		label: name,
		url,
	}))
}

export const getFaqBreadcrumbs = (breadcrumb: IBreadcrumbMapData, node: string): IBreadcrumb[] => {
	const { breadCrumbMap } = breadcrumb

	return map(breadCrumbMap[node].breadCrumbItemList.slice(1), ({ name, url }: IBreadcrumbItem) => ({
		label: name,
		url,
	}))
}
