import { AxiosResponse } from 'axios'
import { isUndefined } from 'lodash'

import { productListParamsSerializer } from '~/utils/productListParamsSerializer'
import { ISearchProductsParams, ICatalogSearchProductResponse, ICatalogSearchProductSeriesResponse, ICatalogChildNodeProductCountResponse, ICatalogSearchProductFilterResponse } from '~/api/dataTypes/catalog'
import { request } from '~/api/requests/axios'
import { IGetCatalogSearchProductsFiltersOptions, IGetCatalogSearchProductsOptions } from '~/api/dataTypes/product'

export const getCatalogSearchProducts = async (isInternal: boolean, params?: ISearchProductsParams, storeCode?: string): Promise<AxiosResponse<ICatalogSearchProductResponse>> => {
	const options: IGetCatalogSearchProductsOptions = {
		params,
		paramsSerializer: (params: ISearchProductsParams) => productListParamsSerializer(params),
		isInternal,
		withStoreCode: true,
	}

	if (!isUndefined(storeCode) && isInternal) {
		options.headers = { storeCode }
	}

	return await request.get('/v1/catalog/search-products', options)
}

export const getCatalogSearchSeries = async (isInternal: boolean, params?: ISearchProductsParams): Promise<AxiosResponse<ICatalogSearchProductSeriesResponse>> => (
	await request.get('/v1/catalog/search-series', {
		params,
		paramsSerializer: (params: ISearchProductsParams) => productListParamsSerializer(params),
		isInternal,
		withStoreCode: true,
	})
)

export const getCatalogChildNodeProductCount = async (node?: number, noCache?: string | string[]): Promise<AxiosResponse<ICatalogChildNodeProductCountResponse>> => (
	await request.get('/v1/catalog/child-nodes-product-count', {
		params: {
			node,
			noCache,
		},
		isInternal: true,
	})
)

export const getCatalogSearchProductsFilters = async (isInternal: boolean, params?: ISearchProductsParams, storeCode?: string): Promise<AxiosResponse<ICatalogSearchProductFilterResponse>> => {
	const options: IGetCatalogSearchProductsFiltersOptions = {
		params,
		paramsSerializer: (params: ISearchProductsParams) => productListParamsSerializer(params),
		isInternal,
		withStoreCode: true,
	}

	if (!isUndefined(storeCode) && isInternal) {
		options.headers = { storeCode }
	}

	return await request.get('/v1/catalog/search-products-filters', options)
}
