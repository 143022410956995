import { noop } from 'lodash'

import { IProductListContextProps } from '~/providers/productListProvider'

export const INITIAL_PRODUCT_LIST_PROVIDER: IProductListContextProps = {
	keyword: '',
	breadCrumb: {
		breadCrumbItemList: [],
	},
	searchData: {
		wwwUrl: '',
		templateUrl: '',
	},
	levelOneHeader: '',
	itemPage: {
		pageable: {
			totalElements: 0,
			totalPages: 0,
			hasNext: false,
			firstPage: false,
			lastPage: false,
			hasPrevious: false,
			pageSize: 0,
			pageNumber: 0,
		},
		content: [],
	},
	node: {
		wwwUrl: '',
		templateType: 'SUB_WORLD_PRODUCT_LIST',
		name: '',
		id: 0,
		childrenIds: [],
		parentId: 0,
		parentWwwUrl: '',
		description: '',
	},
	seoCategoryDescription: '',
	seriesCount: 0,
	series: {
		node: {
			wwwUrl: '',
			parentId: 0,
			parentWwwUrl: '',
			childrenIds: [],
			name: '',
			id: 0,
			templateType: 'SUB_WORLD_PRODUCT_LIST',
			description: '',
		},
		productSeriesPage: {
			content: [],
			pageable: {
				totalElements: 0,
				totalPages: 0,
				hasNext: false,
				firstPage: false,
				lastPage: false,
				hasPrevious: false,
				pageSize: 0,
				pageNumber: 0,
			},
		},
		levelOneHeader: '',
		seoCategoryDescription: '',
		productsCount: 0,
		anyFilterChosen: false,
	},
	filters: {
		filterAttributeList: [],
		filterAttributePromotionList: [],
		filterAttributeValueAvailabilityList: [],
		minAndMaxProductPrice: {
			max: 0,
			min: 0,
			count: 0,
		},
		filterAttributeValueBrandList: [],
		filterLinkList: [],
		searchCategoryList: [],
		searchTopCategoryList: [],
		searchTreeCategoryList: [],
	},
	catalogPath: {
		megaWorld: null,
		world: null,
		subWorld: null,
		leaf: null,
		categories: [],
	},
	params: {},
	handleSetData: noop,
	availableSortList: [],
	cabNavigation: {
		cabStepNavigationList: [],
		header: '',
	},
	articleBanner: null,
	handleSetIsPending: noop,
	redirectUrl: null,
	isPending: false,
	catalogChildNodeData: null,
}
