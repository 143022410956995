import { useTranslation } from 'next-i18next'
import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { IAccountProjectsItemsEditButtonProps } from '~/components/account/accountProjectsItems'
import { useMozaic } from '~/hooks/mozaic'

import styles from './AccountProjectsItemsEditButton.module.scss'

const AccountProjectsItemsEditButton = (props: IAccountProjectsItemsEditButtonProps): ReactElement => {
	const { onClick } = props
	const { t } = useTranslation(['account'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const iconColor = shouldUseMozaic ? 'mozaicPrimary01600' : 'green'
	const iconWidth = shouldUseMozaic ? 14 : 13

	const actionClass = classNames(styles.actionLink, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ actionClass }
				ariaLabel={ t('projects.list.editProject') }
				onClick={ onClick }
			>
				<Icon
					additionalClass={ styles.icon }
					name="edit"
					color={ iconColor }
					width={ iconWidth }
				/>

				<span>{ t('projects.list.editProject') }</span>
			</Button>
		</div>
	)
}

export { AccountProjectsItemsEditButton }
