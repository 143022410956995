import React, { ReactElement, useCallback } from 'react'
import ReactSelect, { SingleValue, ControlProps, MultiValue, components as ReactSelectComponents } from 'react-select'
import classNames from 'classnames'
import { isEmpty, isNil, noop, toNumber } from 'lodash'

import { IPerPageProps, createSelectedOption, createSelectOptions, isMultiValue } from '~/components/core/perPage'
import { ISelectOption } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { useDropdownSelect } from '~/hooks/dropdownSelect'
import { useMozaic } from '~/hooks/mozaic'

import styles from './PerPage.module.scss'

export const PerPage = (props: IPerPageProps): ReactElement => {
	const {
		label,
		value = 10,
		options = [10],
		additionalClass = '',
		onChange = noop,
	} = props
	const { handleFocusAria, guidance } = useDropdownSelect()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const hasLabel = !isEmpty(label)
	const selectOptions = createSelectOptions(options)
	const selectedOption = createSelectedOption(selectOptions, value)

	const handleSelectChange = useCallback((newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption>): void => {
		if (!isMultiValue(newValue) && !isNil(newValue?.value)) {
			onChange(toNumber(newValue?.value))
		}
	}, [onChange])

	const renderCustomControl = useCallback((props: ControlProps<ISelectOption>): ReactElement => {
		const { children } = props

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<ReactSelectComponents.Control { ...props }>
				<Button
					variant="neutral"
					size="inherit"
					ariaLabel={ `${ label } ${ value }` }
					isContentAriaHidden={ false }
				>
					{ children }
				</Button>
			</ReactSelectComponents.Control>
		)
	}, [])

	const perPageClass = classNames(styles.perPage, additionalClass)
	const selectClass = classNames('react-select', styles.select, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const labelClass = classNames(styles.label, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ perPageClass }>
			{ hasLabel && (
				<label htmlFor="react-select-per-page-label-input" className={ labelClass }>
					{ label }
				</label>
			) }

			<ReactSelect
				instanceId="per-page-label"
				defaultValue={ selectedOption }
				options={ selectOptions }
				placeholder=""
				className={ selectClass }
				classNamePrefix="react-select"
				isSearchable={ false }
				aria-label={ `${ label } ${ value }` }
				components={ {
					IndicatorSeparator: () => null,
					Control: renderCustomControl,
				} }
				value={ selectedOption }
				ariaLiveMessages={ {
					onFocus: handleFocusAria,
					guidance,
				} }
				onChange={ handleSelectChange }
			/>
		</div>
	)
}
