import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { map } from 'lodash'

import { Modal } from '~/components/core/modal'
import { usePlannerAddToCart } from '~/hooks/plannerIframe'
import { IPlannerProductSimple } from '~/api/dataTypes/planner'
import { getPlannerProductsSimple } from '~/api/requests/planner'
import { Loader } from '~/components/core/loader'
import { Button } from '~/components/core/button'

import styles from './PlannerNotAddedProductsModal.module.scss'

const PlannerNotAddedProductsModal = (): ReactElement => {
	const { isOpenNotAddedProductsModal, onCloseNotAddedProductsModal, notAddedProducts } = usePlannerAddToCart()
	const { t } = useTranslation(['planner'])
	const [products, setProducts] = useState<IPlannerProductSimple[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const handleGetProducts = useCallback(async (): Promise<void> => {
		setIsLoading(true)

		try {
			const lmReferences = map(notAddedProducts, 'lmReference')
			const { data: { offers } } = await getPlannerProductsSimple(lmReferences)

			setProducts(offers)
		} catch (e: unknown) {} finally {
			setIsLoading(false)
		}
	}, [notAddedProducts, setIsLoading, setProducts])

	const renderContent = useCallback((): ReactElement => {
		if (isLoading) {
			return (
				<Loader />
			)
		}

		return (
			<>
				<div className={ styles.list }>
					{ map(products, (product: IPlannerProductSimple): ReactElement => (
						<div key={ product.lmReference } className={ styles.item }>
							<div className={ styles.reference }>
								{ product.lmReference }
							</div>

							<div>
								{ product.name }
							</div>
						</div>
					)) }
				</div>

				<Button
					text={ t('ok', { ns: 'common' }) }
					variant="secondary"
					additionalClass={ styles.button }
					onClick={ onCloseNotAddedProductsModal }
				/>
			</>
		)
	}, [isLoading, products])

	useEffect(() => {
		(async () => {
			if (isOpenNotAddedProductsModal) {
				await handleGetProducts()
			}
		})()
	}, [isOpenNotAddedProductsModal])

	return (
		<Modal
			isOpen={ isOpenNotAddedProductsModal }
			title={ t('notAddedProductsModal.title') }
			additionalClass={ styles.modal }
			onClose={ onCloseNotAddedProductsModal }
		>
			<div className={ styles.wrapper }>
				{ renderContent() }
			</div>
		</Modal>
	)
}

export { PlannerNotAddedProductsModal }
