import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { vars } from '~/statics'

import styles from './PlannerAvailability.module.scss'

const PlannerAvailability = (): ReactElement => {
	const { t } = useTranslation(['planner'])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>
				{ t('availability.caption') }
			</div>

			<div className={ styles.info }>
				{ t('availability.info') }
			</div>

			<Image
				width={ 720 }
				height={ 532 }
				src={ vars.images.planner }
				alt=""
				className={ styles.image }
			/>
		</div>
	)
}

export { PlannerAvailability }
