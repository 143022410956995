import { useCallback } from 'react'
import { isEqual } from 'lodash'

import { IPaginationRequestParams } from '~/api/dataTypes/pageable'
import { IPaginationUrls, IUsePaginationData, IUsePaginationParams, PAGINATION_TEMPLATE_URL_SLUG } from '~/hooks/pagination'

export const usePagination = (params: IUsePaginationParams): IUsePaginationData => {
	const { pageable } = params
	const { pageSize } = pageable

	const perPageOptions = [10, 25, 50]

	const getChangePageSizeParams = useCallback((size: number): IPaginationRequestParams => ({
		size,
		page: 1,
	}), [])

	const getChangePageNumberParams = useCallback((page: number): IPaginationRequestParams => ({
		size: pageSize,
		page,
	}), [pageSize])

	const getPaginationUrl = useCallback((pageNumber: number, templateUrl: string): string => (
		templateUrl.replace(PAGINATION_TEMPLATE_URL_SLUG, pageNumber.toString())
	), [])

	const getPaginationUrls = useCallback((templateUrl: string, baseUrl: string): IPaginationUrls => {
		const { hasNext, hasPrevious, pageNumber } = pageable

		const prevPage = pageNumber - 1
		const prevPageUrl = isEqual(prevPage, 1) ? baseUrl : templateUrl.replace(PAGINATION_TEMPLATE_URL_SLUG, prevPage.toString())
		const nextPage = pageNumber + 1
		const nextPageUrl = templateUrl.replace(PAGINATION_TEMPLATE_URL_SLUG, nextPage.toString())

		return {
			prev: hasPrevious ? prevPageUrl : '',
			next: hasNext ? nextPageUrl : '',
		}
	}, [pageable])

	return {
		getChangePageSizeParams,
		getChangePageNumberParams,
		getPaginationUrl,
		getPaginationUrls,
		perPageOptions,
	}
}
