import React, { ReactElement, useCallback } from 'react'
import { map, find, isEmpty } from 'lodash'

import { ProductListFiltersAttributesItem, IProductListFiltersAttributesProps } from '~/components/productList/productListFilters'
import { IFilterAttributeData } from '~/api/dataTypes/catalog'

import styles from './ProductListFiltersAttributes.module.scss'

const ProductListFiltersAttributes = (props: IProductListFiltersAttributesProps): ReactElement | null => {
	const { attributes } = props

	const renderAttributeGroups = useCallback((): ReactElement => (
		<>
			{ map(attributes, (attribute: IFilterAttributeData) => {
				const { key, name, values } = attribute
				const isAnyFilterSelected = !isEmpty(find(values, 'selected'))

				return (
					<ProductListFiltersAttributesItem
						key={ key }
						groupKey={ key }
						name={ name }
						values={ values }
						isAnyFilterSelected={ isAnyFilterSelected }
					/>
				)
			}) }
		</>
	), [attributes])

	if (isEmpty(attributes)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			{ renderAttributeGroups() }
		</div>
	)
}

export { ProductListFiltersAttributes }
