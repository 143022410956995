import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Collapse } from 'react-collapse'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { yupResolver } from '@hookform/resolvers/yup'

import { buildAttributesDefaultValues, buildAvailabilitiesDefaultValues, buildPromotionLabelsDefaultValues, SearchResultsFiltersAvailability, ISearchResultsFiltersProps,
	ISearchResultsFiltersFormData, ISearchResultsFiltersData, SearchResultsFiltersPromotions, SearchResultsFiltersPrice, SearchResultsFiltersAttributes, SearchResultsFiltersActions } from '~/components/search'
import { ListingAsideHeader } from '~/components/core/listing'
import { ProductListFilterPriceProvider } from '~/providers/productListFilterPriceProvider'

import styles from './SearchResultsFilters.module.scss'

const SearchResultsFilters = (props: ISearchResultsFiltersProps): ReactElement => {
	const { filters, params } = props
	const { t } = useTranslation(['search'])
	const [isOpen, setIsOpen] = useToggle(true)

	const { filterAttributeList, filterAttributePromotionList, filterAttributeValueAvailabilityList, minAndMaxProductPrice } = filters

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const schema: yup.SchemaOf<ISearchResultsFiltersFormData> = useMemo(() => yup.object().shape({
		promotionLabels: yup.object(),
		availabilities: yup.object(),
		attributes: yup.object(),
	}), [])

	const formProps = useForm<ISearchResultsFiltersData>({
		resolver: yupResolver(schema),
		defaultValues: {
			availabilities: { ...buildAvailabilitiesDefaultValues(filterAttributeValueAvailabilityList) },
			promotionLabels: { ...buildPromotionLabelsDefaultValues(filterAttributePromotionList) },
			attributes: { ...buildAttributesDefaultValues(filterAttributeList) },
		},
	})

	useEffect(() => {
		const { setValue } = formProps

		setValue('availabilities', buildAvailabilitiesDefaultValues(filterAttributeValueAvailabilityList))
		setValue('promotionLabels', buildPromotionLabelsDefaultValues(filterAttributePromotionList))
		setValue('attributes', buildAttributesDefaultValues(filterAttributeList))
	}, [filters])

	const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

	const filterValues = formProps.watch()

	const handleResetTmpFilters = useCallback(async (): Promise<void> => {
		const { setValue } = formProps
		const { availabilities, promotionLabels, attributes } = filterValues

		Object.keys(availabilities).forEach((key: string) => {
			setValue(`availabilities.${ key }`, false)
		})

		Object.keys(promotionLabels).forEach((key: string) => {
			setValue(`promotionLabels.${ key }`, false)
		})

		Object.keys(attributes).forEach((key: string) => {
			Object.keys(attributes[key]).forEach((value: string) => {
				setValue(`attributes.${ key }[${ value }]`, false)
			})
		})
	}, [formProps, filterValues])

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<FormProvider { ...formProps }>
			<form className={ styles.wrapper }>
				<ListingAsideHeader
					title={ t('results.filters.title') }
					isOpen={ isOpen }
					onToggleOpen={ handleToggleOpen }
				/>

				<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
					<SearchResultsFiltersPromotions promotions={ filterAttributePromotionList } />

					<SearchResultsFiltersAvailability availabilities={ filterAttributeValueAvailabilityList } />

					<ProductListFilterPriceProvider value={ { priceFrom: params.priceFrom, priceTo: params.priceTo } }>
						<SearchResultsFiltersPrice price={ minAndMaxProductPrice } params={ params } />

						<SearchResultsFiltersAttributes attributes={ filterAttributeList } />

						<SearchResultsFiltersActions
							filterValues={ filterValues }
							params={ params }
							price={ minAndMaxProductPrice }
							onResetTmpFilters={ handleResetTmpFilters }
						/>
					</ProductListFilterPriceProvider>
				</Collapse>
			</form>
		</FormProvider>
	)
}

export { SearchResultsFilters }
