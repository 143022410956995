import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import classNames from 'classnames'
import { useEffectOnce } from 'react-use'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { PlannerContext } from '~/providers/plannerProvider'
import { usePlanner } from '~/hooks/plannerIframe'
import { PlannerProjectSavingStatusModal, PlannerUnsavedProjectsModal, PlannerUserProjectsModal, PlannerAddToCartModal, PlannerUserUnauthorizedModal, PlannerAssignProjectModal, PlannerAddEmptyCartModal, PlannerNotAddedProductsModal } from '~/components/planner'

import styles from './PlannerIframe.module.scss'

const PlannerIframe = (): ReactElement => {
	const { iframeRef, isFullscreen, isScreenSaverVisible, isShopVersion } = useContext(PlannerContext)
	const { planner: { plannerUrl } } = useContext(AppParametersContext)
	const { handleMessage, handleSetIframeLoaded, isLogged } = usePlanner()

	const handleOnLoad = useCallback(() => {
		handleSetIframeLoaded()
	}, [handleSetIframeLoaded])

	useEffectOnce(() => {
		handleSetIframeLoaded(true)
	})

	useEffect(() => {
		window.addEventListener('message', handleMessage)

		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [isLogged])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.notVisible]: isScreenSaverVisible,
		[styles.fullscreen]: isFullscreen,
	})

	return (
		<div className={ wrapperClass }>
			{ /* eslint-disable-next-line react/iframe-missing-sandbox, react/jsx-newline */ }
			<iframe
				ref={ iframeRef }
				src={ `${ plannerUrl }/netplus/planner-iframe-rwd-static.html` }
				title="planner-3d"
				id="planner-frame"
				sandbox="allow-scripts allow-popups allow-same-origin allow-downloads allow-popups-to-escape-sandbox"
				data-hj-allow-iframe="true"
				className={ styles.iframe }
				onLoad={ handleOnLoad }
			/>

			<PlannerAddToCartModal />

			<PlannerAddEmptyCartModal />

			<PlannerNotAddedProductsModal />

			<PlannerUnsavedProjectsModal />

			<PlannerProjectSavingStatusModal />

			<PlannerUserProjectsModal />

			<PlannerUserUnauthorizedModal isShopVersion={ isShopVersion } />

			<PlannerAssignProjectModal />
		</div>
	)
}

export { PlannerIframe }
