import React, { ReactElement, useCallback } from 'react'
import { map, find, isEmpty } from 'lodash'

import { ISearchResultsFiltersAttributesProps, SearchResultsFiltersAttributesItem } from '~/components/search'
import { IFilterAttributeData } from '~/api/dataTypes/catalog'

import styles from './SearchResultsFiltersAttributes.module.scss'

const SearchResultsFiltersAttributes = (props: ISearchResultsFiltersAttributesProps): ReactElement => {
	const { attributes } = props

	const renderAttributeGroups = useCallback((): ReactElement => (
		<>
			{ map(attributes, (attribute: IFilterAttributeData) => {
				const { key, name, values } = attribute
				const isAnyFilterSelected = !isEmpty(find(values, 'selected'))

				return (
					<SearchResultsFiltersAttributesItem
						key={ key }
						groupKey={ key }
						name={ name }
						values={ values }
						isAnyFilterSelected={ isAnyFilterSelected }
					/>
				)
			}) }
		</>
	), [attributes])

	return (
		<div className={ styles.wrapper }>
			{ renderAttributeGroups() }
		</div>
	)
}

export { SearchResultsFiltersAttributes }
