import React, { ReactElement } from 'react'
import { isEmpty } from 'lodash'

import { ICategoryCarouselProps, CategoryCarouselList } from '~/components/core/categoryCarousel'

import styles from './CategoryCarousel.module.scss'

const CategoryCarousel = (props: ICategoryCarouselProps): ReactElement | null => {
	const {
		categories, pageType,
		caption = '',
		additionalClass = '',
	} = props

	if (isEmpty(categories)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			{ !isEmpty(caption) && (
				<div className={ styles.caption }>
					{ caption }
				</div>
			) }

			<CategoryCarouselList
				categories={ categories }
				additionalClass={ additionalClass }
				pageType={ pageType }
			/>
		</div>
	)
}

export { CategoryCarousel }
