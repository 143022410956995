import React, { MouseEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IPaginationButtonProps } from '~/components/core/pagination'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { Link } from '~/components/core/link'
import { useMozaic } from '~/hooks/mozaic'

import styles from './PaginationButton.module.scss'

export const NextPageButton = (props: IPaginationButtonProps): ReactElement => {
	const {
		onClick,
		url = '',
	} = props
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const handleClickUrl = useCallback((event: MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault()
		onClick()
	}, [onClick])

	const renderContent = useCallback((): ReactElement => {
		const buttonClass = classNames(styles.label, {
			[styles.isMozaic]: shouldUseMozaic,
		})

		const iconName = shouldUseMozaic ? 'mozaicArrowRight16' : 'arrow'
		const iconColor = shouldUseMozaic ? 'mozaicGrey900' : 'navy'
		const iconWidth = shouldUseMozaic ? 16 : 8

		return (
			<>
				<span className={ buttonClass }>
					{ t('pagination.nextPage') }
				</span>

				<Icon
					name={ iconName }
					width={ iconWidth }
					color={ iconColor }
					additionalClass={ styles.pageButtonIcon }
				/>
			</>
		)
	}, [shouldUseMozaic])

	if (!isEmpty(url)) {
		return (
			<Link
				href={ url }
				additionalClass={ styles.nextPageButton }
				ariaLabel={ t('pagination.nextPage') }
				onClick={ handleClickUrl }
			>
				{ renderContent() }
			</Link>
		)
	}

	const nextPageButtonClass = classNames(styles.nextPageButton, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ nextPageButtonClass }
			ariaLabel={ t('pagination.nextPage') }
			onClick={ onClick }
		>
			{ renderContent() }
		</Button>
	)
}
