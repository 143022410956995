import React, { ReactElement, useCallback, forwardRef, ForwardedRef } from 'react'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { Image } from '~/components/core/image'
import { IListingBannerBlockCampaignProps } from '~/components/core/listing'
import { Link } from '~/components/core/link'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'

import styles from './ListingBannerBlockCampaign.module.scss'

const ListingBannerBlockCampaign = forwardRef<HTMLDivElement, IListingBannerBlockCampaignProps>((props: IListingBannerBlockCampaignProps, forwardedRef: ForwardedRef<HTMLDivElement>): ReactElement => {
	const { banner, position } = props
	const { GA_promotionClick } = useGoogleAnalytics()

	const { wwwUrl, name, imagePath } = banner

	const handlePromotionClickEvent = useCallback((): void => {
		const { name, listingBannerType } = banner

		GA_promotionClick({ name, id: `${ listingBannerType }-${ name }` }, position)
	}, [position, banner])

	const renderContent = useCallback(() => {
		if (!isNull(wwwUrl)) {
			return (
				<Link
					href={ wwwUrl }
					theme={ { wrapper: styles.link } }
					ariaLabel={ name }
					dataTestId={ DATA_TESTID.PRODUCT_LIST.BREAK_CAMPAIGN }
					onClick={ handlePromotionClickEvent }
				>
					<Image
						width={ 0 }
						height={ 0 }
						src={ imagePath }
						alt={ name }
						className={ styles.image }
					/>
				</Link>
			)
		}

		return (
			<div className={ styles.link }>
				<Image
					width={ 0 }
					height={ 0 }
					src={ imagePath }
					alt={ name }
					className={ styles.image }
				/>
			</div>
		)
	}, [wwwUrl])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.withLink]: !isNull(wwwUrl),
	})

	return (
		<div ref={ forwardedRef } className={ wrapperClass }>
			{ renderContent() }
		</div>
	)
})

export { ListingBannerBlockCampaign }
