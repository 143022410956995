import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty, isFunction } from 'lodash'

import { IListingAsideHeaderProps } from '~/components/core/listing'
import { Icon } from '~/components/core/icon'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './ListingAsideHeader.module.scss'

const ListingAsideHeader = (props: IListingAsideHeaderProps): ReactElement => {
	const {
		title, isOpen,
		onToggleOpen = undefined,
		additionalClass = '',
	} = props
	const { isDesktop } = useMediaQuery()

	if (isDesktop) {
		return (
			<div className={ styles.wrapper }>
				{ title }
			</div>
		)
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.open]: isOpen,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			<div
				role="button"
				className={ styles.headerWrapper }
				tabIndex={ 0 }
				aria-label={ title }
				aria-expanded={ isOpen }
				onClick={ onToggleOpen }
			>
				<span className={ styles.header }>
					{ title }
				</span>

				{ isFunction(onToggleOpen) && (
					<Icon
						name="arrowFull"
						color="green"
						width={ 15 }
						height={ 15 }
						additionalClass={ styles.icon }
					/>
				) }
			</div>
		</div>
	)
}

export { ListingAsideHeader }
