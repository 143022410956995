import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { ISearchResultsContentEmptyProps } from '~/components/search'
import { ListingContentEmpty } from '~/components/core/listing'

const SearchResultsContentEmpty = (props: ISearchResultsContentEmptyProps): ReactElement => {
	const { wwwUrl } = props
	const { t } = useTranslation(['search'])
	const router = useRouter()

	const handleResetFilters = useCallback(async (): Promise<void> => {
		await router.push(wwwUrl)
	}, [wwwUrl])

	return (
		<ListingContentEmpty
			title={ t('results.empty.title') }
			tip={ t('results.empty.tip') }
			buttonLabel={ `${ t('results.empty.showAll') }` }
			onClick={ handleResetFilters }
		/>
	)
}

export { SearchResultsContentEmpty }
