import { useCallback, useContext, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocalStorage } from 'react-use'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { getCookie } from 'cookies-next'
import { isEmpty, isEqual, isNil, isNull, isUndefined, map } from 'lodash'

import { IUsePlannerData, PlannerIframeContentWindow, PLANNER_DB_ADDR, PLANNER_REST_API, PLANNER_REST_API_TURBINE, PlannerIframeCommands, IPlannerIframeParamsObject, PlannerIframeCommandsCallbacks, PlannerIframeCommandsEvents, usePlannerProject, usePlannerAddToCart, valueToUint8Array, IPlannerProjectInfo, mapProjectFormData, PlannerIframeCommandsInit, IPlannerProjectInitData } from '~/hooks/plannerIframe'
import { useGoToSignIn } from '~/hooks/goToSignIn'
import { useAlert } from '~/hooks/alert'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { PlannerContext } from '~/providers/plannerProvider'
import { UserContext } from '~/providers/userProvider'
import { IRootState } from '~/state/types'
import { PreferredStoreDataType } from '~/state/reducers/preferredStoreReducer'
import { LS_KEY_PREFERRED_STORE, COOKIE_KEY_ADEO_STORE_ID } from '~/utils/constants'
import { getCartTempoOrderId, getCartUuid } from '~/utils/cart'
import { parseCookieStoreIdToStoreCode } from '~/utils/stores'
import { IPreferredStoreData } from '~/actions/preferredStore'
import { ICustomerProjectItem } from '~/api/dataTypes/customerProject'
import { useAdeoSignIn } from '~/hooks/signIn'

export const usePlanner = (): IUsePlannerData => {
	const { iframeContentWindow, isShopVersion, initialProjectNumber, onToggleFullscreen, onSetScreenSaverVisible, onOpenOpenUserProjectsModal, onOpenUnauthorizedModal, onOpenAssignProjectModal, onOpenUnsavedProjectModal, onOpenProjectSavingStatusModal, onSetAddToCartParams, isReload, onLogoutReload, onCompleteReload } = useContext(PlannerContext)
	const { isLogged, userName } = useContext(UserContext)
	const { planner: { plannerUrl }, kobiConfig: { integrationEnabled: isKobiIntegrationEnabled }, turbineConfig: { offerManagerService } } = useContext(AppParametersContext)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { handleGetAllProjects, handleGetProject, handleSaveProjectToStorage, handleCreateProject, handleUpdateProject, handleGetProjectFromStorage, handleRemoveProjectFromStorage } = usePlannerProject()
	const { onOpenAddToCartModal, onOpenAddEmptyCartModal } = usePlannerAddToCart()
	const { onGoToSignInPage } = useGoToSignIn()
	const [preferredStoreFromLS] = useLocalStorage<IPreferredStoreData>(LS_KEY_PREFERRED_STORE)
	const [isPlannerLoaded, setIsPlannerLoaded] = useState<boolean>(false)
	const [isIframeLoaded, setIsIframeLoaded] = useState<boolean | null>(null)
	const router = useRouter()
	const { newAlert } = useAlert()
	const { t } = useTranslation(['planner'])
	const { handleSignInByAdeo } = useAdeoSignIn()

	const projectInfoFromLS = handleGetProjectFromStorage()

	const handleSetIframeLoaded = useCallback((isLoaded?: boolean): void => {
		!isUndefined(isLoaded) ? setIsIframeLoaded(isLoaded) : setIsIframeLoaded(!isIframeLoaded)
	}, [isIframeLoaded])

	const init = useCallback(async (iframeContentWindow: PlannerIframeContentWindow): Promise<void> => {
		const projectResponse = !isEmpty(initialProjectNumber) ? await handleGetProject(initialProjectNumber) : null

		const project = !isNull(projectResponse) ? valueToUint8Array((projectResponse as ICustomerProjectItem).project) : null

		let projectData: IPlannerProjectInitData = {
			...projectResponse,
			project,
		}

		if (isEqual(router.query.editMode, 'true') && isNull(projectResponse) && !isNull(projectInfoFromLS) && isLogged) {
			const { project, params: { name } } = projectInfoFromLS

			projectData = {
				project,
				name,
			}

			handleRemoveProjectFromStorage()
		}

		if (!isNull(iframeContentWindow)) {
			const restAPI = offerManagerService ? PLANNER_REST_API_TURBINE : PLANNER_REST_API

			const params: IPlannerIframeParamsObject = {
				dbaddr: PLANNER_DB_ADDR,
				restAPI,
				isHomeVersion: !isShopVersion,
			}

			const registerEvents: PlannerIframeCommandsEvents[] = [
				'Login',
				'Logout',
				'Fullscreen',
				'ShowProjects',
				'SaveProject',
				'AddToBasket',
			]

			iframeContentWindow.postMessage({ command: 'RunNetPlusApp' as PlannerIframeCommandsInit, params, registerEvents, projectData }, '*')
		}
	}, [isShopVersion, isLogged, userName, preferredStoreFromLS, router.query, offerManagerService])

	const handleSetLoginState = useCallback(async (): Promise<void> => {
		if (!isNull(iframeContentWindow)) {
			const params = {
				state: isLogged,
				userName: isLogged ? userName : undefined,
			}

			iframeContentWindow.postMessage({ command: 'SetLoginState' as PlannerIframeCommands, params }, plannerUrl)
		}
	}, [isLogged, userName, iframeContentWindow, plannerUrl])

	const handleSetShopInfo = useCallback((preferredStore: PreferredStoreDataType): void => {
		if (!isNull(iframeContentWindow)) {
			const shopData = preferredStore ?? (preferredStoreFromLS || {} as IPreferredStoreData)

			const { id, storeCode, name } = shopData
			const params = {
				id,
				code: storeCode,
				name,
			}

			iframeContentWindow.postMessage({ command: 'SetShopInfo' as PlannerIframeCommandsInit, params }, plannerUrl)
		}
	}, [preferredStoreFromLS, iframeContentWindow, plannerUrl])

	const handleLoginEvent = useCallback(async (): Promise<void> => {
		if (isKobiIntegrationEnabled) {
			await handleSignInByAdeo(router.asPath)
		} else {
			isShopVersion ? await onGoToSignInPage({ isShopVersion }) : await onGoToSignInPage()
		}
	}, [onGoToSignInPage, isShopVersion, isKobiIntegrationEnabled, router])

	const handleLogoutEvent = useCallback(async (): Promise<void> => {
		await onLogoutReload()
	}, [onLogoutReload])

	const handleOpenProject = useCallback((projectInfo: IPlannerProjectInfo | null): void => {
		if (!isNull(projectInfo)) {
			const { project, params: { name, number } } = projectInfo
			const params = [project, JSON.stringify({
				name,
				id: number,
			})]

			if (!isNull(iframeContentWindow)) {
				iframeContentWindow.postMessage({ command: 'openProject' as PlannerIframeCommandsInit, params }, plannerUrl)
			}
		}
	}, [iframeContentWindow, plannerUrl])

	const handleShowProject = useCallback(async (projectNumber: string): Promise<void> => {
		if (!isEmpty(projectNumber)) {
			const projectInfo = await handleGetProject(projectNumber)

			if (!isUndefined(projectInfo)) {
				const { project, name, number } = projectInfo

				handleOpenProject({
					project: valueToUint8Array(project),
					params: {
						name,
						number,
					},
				})
			}
		}
	}, [iframeContentWindow, handleGetProject])

	const handleDataMessage = useCallback(async (event: MessageEvent): Promise<void> => {
		switch (event.data.message as PlannerIframeCommandsCallbacks) {
			case 'onSetLoginState':
				await handleShowProject(initialProjectNumber)
				break
			case 'onRunNetPlusAppReady':
				setIsPlannerLoaded(true)
				break
			case 'onRunNetPlusApp':
				onSetScreenSaverVisible(false)
				break
			default:
				break
		}
	}, [initialProjectNumber, onSetScreenSaverVisible])

	const handleSaveProject = useCallback(async (event: MessageEvent): Promise<void> => {
		if (!isLogged) {
			handleSaveProjectToStorage({
				project: event.data.params[0],
				image: event.data.params[1],
				params: JSON.parse(event.data.params[2]),
			})

			onOpenAssignProjectModal()
		} else {
			const { id, name } = JSON.parse(event.data.params[2])
			const projectInfo: IPlannerProjectInfo = {
				image: event.data.params[1],
				project: event.data.params[0],
				params: {
					name,
					number: id,
				},
			}

			const formData = mapProjectFormData(projectInfo)

			if (!isNil(id)) {
				await handleUpdateProject(id, formData)
			} else {
				await handleCreateProject(projectInfo, '')
			}
		}
	}, [isLogged, handleSaveProjectToStorage, onOpenAssignProjectModal, handleUpdateProject, handleCreateProject, onOpenProjectSavingStatusModal])

	const handleSetShowProjectsEvent = useCallback(async (): Promise<void> => {
		if (isLogged) {
			onOpenOpenUserProjectsModal()
			await handleGetAllProjects()
		} else {
			onOpenUnauthorizedModal()
		}
	}, [isLogged, onOpenOpenUserProjectsModal, onOpenUnauthorizedModal, handleGetAllProjects])

	const handleSetAddToCartParams = useCallback(async (event: MessageEvent): Promise<void> => {
		const { r: referenceList, q: quantityList }: { r: string[]; q: number[] } = JSON.parse(event.data.params[0])
		const items = map(referenceList, (lmReference: string, index: number) => ({ lmReference, quantity: quantityList[index] }))

		const cartUUID = getCartUuid()
		const tempoOrderId = getCartTempoOrderId()
		const adeoCookieStoreId = getCookie(COOKIE_KEY_ADEO_STORE_ID) as string
		const storeCode = !isNil(adeoCookieStoreId) ? parseCookieStoreIdToStoreCode(adeoCookieStoreId) : null

		if (isEmpty(items)) {
			onOpenAddEmptyCartModal()
		} else {
			onSetAddToCartParams({ items, cartUUID, tempoOrderId, storeCode })
			onOpenAddToCartModal()
		}
	}, [])

	const handleEventMessage = useCallback(async (event: MessageEvent): Promise<void> => {
		switch (event.data.eventName as PlannerIframeCommandsEvents) {
			case 'SaveProject':
				await handleSaveProject(event)
				break
			case 'Login':
				await handleLoginEvent()
				break
			case 'Logout':
				await handleLogoutEvent()
				break
			case 'ShowProjects':
				await handleSetShowProjectsEvent()
				break
			case 'AddToBasket':
				await handleSetAddToCartParams(event)
				break
			case 'Fullscreen':
				onToggleFullscreen()
				break
			default:
				break
		}
	}, [isLogged, userName, handleLoginEvent, handleLogoutEvent, onOpenAddToCartModal, onOpenAddEmptyCartModal, onSetAddToCartParams, onToggleFullscreen, handleSetShowProjectsEvent, handleSaveProject])

	const handleMessage = useCallback(async (event: MessageEvent): Promise<void> => {
		if (isEqual(event.origin, plannerUrl)) {
			if (event.data.message) {
				await handleDataMessage(event)
			}

			if (event.data.eventName) {
				await handleEventMessage(event)
			}
		}
	}, [handleDataMessage, handleEventMessage, isLogged])

	useEffect(() => {
		if (!isNull(iframeContentWindow) && !isNull(isIframeLoaded)) {
			(async () => {
				await init(iframeContentWindow)
			})()
		}
	}, [iframeContentWindow, isIframeLoaded])

	useEffect(() => {
		if (!isNull(preferredStore) && isPlannerLoaded) {
			handleSetShopInfo(preferredStore)
		}
	}, [preferredStore, isPlannerLoaded])

	useEffect(() => {
		if (isPlannerLoaded) {
			(async () => {
				await handleSetLoginState()
			})()
		}
	}, [isLogged, isPlannerLoaded])

	useEffect(() => {
		if (isPlannerLoaded) {
			(async () => {
				await handleShowProject(initialProjectNumber)
			})()
		}
	}, [initialProjectNumber, isPlannerLoaded])

	useEffect(() => {
		if (isReload) {
			newAlert('success', t('reloadSuccess'), 5000)
		}
	}, [isReload])

	useEffect(() => {
		router.events.on('routeChangeComplete', onCompleteReload)

		return () => {
			router.events.off('routeChangeComplete', onCompleteReload)
		}
	}, [])

	useEffect(() => {
		if (isEmpty(initialProjectNumber) && !isNull(projectInfoFromLS) && isPlannerLoaded && isLogged) {
			onOpenUnsavedProjectModal()
		}
	}, [isPlannerLoaded, isLogged])

	return {
		handleMessage,
		handleSetIframeLoaded,
		handleOpenProject,
		isPlannerLoaded,
		isLogged,
	}
}
