import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

import { Image } from '~/components/core/image'
import { useModal } from '~/hooks/modal'
import { useDate } from '~/hooks/date'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { PlannerContext } from '~/providers/plannerProvider'
import { IAccountProjectsItemsRowProps, AccountProjectsItemsEditButton, AccountProjectsItemsRemoveItem, AccountProjectsItemsRemoveModal } from '~/components/account/accountProjectsItems'
import { DotMenu, IDotMenuListItem } from '~/components/core/dotMenu'
import { useMozaic } from '~/hooks/mozaic'

import styles from './AccountProjectsItemsRow.module.scss'

const AccountProjectsItemsRow = (props: IAccountProjectsItemsRowProps): ReactElement => {
	const { project } = props
	const { t } = useTranslation(['account'])
	const { formatDateUnix, getDateFullTimeFormat } = useDate()
	const { isSmallDesktop } = useMediaQuery()
	const { urls, planner } = useContext(AppParametersContext)
	const { isOpen, handleOpen, handleClose } = useModal('AccountProjectRemove')
	const { isOpenUserProjectsModal, onCloseUserProjectsModal, isShopVersion } = useContext(PlannerContext)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { name, createdDate, number, image, imageUrl, modifiedDate, lastModifiedDate } = project

	const dotMenuList: IDotMenuListItem[] = [
		{
			id: 'removeProject',
			title: t('projects.list.removeProject'),
			icon: {
				name: 'trash',
			},
			onClick: handleOpen,
		},
	]
	const imageSrc = planner.plsAsPlannerSourceEnabled ? `data:image/jpeg;base64,${ image }` : imageUrl

	const modificationDate = planner.plsAsPlannerSourceEnabled && !isUndefined(modifiedDate) ? modifiedDate : lastModifiedDate

	const handleEdit = useCallback(async (): Promise<void> => {
		if (isOpenUserProjectsModal) {
			onCloseUserProjectsModal()
		}

		const plannerUrl = isShopVersion ? urls.appPlanner3dStore : urls.appPlanner3d

		window.location.replace(`${ plannerUrl }?number=${ number }`)
	}, [number, urls.appPlanner3d, urls.appPlanner3dStore, isShopVersion, isOpenUserProjectsModal, onCloseUserProjectsModal])

	const cellClass = classNames(styles.cell, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.mobileCell }>
				<div className={ styles.thumbnailWrapper }>
					<Image
						src={ imageSrc }
						alt={ name }
						width={ 100 }
						height={ 75 }
						className={ styles.thumbnail }
					/>
				</div>

				{ !isSmallDesktop && (
					<DotMenu nodes={ dotMenuList } />
				) }
			</div>

			<div className={ cellClass }>
				<span className={ styles.title }>
					{ `${ t('projects.list.name') }: ` }
				</span>

				<span>
					{ name }
				</span>
			</div>

			<div className={ cellClass }>
				<span className={ styles.title }>
					{ `${ t('projects.list.createDate') }: ` }
				</span>

				<span>
					{ formatDateUnix(createdDate, getDateFullTimeFormat()) }
				</span>
			</div>

			<div className={ cellClass }>
				<span className={ styles.title }>
					{ `${ t('projects.list.modifyDate') }: ` }
				</span>

				<span>
					{ formatDateUnix(modificationDate, getDateFullTimeFormat()) }
				</span>
			</div>

			{ isSmallDesktop && (
				<div className={ cellClass }>
					<AccountProjectsItemsRemoveItem onClick={ handleOpen } />

					<AccountProjectsItemsEditButton onClick={ handleEdit } />
				</div>
			) }

			<AccountProjectsItemsRemoveModal
				isOpen={ isOpen }
				project={ project }
				onClose={ handleClose }
			/>
		</div>
	)
}

export { AccountProjectsItemsRow }
