import React, { ReactElement, useCallback } from 'react'
import { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { map } from 'lodash'

import { ICabStepVarianceNavigation } from '~/api/dataTypes/catalog'
import { ProductListCabStepImageItem, IProductListCabStepImageProps } from '~/components/productList/productListCab'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './ProductListCabStepImage.module.scss'

const ProductListCabStepImage = (props: IProductListCabStepImageProps): ReactElement => {
	const { variances, onVarianceClick } = props
	const { isTablet } = useMediaQuery()

	const swiperParams: SwiperOptions = {
		spaceBetween: isTablet ? 40 : 8,
		slidesPerView: 'auto',
	}

	const renderSteps = useCallback((): ReactElement[] => (
		map(variances, (variance: ICabStepVarianceNavigation) => (
			<SwiperSlide key={ variance.id } className={ styles.slide }>
				<ProductListCabStepImageItem variance={ variance } onVarianceClick={ onVarianceClick } />
			</SwiperSlide>
		))
	), [variances, onVarianceClick])

	return (
		<div className={ styles.wrapper }>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<Swiper { ...swiperParams }>
				{ renderSteps() }
			</Swiper>
		</div>
	)
}

export { ProductListCabStepImage }
