import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'

import { ISearchResultsContentProps, SearchResultsSortAndPagination, SearchResultsPagination, SearchResultsItems, SearchResultsContentEmpty, SEARCH_RESULTS_PAGE_NUMBER_TEMPLATE } from '~/components/search'
import { SectionHeader } from '~/components/core/sectionHeader'
import { useProductList } from '~/hooks/productList'
import { useScrollPositionHistory } from '~/hooks/scrollPositionHistory'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'

import styles from './SearchResultsContent.module.scss'

const SearchResultsContent = (props: ISearchResultsContentProps): ReactElement => {
	const { productList } = props
	const { t } = useTranslation(['search'])
	const { getProductListData } = useProductList(true)
	const { scrollToLastPosition } = useScrollPositionHistory()

	const handleChangePage = useCallback(async (pageNumber: number): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			page: pageNumber,
		}

		await getProductListData(newFilterParams)
	}, [getProductListData])

	const renderContent = useCallback((): ReactElement => {
		const { params, itemPage, searchData, availableSortList } = productList
		const { content, pageable } = itemPage
		const { templateUrl, wwwUrl } = searchData
		const { totalElements } = pageable
		const baseUrl = templateUrl.replace(SEARCH_RESULTS_PAGE_NUMBER_TEMPLATE, '')

		if (totalElements === 0) {
			return <SearchResultsContentEmpty wwwUrl={ wwwUrl } />
		}

		return (
			<>
				<SectionHeader counter={ totalElements } title={ t('results.productsTitle') } />

				<SearchResultsSortAndPagination
					baseUrl={ baseUrl }
					templateUrl={ templateUrl }
					pageable={ pageable }
					sortOptions={ availableSortList }
					params={ params }
					onChangePage={ handleChangePage }
				/>

				<SearchResultsItems items={ content } />

				<SearchResultsPagination
					baseUrl={ baseUrl }
					templateUrl={ templateUrl }
					pageable={ pageable }
					params={ params }
					onChangePage={ handleChangePage }
				/>
			</>
		)
	}, [productList])

	useEffect(() => {
		scrollToLastPosition()
	}, [])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { SearchResultsContent }
