import React, { ReactElement } from 'react'

import { ISearchResultsProps, SearchResultsFiltersButton, SearchHeader, SearchResultsLoader, SearchResultsWrapper, SearchResultsAside, SearchResultsContent, SearchResultsContentWrapper } from '~/components/search'
import { ProductListFiltersModalProvider } from '~/providers/productListFiltersModalProvider'

const SearchResults = (props: ISearchResultsProps): ReactElement => {
	const { productListData } = props

	const { params, keyword: resultQuery, isPending, filters } = productListData
	const { keyword: originalQuery } = params

	return (
		<>
			<SearchResultsLoader isLoading={ isPending } />

			<SearchHeader originalQuery={ originalQuery } resultQuery={ resultQuery } />

			<ProductListFiltersModalProvider>
				<SearchResultsWrapper>
					<SearchResultsAside params={ params } initialFilters={ filters } />

					<SearchResultsContentWrapper>
						<SearchResultsFiltersButton />

						<SearchResultsContent productList={ productListData } />
					</SearchResultsContentWrapper>
				</SearchResultsWrapper>
			</ProductListFiltersModalProvider>
		</>
	)
}

export { SearchResults }
