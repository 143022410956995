import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, isEqual, isNull } from 'lodash'

import { ISearchResultsFiltersActionsProps, getFiltersCount, getFilterParams, SEARCH_RESULTS_PER_PAGE_DEFAULT_VALUE } from '~/components/search'
import { Button } from '~/components/core/button'
import { useMounted } from '~/hooks/mounted'
import { useProductList } from '~/hooks/productList'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'
import { ProductListFiltersModalContext } from '~/providers/productListFiltersModalProvider'
import { ProductListFiltersPriceContext } from '~/providers/productListFilterPriceProvider'

import styles from './SearchResultsFiltersActions.module.scss'

const SearchResultsFiltersActions = (props: ISearchResultsFiltersActionsProps): ReactElement | null => {
	const { filterValues, params, onResetTmpFilters, price } = props
	const { handleSetPriceValues, priceFrom, priceTo } = useContext(ProductListFiltersPriceContext)
	const { isOpen: isModalOpen, handleSetOpen: handleSetOpenModal } = useContext(ProductListFiltersModalContext)
	const { t } = useTranslation(['search'])
	const { getProductListData, isPending } = useProductList(true)
	const { isMounted } = useMounted()

	const countFilters: number = getFiltersCount(filterValues, price, priceFrom, priceTo)

	const handleResetTmpPriceFilter = useCallback(() => {
		const { priceFrom, priceTo } = params
		const { min, max } = price
		const initialPriceFrom = !isNull(priceFrom) ? priceFrom : min
		const initialPriceTo = !isNull(priceTo) ? priceTo : max

		handleSetPriceValues({
			priceFrom: initialPriceFrom,
			priceTo: initialPriceTo,
		})
	}, [params, price])

	const handleCleanFilters = useCallback(async (): Promise<void> => {
		const { availabilities, promotionLabels, filterAttribute, priceFrom, priceTo } = params
		const isAnyFilterSelected = !isEmpty(availabilities) || !isEmpty(promotionLabels) || !isEmpty(filterAttribute) || !isNull(priceFrom) || !isNull(priceTo)

		onResetTmpFilters()
		handleResetTmpPriceFilter()

		if (isAnyFilterSelected) {
			const newFilterParams: ISearchProductsParams = {
				promotionLabels: [],
				availabilities: [],
				filterAttribute: '',
				priceFrom: undefined,
				priceTo: undefined,
				page: 1,
			}

			await getProductListData(newFilterParams)
		}
	}, [onResetTmpFilters, getProductListData, params])

	const handleSetFilters = useCallback(async (): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			...getFilterParams(filterValues),
			priceFrom,
			priceTo,
			page: 1,
			size: SEARCH_RESULTS_PER_PAGE_DEFAULT_VALUE,
		}

		if (isModalOpen) {
			handleSetOpenModal(false)
		}

		await getProductListData(newFilterParams)
	}, [filterValues, priceFrom, priceTo, getProductListData, isModalOpen])

	const resetClass = classNames(styles.reset, {
		[styles.invisible]: isEqual(countFilters, 0),
	})

	if (!isMounted) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Button
				text={ t('results.filters.action') }
				variant="secondary"
				isDisabled={ isPending }
				onClick={ handleSetFilters }
			/>

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ resetClass }
				ariaLabel={ t('results.filters.clean', { value: countFilters }) }
				onClick={ handleCleanFilters }
			>
				{ t('results.filters.clean', { value: countFilters }) }
			</Button>
		</div>
	)
}

export { SearchResultsFiltersActions }
