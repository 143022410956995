import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getCustomerReviewsData } from '~/actions/account'
import { usePagination } from '~/hooks/pagination'
import { IUseAccountPaginationData } from '~/hooks/accountPagination'
import { useLogError } from '~/hooks/logError'
import { useScrollToTop } from '~/hooks/scrollToTop'
import { useAlert } from '~/hooks/alert'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'

export const useAccountReviewsPagination = (): IUseAccountPaginationData => {
	const dispatch: AppDispatch = useDispatch()
	const { data: { pageable } } = useSelector((state: IRootState) => state.account.reviews, shallowEqual)
	const { getChangePageNumberParams, getChangePageSizeParams, perPageOptions } = usePagination({ pageable })
	const { preventAlert } = useAlert()
	const { scrollToTop } = useScrollToTop()
	const { sendLogError } = useLogError()

	const handleChangePageNumber = useCallback(async (pageNumber: number) => {
		try {
			const params = getChangePageNumberParams(pageNumber)

			await dispatch(getCustomerReviewsData(params))

			scrollToTop()
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}, [getChangePageNumberParams])

	const handleChangePageSize = useCallback(async (pageSize: number) => {
		try {
			const params = getChangePageSizeParams(pageSize)

			await dispatch(getCustomerReviewsData(params))

			scrollToTop()
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}, [getChangePageSizeParams])

	return {
		pageable,
		perPageOptions,
		onChangePageNumber: handleChangePageNumber,
		onChangePageSize: handleChangePageSize,
	}
}
