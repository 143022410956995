import { AxiosResponse } from 'axios'

import { IRegisterTransactionRequest, IRegisterTransactionResponse, IPaymentTransactionResultResponse, ITransactionAvailabilityResponse } from '~/api/dataTypes/transactionManager'
import { request } from '~/api/requests/axios'
import { IGetPaymentTransactionResultParams } from '~/api/requests/types'
import { getNoCacheParam } from '~/utils/urls'

export const postRegisterTransaction = async (data: IRegisterTransactionRequest): Promise<AxiosResponse<IRegisterTransactionResponse>> => (
	request.post('/v1/p24/ecommerce/transactions', data, {
		service: 'TRANSACTION_MANAGER_SERVICE',
	})
)

export const getPaymentTransactionResult = async (params: IGetPaymentTransactionResultParams): Promise<AxiosResponse<IPaymentTransactionResultResponse>> => {
	const {
		sessionId,
		isInternal = false,
		isTurbine = false,
		noCache = '',
	} = params

	return request.get(`/v1/p24/transactions/${ sessionId }`, {
		service: 'TRANSACTION_MANAGER_SERVICE',
		isInternal,
		isTurbine,
		params: getNoCacheParam(noCache),
	})
}

export const getTransactionAvailability = async (orderTransactionId: string): Promise<AxiosResponse<ITransactionAvailabilityResponse>> => (
	request.get(`/v1/transaction-origins/${ orderTransactionId }/transaction-availability`, {
		service: 'TRANSACTION_MANAGER_SERVICE',
	})
)
