import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Collapse } from 'react-collapse'
import { useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { map } from 'lodash'

import { ISearchResultsFiltersAvailabilityProps, isAnyFilterSelected, sortProductListFiltersPosition, ISearchResultsFiltersFormData } from '~/components/search'
import { ListingFiltersItem, ListingFiltersCategory } from '~/components/core/listing'
import { IFilterAttributeValueData } from '~/api/dataTypes/catalog'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './SearchResultsFiltersAvailability.module.scss'

const SearchResultsFiltersAvailability = (props: ISearchResultsFiltersAvailabilityProps): ReactElement => {
	const { availabilities } = props
	const { t } = useTranslation(['search'])
	const [isOpen, setIsOpen] = useToggle(isAnyFilterSelected(availabilities))
	const { isDesktop } = useMediaQuery()
	const { control } = useFormContext<ISearchResultsFiltersFormData>()

	const sortAvailabilities: IFilterAttributeValueData[] = sortProductListFiltersPosition(availabilities)

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const renderFilters = useCallback((): ReactElement[] => {
		const tabIndex = isDesktop || isOpen ? 0 : -1

		return map(sortAvailabilities, (field: IFilterAttributeValueData): ReactElement => {
			const { name, id } = field

			return (
				<ListingFiltersItem
					key={ name }
					control={ control }
					name={ `availabilities.${ id }` }
					field={ field }
					tabIndex={ tabIndex }
				/>
			)
		})
	}, [sortAvailabilities, availabilities, isOpen, isDesktop])

	const renderContent = useCallback((): ReactElement | ReactElement[] => {
		if (isDesktop) {
			return renderFilters()
		}

		const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

		return (
			<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
				{ renderFilters() }
			</Collapse>
		)
	}, [isDesktop, isOpen, sortAvailabilities])

	useEffect(() => {
		setIsOpen(isAnyFilterSelected(availabilities))
	}, [availabilities])

	return (
		<div className={ styles.wrapper }>
			<ListingFiltersCategory
				title={ t('results.filters.availability') }
				isOpen={ isOpen }
				onToggleOpen={ handleToggleOpen }
			/>

			{ renderContent() }
		</div>
	)
}

export { SearchResultsFiltersAvailability }
