import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useWindowScroll } from 'react-use'
import { isUndefined } from 'lodash'

import { sessionStorageGet, sessionStorageSet } from '~/utils/storage'
import { ISessionStorageScrollPosition, IUseScrollPositionHistoryData } from '~/hooks/scrollPositionHistory'
import { useDebounce } from '~/hooks/debounce'

export const useScrollPositionHistory = (): IUseScrollPositionHistoryData => {
	const router = useRouter()
	const { y } = useWindowScroll()

	const setScrollPosition = useCallback(useDebounce((): void => {
		sessionStorageSet('scrollPosition', {
			y,
			page: router.asPath,
		})
	}, 500), [y, router.asPath])

	const saveScrollPosition = useCallback(() => {
		setScrollPosition()
	}, [y])

	useEffect(() => {
		saveScrollPosition()
	}, [y])

	const scrollToLastPosition = useCallback((): void => {
		const position = sessionStorageGet<ISessionStorageScrollPosition>('scrollPosition')

		if (!isUndefined(position)) {
			if (router.asPath === position?.page) {
				window.scrollTo({ top: position.y, behavior: 'smooth' })
			}
		}
	}, [])

	return {
		scrollToLastPosition,
	}
}
