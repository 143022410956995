import { filter, isNil, map, sumBy } from 'lodash'

import { IFilterPriceData, ISearchProductsParams } from '~/api/dataTypes/catalog'
import { IProductListFiltersDefaultValues, IProductListFiltersFormData } from '~/components/productList/productListFilters'

export const getAttributesSelectedCount = (attributes: IProductListFiltersDefaultValues): number => (
	sumBy(map(attributes, (group: IProductListFiltersDefaultValues) => {
		return filter(group, (value: boolean) => value).length
	}))
)

export const getIsPriceFilterSelected = (price: IFilterPriceData, priceFrom?: number, priceTo?: number): boolean => {
	const { min, max } = price

	return (!isNil(priceFrom) && priceFrom > min) || (!isNil(priceTo) && priceTo < max)
}

export const getFiltersCount = (filterValues: IProductListFiltersFormData, price: IFilterPriceData, priceFrom?: number, priceTo?: number,): number => {
	const { promotionLabels, availabilities, attributes } = filterValues
	const availabilitiesSelected = filter(availabilities, (value: boolean) => value).length
	const promotionLabelsSelected = filter(promotionLabels, (value: boolean) => value).length
	const attributesSelected = getAttributesSelectedCount(attributes)
	const priceFilterSelected = getIsPriceFilterSelected(price, priceFrom, priceTo) ? 1 : 0

	return availabilitiesSelected + promotionLabelsSelected + attributesSelected + priceFilterSelected
}

export const mapFilterAttributesToString = (attributes: IProductListFiltersDefaultValues): string => {
	let filterAttribute = ''
	const temp: string[] = []

	Object.keys(attributes).forEach((attributeKey: string) => {
		const attrKeyTemp: number[] = []

		Object.keys(attributes[attributeKey]).forEach((value: string) => {
			if (attributes[attributeKey][value]) {
				attrKeyTemp.push(Number(value))
			}
		})

		if (attrKeyTemp.length) {
			temp.push(`${ attributeKey }${ attrKeyTemp.join(':') }`)
		}
	})

	if (temp.length) {
		filterAttribute = temp.join()
	}

	return filterAttribute
}

export const getFilterParams = (filterValues: IProductListFiltersFormData): ISearchProductsParams => {
	const { promotionLabels, attributes, availabilities } = filterValues

	const newPromotionLabels: string[] = []
	const newAvailabilities: number[] = []
	const newFilterAttribute: string = mapFilterAttributesToString(attributes)

	Object.keys(promotionLabels).forEach(function(key: string) {
		if (promotionLabels[key]) {
			newPromotionLabels.push(key)
		}
	})

	Object.keys(availabilities).forEach(function(key: string) {
		if (availabilities[key]) {
			newAvailabilities.push(Number(key))
		}
	})

	return {
		promotionLabels: newPromotionLabels,
		availabilities: newAvailabilities,
		filterAttribute: newFilterAttribute,
	}
}
