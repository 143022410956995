import React, { useCallback, ReactElement } from 'react'
import { map } from 'lodash'

import { ProductListCabStep, IProductListCabStepsProps } from '~/components/productList/productListCab'
import { ICabStepNavigation } from '~/api/dataTypes/catalog'

const ProductListCabSteps = (props: IProductListCabStepsProps): ReactElement => {
	const { steps } = props

	const renderSteps = useCallback((): ReactElement[] => (
		map(steps, (step: ICabStepNavigation) => (
			<ProductListCabStep key={ step.name } step={ step } />
		))
	), [steps])

	return (
		<>
			{ renderSteps() }
		</>
	)
}

export { ProductListCabSteps }
