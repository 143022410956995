import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import { includes, isEmpty, isNil } from 'lodash'

import { Container } from '~/components/core/layout/container'
import { Breadcrumbs } from '~/components/core/breadcrumbs'
import { SearchNoResults, SearchResults } from '~/components/search'
import { getGoogleAnalyticsImpressionFromCatalog, useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useProductList } from '~/hooks/productList'
import { getBreadcrumbs } from '~/utils/breadcrumbs'
import { IRootState } from '~/state/types'
import { useMounted } from '~/hooks/mounted'
import { useSare } from '~/hooks/sare'

const Search = (): ReactElement => {
	const { productListData, getProductListData } = useProductList(true)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const [filterPromo, setFilterPromo] = useState<string[]>(productListData.params.promotionLabels || [])
	const { GA_impressions } = useGoogleAnalytics()
	const { SARE_search } = useSare()

	const { breadCrumb, params, itemPage: { content }, searchData: { wwwUrl } } = productListData
	const { keyword: originalQuery } = params

	const breadcrumbs = getBreadcrumbs(breadCrumb)
	const isNoSearchResults = isEmpty(content)
	const shouldBePromotion = useMemo(() => (includes(params.promotionLabels, 'SALE') || includes(params.promotionLabels, 'OUTLET')) && !isNil(preferredStore?.storeCode), [params.promotionLabels, preferredStore?.storeCode])

	useEffect(() => {
		if (shouldBePromotion) {
			setFilterPromo(['PROMOTION', 'SUPER_OFFER'])
		}
	}, [shouldBePromotion])

	useUpdateEffect(() => {
		(async () => {
			await getProductListData({ ...params, promotionLabels: filterPromo })
		})()
	}, [preferredStore?.storeCode])

	useEffect(() => {
		// GA_impressions(getGoogleAnalyticsImpressionFromCatalog(productListData.itemPage, wwwUrl))
		SARE_search(productListData.itemPage)
	}, [wwwUrl])

	const renderContent = useCallback((): ReactElement => {
		if (isNoSearchResults) {
			return (
				<SearchNoResults originalQuery={ originalQuery } />
			)
		}

		return (
			<SearchResults productListData={ productListData } />
		)
	}, [productListData, isNoSearchResults])

	return (
		<Container>
			<Breadcrumbs breadcrumbs={ breadcrumbs } />

			{ renderContent() }
		</Container>
	)
}

export { Search }
