import React, { createContext, useState, ReactElement, useMemo, useCallback } from 'react'
import { noop } from 'lodash'

import { IProductListFiltersModalContextProps, IProductListFiltersModalProviderProps } from '~/providers/productListFiltersModalProvider'

const initialProps: IProductListFiltersModalContextProps = {
	isOpen: false,
	handleSetOpen: noop,
}

export const ProductListFiltersModalContext = createContext(initialProps)

export const ProductListFiltersModalProvider = (props: IProductListFiltersModalProviderProps): ReactElement => {
	const { children } = props
	const [isOpen, setIsOpen] = useState<boolean>(initialProps.isOpen)

	const handleSetOpen = useCallback((state: boolean): void => {
		setIsOpen(state)
	}, [])

	const providerValue: IProductListFiltersModalContextProps = useMemo(() => ({
		isOpen,
		handleSetOpen,
	}), [isOpen])

	return (
		<ProductListFiltersModalContext.Provider value={ providerValue }>
			{ children }
		</ProductListFiltersModalContext.Provider>
	)
}
