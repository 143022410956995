import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { IProductListProductBlockProps } from '~/components/productList/productListProductBlock'
import { ProductBlock } from '~/components/core/productBlock'

import styles from './ProductListProductBlock.module.scss'

const ProductListProductBlock = (props: IProductListProductBlockProps): ReactElement | null => {
	const { product, position } = props

	if (isNull(product)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<ProductBlock
				hasWishList
				hasPriority
				product={ product }
				position={ position }
				tag="h3"
			/>
		</div>
	)
}

export { ProductListProductBlock }
