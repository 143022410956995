import { createRef, RefObject, useCallback } from 'react'
import { isNil } from 'lodash'

export const scrollElementIntoView = (element: HTMLElement, offset: number = 0, behavior: ScrollBehavior = 'smooth'): void => {
	if (typeof window === 'undefined') {
		return
	}

	window.scrollTo({ top: element.offsetTop - offset, behavior })
}

export const useScrollElementIntoView = <ElementType extends HTMLElement>(): [RefObject<ElementType>, () => void] => {
	const elementRef = createRef<ElementType>()

	const scrollElement = useCallback((): void => {
		if (!isNil(elementRef.current)) {
			scrollElementIntoView(elementRef.current)
		}
	}, [elementRef])

	return [
		elementRef,
		scrollElement,
	]
}

export const useScrollToElementByRef = <ElementType extends HTMLElement>(): ((elementRef: ElementType, offset?: number, behavior?: ScrollBehavior) => void) => (
	useCallback((elementRef: ElementType, offset?: number, behavior?: ScrollBehavior): void => {
		if (!isNil(elementRef)) {
			scrollElementIntoView(elementRef, offset, behavior)
		}
	}, [scrollElementIntoView])
)
