import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEqual, isNil } from 'lodash'

import { ISearchHeaderProps } from '~/components/search'

import styles from './SearchHeader.module.scss'

const SearchHeader = (props: ISearchHeaderProps): ReactElement | null => {
	const { originalQuery, resultQuery } = props
	const { t } = useTranslation(['search'])

	const isTypo = !isEqual(originalQuery, resultQuery)

	const shouldDisplayHeader = !isNil(resultQuery)

	if (!shouldDisplayHeader) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.title }>
				<span>{ t('searchResultsFor') }{ ' ' }</span>

				<span className={ styles.name }>{ resultQuery }</span>
			</div>

			{ isTypo && <div>{ t('notFoundResultsFor', { originalQuery, resultQuery }) }</div> }
		</div>
	)
}

export { SearchHeader }
