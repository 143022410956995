import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'

import { IProductListContentProps, ProductListContentEmpty } from '~/components/productList/productListContent'
import { ProductListItems } from '~/components/productList/productListItems'
import { ProductListSortAndPagination } from '~/components/productList/productListSortAndPagination'
import { ProductListPagination } from '~/components/productList/productListPagination'
import { SectionHeader } from '~/components/core/sectionHeader'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'
import { useProductList } from '~/hooks/productList'
import { useScrollPositionHistory } from '~/hooks/scrollPositionHistory'

import styles from './ProductListContent.module.scss'

const ProductListContent = (props: IProductListContentProps): ReactElement => {
	const { productList } = props
	const { t } = useTranslation(['productList'])
	const { getProductListData } = useProductList()
	const { scrollToLastPosition } = useScrollPositionHistory()

	const { node, params, itemPage, searchData, availableSortList } = productList
	const { content, pageable } = itemPage
	const { templateUrl } = searchData
	const { pageNumber, totalElements } = pageable
	const nextPage = pageNumber + 1
	const nextPageParams = { ...params, page: nextPage }

	const handleChangePage = useCallback(async (pageNumber: number): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			sort: nextPageParams.sort,
			page: pageNumber,
		}

		await getProductListData(newFilterParams)
	}, [getProductListData, nextPageParams])

	const renderContent = useCallback((): ReactElement => {
		if (totalElements === 0) {
			return <ProductListContentEmpty name={ node?.name } wwwUrl={ node?.wwwUrl } />
		}

		return (
			<>
				<SectionHeader counter={ totalElements } title={ t('products.title') } />

				<ProductListSortAndPagination
					pageable={ pageable }
					sortOptions={ availableSortList }
					node={ node }
					params={ params }
					templateUrl={ templateUrl }
					onChangePage={ handleChangePage }
				/>

				<ProductListItems items={ content } />

				<ProductListPagination
					baseUrl={ node?.wwwUrl }
					templateUrl={ templateUrl }
					pageable={ pageable }
					params={ params }
					onChangePage={ handleChangePage }
				/>
			</>
		)
	}, [productList, handleChangePage])

	useEffect(() => {
		scrollToLastPosition()
	}, [])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { ProductListContent }
