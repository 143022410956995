import React, { ReactElement } from 'react'

import { IProductListWrapperProps } from '~/components/productList/productListWrapper'

import styles from './ProductListWrapper.module.scss'

const ProductListWrapper = (props: IProductListWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { ProductListWrapper }
