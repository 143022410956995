import React, { ReactElement } from 'react'
import { SwiperOptions } from 'swiper'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { isEmpty, map } from 'lodash'

import { ICategoryCarousel, ICategoryCarouselListProps } from '~/components/core/categoryCarousel'
import { Link } from '~/components/core/link'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './CategoryCarouselList.module.scss'

const CategoryCarouselList = (props: ICategoryCarouselListProps): ReactElement => {
	const {
		categories,
		additionalClass = '',
	} = props
	const { isTablet } = useMediaQuery()

	const swiperParams: SwiperOptions = {
		spaceBetween: isTablet ? 16 : 8,
		slidesPerView: 'auto',
	}

	const categoryClass = classNames({
		[styles.category]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ styles.wrapper }>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<Swiper { ...swiperParams }>
				{ map(categories, (category: ICategoryCarousel) => {
					const { id, wwwUrl, label } = category

					return (
						<SwiperSlide key={ id } className={ styles.slide }>
							<Link
								href={ wwwUrl }
								additionalClass={ categoryClass }
								ariaLabel={ label }
							>
								{ label }
							</Link>
						</SwiperSlide>
					)
				}) }
			</Swiper>
		</div>
	)
}

export { CategoryCarouselList }
