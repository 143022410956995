import React, { useState, createContext, ReactElement, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { noop } from 'lodash'

import { IComparisonContextProps, IComparisonProviderProps, getComparisonProducts, removeComparisonProduct, getComparisonTableColumns, getComparisonPageUrl, getComparisonLabelColumn } from '~/providers/comparisonProvider'
import { IProductSimpleWithDetails } from '~/api/dataTypes/product'
import { AppDispatch } from '~/state/store'
import { removeComparisonItem } from '~/actions/comparison'

const initialProps: IComparisonContextProps = {
	pageUrl: '',
	productsCount: 0,
	labelColumn: [],
	products: [],
	tableColumns: [],
	isRemovable: false,
	isDiffsShowing: false,
	removeProduct: noop,
	changeDiffsVisibility: noop,
}

export const ComparisonContext = createContext(initialProps)

export const ComparisonProvider = (props: IComparisonProviderProps): ReactElement => {
	const { children, data } = props
	const dispatch: AppDispatch = useDispatch()
	const [productsData, setProductsData] = useState<IProductSimpleWithDetails[]>(data)
	const [isDiffsShowing, setIsDiffsShowing] = useState<boolean>(false)

	const productsCount = productsData.length
	const isRemovable = productsData.length > 1
	const products = getComparisonProducts(productsData)
	const labelColumn = getComparisonLabelColumn(productsData)
	const tableColumns = getComparisonTableColumns(productsData)
	const pageUrl = getComparisonPageUrl(productsData)

	const changeDiffsVisibility = useCallback((): void => {
		setIsDiffsShowing(!isDiffsShowing)
	}, [isDiffsShowing, setIsDiffsShowing])

	const removeProduct = useCallback(async (id: number): Promise<void> => {
		if (isRemovable) {
			setProductsData(removeComparisonProduct(productsData, id))
			await dispatch(removeComparisonItem(id))
		}
	}, [productsData, setProductsData, isRemovable])

	const providerValue: IComparisonContextProps = useMemo(() => ({
		productsCount,
		labelColumn,
		products,
		tableColumns,
		removeProduct,
		isRemovable,
		isDiffsShowing,
		changeDiffsVisibility,
		pageUrl,
	}), [productsCount, products, labelColumn, tableColumns, removeProduct, isRemovable, isDiffsShowing, changeDiffsVisibility, pageUrl])

	return (
		<ComparisonContext.Provider value={ providerValue }>
			{ children }
		</ComparisonContext.Provider>
	)
}
