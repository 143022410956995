import React, { ReactElement, useContext, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { ProductListFiltersModalContext } from '~/providers/productListFiltersModalProvider'

import styles from './SearchResultsFiltersButton.module.scss'

const SearchResultsFiltersButton = (): ReactElement => {
	const { t } = useTranslation(['search'])
	const { handleSetOpen } = useContext(ProductListFiltersModalContext)

	const handleShowProductListFiltersModal = useCallback((): void => {
		handleSetOpen(true)
	}, [handleSetOpen])

	return (
		<div className={ styles.wrapper }>
			<Button
				isReverse
				variant="secondary"
				additionalClass={ styles.button }
				text={ t('results.filters.button') }
				onClick={ handleShowProductListFiltersModal }
			/>
		</div>
	)
}

export { SearchResultsFiltersButton }
