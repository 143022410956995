import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import classNames from 'classnames'
import { isEmpty, noop } from 'lodash'

import { IRangeFromToSliderProps } from '~/components/core/rangeFromToSlider'

import styles from './RangeFromToSlider.module.scss'

const RangeFromToSlider = (props: IRangeFromToSliderProps): ReactElement => {
	const {
		min, max, value,
		tabIndex = 0,
		onBlur = noop,
		onChange = noop,
		onAfterChange = noop,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['form'])

	const handleAriaValueTextFormatterForHandle = useCallback((value: number): string => (
		`${ t('wcag.currentValue') }: ${ value }`
	), [])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			<Slider
				range
				className={ styles.slider }
				min={ min }
				max={ max }
				value={ value }
				allowCross={ false }
				tabIndex={ tabIndex }
				ariaLabelForHandle={ t('wcag.currentValue') }
				ariaValueTextFormatterForHandle={ handleAriaValueTextFormatterForHandle }
				onBlur={ onBlur }
				onChange={ onChange }
				onAfterChange={ onAfterChange }
			/>
		</div>
	)
}

export { RangeFromToSlider }
