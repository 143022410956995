import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { SectionHeader } from '~/components/core/sectionHeader'
import { CategoryCarousel } from '~/components/core/categoryCarousel'
import { IProductListRelatedCategoriesProps } from '~/components/productList/productListRelatedCategories'

import styles from './ProductListRelatedCategories.module.scss'

const ProductListRelatedCategories = (props: IProductListRelatedCategoriesProps): ReactElement => {
	const { categories } = props
	const { t } = useTranslation(['productList'])

	return (
		<div className={ styles.wrapper }>
			<SectionHeader title={ t('relatedCategories.title') } />

			<CategoryCarousel
				categories={ categories }
				pageType="WORLD"
				additionalClass={ styles.categories }
			/>
		</div>
	)
}

export { ProductListRelatedCategories }
