import React, { createContext, ReactElement, useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { noop } from 'lodash'

import { ITransactionProviderProps, ITransactionProviderData, getTransactionProviderData, isTransactionSuccess } from '~/providers/transactionProvider'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { IPaymentTransactionResultResponse } from '~/api/dataTypes/transactionManager'
import { getPaymentTransactionResult } from '~/api/requests/transactionManager'

const initialData: ITransactionProviderData = {
	data: {
		isPickupOrder: false,
		isShopDeliveryOrder: false,
		orderFrontId: '',
		orderStatusUrl: '',
		orderNumber: null,
		promiseDate: null,
	},
	orderData: null,
	transactionData: null,
	isTransactionSuccess: false,
	transactionDataRequest: noop,
}

export const TransactionContext = createContext(initialData)

export const TransactionProvider = (props: ITransactionProviderProps): ReactElement => {
	const { children, orderData, transactionData: initialTransactionData } = props
	const [transactionData, setTransactionData] = useState<IPaymentTransactionResultResponse>(initialTransactionData)
	const router = useRouter()
	const { preventAlert } = useAlert()
	const { sendLogError } = useLogError()

	const handleTransactionDataRequest = useCallback(async (): Promise<void> => {
		try {
			const sessionId = router.query.sessionId as string

			const { data } = await getPaymentTransactionResult({ sessionId })

			setTransactionData(data)
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}, [router])

	const providerValue: ITransactionProviderData = useMemo(() => ({
		transactionDataRequest: handleTransactionDataRequest,
		isTransactionSuccess: isTransactionSuccess(transactionData),
		data: getTransactionProviderData(orderData, router),
		orderData,
		transactionData,
	}), [handleTransactionDataRequest, transactionData, orderData])

	return (
		<TransactionContext.Provider value={ providerValue }>
			{ children }
		</TransactionContext.Provider>
	)
}
