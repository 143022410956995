import React, { ReactElement, useCallback } from 'react'

import { Modal } from '~/components/core/modal'
import { IListingAsideWrapperProps } from '~/components/core/listing'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './ListingAsideWrapper.module.scss'

const ListingAsideWrapper = (props: IListingAsideWrapperProps): ReactElement => {
	const { children, setVisibility, isOpen } = props
	const { isDesktop } = useMediaQuery()

	const handleCloseFiltersModal = useCallback((): void => {
		setVisibility(false)
	}, [setVisibility])

	const isModalOpen = isDesktop ? false : isOpen

	return (
		<>
			<div className={ styles.wrapper }>
				{ children }
			</div>

			<Modal
				isOpen={ isModalOpen }
				theme={ { content: styles.modal } }
				onClose={ handleCloseFiltersModal }
			>
				<div className={ styles.content }>
					{ children }
				</div>
			</Modal>
		</>
	)
}

export { ListingAsideWrapper }
