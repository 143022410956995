import { useEffect, useRef } from 'react'
import { useToggle } from 'react-use'
import { isNull } from 'lodash'

import { IUseOnScreenParams } from '~/hooks/onScreen'

export const useOnScreen = (params: IUseOnScreenParams): boolean => {
	const {
		ref,
		options = {},
	} = params
	const observerRef = useRef<IntersectionObserver | null>(null)
	const [isOnScreen, setIsOnScreen] = useToggle(false)

	useEffect(() => {
		observerRef.current = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => (
			setIsOnScreen(entry.isIntersecting)
		), {
			threshold: 1,
			...options,
		})
	}, [options])

	useEffect(() => {
		if (!isNull(ref.current) && observerRef.current) {
			observerRef.current.observe(ref.current)
		}

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect()
			}
		}
	}, [ref.current, observerRef.current])

	return isOnScreen
}
