import { IProductSimpleWithDetails } from '~/api/dataTypes/product'

export const COMPARISON_SITE_MOCK: IProductSimpleWithDetails[] = [
	{
		productSimple: {
			id: 607040,
			name: 'Młot udarowo-obrotowy NUTOOL NRHD900 SDS+ 900W 3.2J',
			brand: '',
			catalogPath: {
				megaWorld: null,
				world: null,
				subWorld: null,
				leaf: null,
				categories: [],
			},
			eanCode: '',
			image: {
				normalUrl: '',
				mouseoverUrl: null,
				thumbnailUrl: 'https://static01.leroymerlin.pl/files/media/image/455/2748455/product/mlot-udarowo-obrotowy-nutool-nrhd900-sds-900w-3-2j,large.jpg',
				main: true,
			},
			productBasicDetailsList: [],
			review: {
				averageRating: 4.2,
				countReviews: 5,
				bestRating: 0,
				worstRating: 0,
			},
			lmReference: '123123',
			price: {
				newPrice: 22.99,
				packagePrice: null,
				packageQuantity: 1,
				basePrice: null,
				newPricePromoLabel: '',
				packagePriceUnit: '',
				basePriceUnit: 'szt.',
				omnibusPrice: null,
				promotionalPrice: false,
			},
			priceByPackageUnit: false,
			promotionLabel: null,
			wwwUrl: '/elektrycznosc/gniazda-wtyczki-akcesoria/serie-gniazd-i-wlacznikow/wlacznik-zwierny-ardea-srebrny-lexman,p607040,l1056.html',
			availability: {
				availabilityType: 'CAN_ADD_TO_CART',
				userStoreStock: null,
			},
			deliveryInfo: {
				productServiceType: 'SHIPMENT',
				title: 'todo',
				description: 'todo',
				subtitle: 'todo',
				isActive: false,
				isOnDemand: false,
			},
			labels: {
				pricePromotionLabel: 'Taniej o 20 zł',
				promotionLabel: {
					code: 'SALE',
					name: 'Promocja',
					promotionTo: 0,
				},
			},
		},
		productDetailList: [
			{
				name: 'Typ instalacji',
				description: 'Wężyki zasilające 40 cm w zestawie',
			},
			{
				name: 'Wysokość całkowita (w cm)',
				description: '13.8',
			},
			{
				name: 'Głębokość wylewki umywalkowej (w cm)',
				description: '13.1',
			},
			{
				name: 'Rodzaj korka',
				description: 'Korek automatyczny',
			},
			{
				name: 'Kolor',
				description: 'Chromowany',
			},
			{
				name: 'Wykończenie produktu',
				description: 'Połysk',
			},
			{
				name: 'Rodzaj Głowicy',
				description: 'Głowica ceramiczna 35 mm',
			},
		],
	},
	{
		productSimple: {
			id: 607041,
			name: 'Młot udarowo-obrotowy GRAPHITE 58G874 SDS Max 1250W 10J',
			brand: '',
			catalogPath: {
				megaWorld: null,
				world: null,
				subWorld: null,
				leaf: null,
				categories: [],
			},
			eanCode: '',
			image: {
				normalUrl: '',
				mouseoverUrl: null,
				thumbnailUrl: 'https://static01.leroymerlin.pl/files/media/image/853/1864853/product/mlot-udarowo-obrotowy-graphite-58g874-sds-max-1250w-10j,large.jpg',
				main: true,
			},
			productBasicDetailsList: [],
			review: {
				averageRating: 4.2,
				countReviews: 5,
				bestRating: 0,
				worstRating: 0,
			},
			lmReference: '123123',
			price: {
				newPrice: 22.99,
				packagePrice: null,
				packageQuantity: 1,
				basePrice: null,
				newPricePromoLabel: '',
				packagePriceUnit: '',
				basePriceUnit: 'szt.',
				omnibusPrice: null,
				promotionalPrice: false,
			},
			priceByPackageUnit: false,
			promotionLabel: null,
			wwwUrl: '/elektrycznosc/gniazda-wtyczki-akcesoria/serie-gniazd-i-wlacznikow/wlacznik-zwierny-ardea-srebrny-lexman,p607040,l1056.html',
			availability: {
				availabilityType: 'CAN_ADD_TO_CART',
				userStoreStock: null,
			},
			deliveryInfo: {
				productServiceType: 'SHIPMENT',
				title: 'todo',
				description: 'todo',
				subtitle: 'todo',
				isActive: false,
				isOnDemand: false,
			},
			labels: {
				pricePromotionLabel: 'Taniej o 20 zł',
				promotionLabel: {
					code: 'SALE',
					name: 'Promocja',
					promotionTo: 0,
				},
			},
		},
		productDetailList:[
			{
				name: 'Typ instalacji',
				description: 'Wężyki zasilające 40 cm w zestawie',
			},
			{
				name: 'Wysokość całkowita (w cm)',
				description: '13.8',
			},
			{
				name: 'Głębokość wylewki umywalkowej (w cm)',
				description: '13.1',
			},
			{
				name: 'Rodzaj korka',
				description: 'Korek automatyczny',
			},
			{
				name: 'Kolor',
				description: 'Chromowany',
			},
			{
				name: 'Wykończenie produktu',
				description: 'Połysk',
			},
			{
				name: 'Rodzaj Głowicy',
				description: 'Głowica ceramiczna 35 mm',
			},
		],
	},
	{
		productSimple: {
			id: 607042,
			name: 'Młotowiertarka CELMA PRWg 26GEO SDS+ 850W 3J',
			brand: '',
			catalogPath: {
				megaWorld: null,
				world: null,
				subWorld: null,
				leaf: null,
				categories: [],
			},
			eanCode: '',
			image: {
				normalUrl: '',
				mouseoverUrl: null,
				thumbnailUrl: 'https://static01.leroymerlin.pl/files/media/image/032/2458032/product/mlotowiertarka-celma-prwg-26geo-sds-850w-3j,large.jpg',
				main: true,
			},
			productBasicDetailsList: [],
			review: {
				averageRating: 4.2,
				countReviews: 5,
				bestRating: 0,
				worstRating: 0,
			},
			lmReference: '123123',
			price: {
				newPrice: 22.99,
				packagePrice: null,
				packageQuantity: 1,
				basePrice: null,
				newPricePromoLabel: '',
				packagePriceUnit: '',
				basePriceUnit: 'szt.',
				omnibusPrice: null,
				promotionalPrice: false,
			},
			priceByPackageUnit: false,
			promotionLabel: null,
			wwwUrl: '/elektrycznosc/gniazda-wtyczki-akcesoria/serie-gniazd-i-wlacznikow/wlacznik-zwierny-ardea-srebrny-lexman,p607040,l1056.html',
			availability: {
				availabilityType: 'CAN_ADD_TO_CART',
				userStoreStock: null,
			},
			deliveryInfo: {
				productServiceType: 'SHIPMENT',
				title: 'todo',
				description: 'todo',
				subtitle: 'todo',
				isActive: false,
				isOnDemand: false,
			},
			labels: {
				pricePromotionLabel: 'Taniej o 20 zł',
				promotionLabel: {
					code: 'SALE',
					name: 'Promocja',
					promotionTo: 0,
				},
			},
		},
		productDetailList:[
			{
				name: 'Typ instalacji',
				description: 'Wężyki zasilające 40 cm w zestawie',

			},
			{
				name: 'Wysokość całkowita (w cm)',
				description: '13.8',

			},
			{
				name: 'Głębokość wylewki umywalkowej (w cm)',
				description: '13.1',

			},
			{
				name: 'Rodzaj korka',
				description: 'Korek automatyczny',
			},
			{
				name: 'Kolor',
				description: 'Chromowany',
			},
			{
				name: 'Wykończenie produktu',
				description: 'Połysk',

			},
			{
				name: 'Rodzaj Głowicy',
				description: 'Głowica ceramiczna 35 mm',
			},
		],
	},
	{
		productSimple: {
			id: 607043,
			name: 'Młot udarowo-obrotowy UD-OBR SDS+ 800W 3.8J',
			brand: '',
			catalogPath: {
				megaWorld: null,
				world: null,
				subWorld: null,
				leaf: null,
				categories: [],
			},
			eanCode: '',
			image: {
				normalUrl: '',
				mouseoverUrl: null,
				thumbnailUrl: 'https://static02.leroymerlin.pl/files/media/image/617/1932617/product/mlot-udarowo-obrotowy-ud-obr-sds-800w-3-8j,large.jpg',
				main: true,
			},
			productBasicDetailsList: [],
			review: {
				averageRating: 4.2,
				countReviews: 5,
				bestRating: 0,
				worstRating: 0,
			},
			lmReference: '123123',
			price: {
				newPrice: 22.99,
				packagePrice: null,
				packageQuantity: 1,
				basePrice: null,
				newPricePromoLabel: '',
				packagePriceUnit: '',
				basePriceUnit: 'szt.',
				omnibusPrice: null,
				promotionalPrice: false,
			},
			priceByPackageUnit: false,
			promotionLabel: null,
			wwwUrl: '/elektrycznosc/gniazda-wtyczki-akcesoria/serie-gniazd-i-wlacznikow/wlacznik-zwierny-ardea-srebrny-lexman,p607040,l1056.html',
			availability: {
				availabilityType: 'CAN_ADD_TO_CART',
				userStoreStock: null,
			},
			deliveryInfo: {
				productServiceType: 'SHIPMENT',
				title: 'todo',
				description: 'todo',
				subtitle: 'todo',
				isActive: false,
				isOnDemand: false,
			},
			labels: {
				pricePromotionLabel: 'Taniej o 20 zł',
				promotionLabel: {
					code: 'SALE',
					name: 'Promocja',
					promotionTo: 0,
				},
			},
		},
		productDetailList:[
			{
				name: 'Typ instalacji',
				description: 'Wężyki zasilające 40 cm w zestawie',
			},
			{
				name: 'Wysokość całkowita (w cm)',
				description: '13.8',
			},
			{
				name: 'Głębokość wylewki umywalkowej (w cm)',
				description: '13.1',
			},
			{
				name: 'Rodzaj korka',
				description: 'Korek automatyczny',
			},
			{
				name: 'Kolor',
				description: 'Chromowany',
			},
			{
				name: 'Wykończenie produktu',
				description: 'Połysk',

			},
			{
				name: 'Rodzaj Głowicy',
				description: 'Głowica ceramiczna 35 mm',
			},
		],
	},
]
