import React, { ReactElement } from 'react'

import { ILastViewedProductsWrapperProps } from '~/components/core/lastViewedProducts'
import { useMounted } from '~/hooks/mounted'

import styles from './LastViewedProductsWrapper.module.scss'

const LastViewedProductsWrapper = (props: ILastViewedProductsWrapperProps): ReactElement | null => {
	const {
		children,
		dataTestId = '',
	} = props
	const { isMounted } = useMounted()

	if (!isMounted) {
		return null
	}

	return (
		<section className={ styles.wrapper } data-testid={ dataTestId }>
			{ children }
		</section>
	)
}

export { LastViewedProductsWrapper }
