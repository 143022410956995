import React, { ReactElement, useCallback, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { IRootState } from '~/state/types'
import { PlannerContext } from '~/providers/plannerProvider'
import { Modal } from '~/components/core/modal'
import { Loader } from '~/components/core/loader'
import { AccountProjectsListAddingForm, AccountProjectsListEmpty, AccountProjectsListPagination } from '~/components/account/accountProjects'
import { AccountProjectsItems } from '~/components/account/accountProjectsItems'

import styles from './PlannerUserProjectsModal.module.scss'

const PlannerUserProjectsModal = (): ReactElement => {
	const { list: { content }, isPending } = useSelector((state: IRootState) => state.account.projects, shallowEqual)
	const { isOpenUserProjectsModal, onCloseUserProjectsModal } = useContext(PlannerContext)
	const { t } = useTranslation(['planner'])

	const theme = {
		wrapper: styles.contentWrapper,
		title: styles.contentTitle,
		button: styles.contentButton,
		input: styles.contentInput,
		information: styles.contentInformation,
	}

	const renderContent = useCallback((): ReactElement => {
		if (isPending) {
			return (
				<Loader />
			)
		}

		if (isEmpty(content)) {
			return (
				<AccountProjectsListEmpty />
			)
		}

		return (
			<>
				<AccountProjectsItems items={ content } />

				<AccountProjectsListPagination />
			</>
		)
	}, [content, isPending])

	return (
		<Modal
			isOpen={ isOpenUserProjectsModal }
			additionalClass={ styles.modal }
			title={ t('userProjectsModal.title') }
			onClose={ onCloseUserProjectsModal }
		>
			{ !isPending && <AccountProjectsListAddingForm theme={ theme } /> }

			{ renderContent() }
		</Modal>
	)
}

export { PlannerUserProjectsModal }
