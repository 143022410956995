import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { map, isEmpty, isNull } from 'lodash'

import { IProductListCategoriesProps, ProductListCategoriesItem } from '~/components/productList/productListCategories'
import { ListingAsideHeader } from '~/components/core/listing'
import { SkipContentAnchor } from '~/components/core/skipContent'
import { SKIP_FILTERS_SUBCATEGORIES } from '~/hooks/skipElements'
import { IProductCatalogWithCountData } from '~/api/dataTypes/catalog'
import { ProductListContext } from '~/providers/productListProvider'

import styles from './ProductListCategories.module.scss'

const ProductListCategories = (props: IProductListCategoriesProps): ReactElement => {
	const { node, params } = props
	const { t } = useTranslation(['productList'])
	const { catalogChildNodeData } = useContext(ProductListContext)

	const { childrenIds, id: activeNodeId, parentWwwUrl, wwwUrl } = node

	const renderCategories = useCallback((): ReactElement[] | null => {
		if (isNull(catalogChildNodeData)) {
			return null
		}

		const { nodesWithProductsCount } = catalogChildNodeData

		return map(nodesWithProductsCount, (nodeWithCount: IProductCatalogWithCountData) => {
			const { node, count } = nodeWithCount
			const { id: nodeId, name, wwwUrl } = node
			const isActive = activeNodeId === node.id

			return (
				<ProductListCategoriesItem
					key={ nodeId }
					wwwUrl={ wwwUrl }
					name={ name }
					count={ count }
					isActive={ isActive }
					params={ params }
				/>
			)
		})
	}, [catalogChildNodeData, activeNodeId, params])

	const allCategoriesUrl = isEmpty(childrenIds) ? parentWwwUrl : wwwUrl
	const allCategoriesCount = isNull(catalogChildNodeData) ? null : catalogChildNodeData.quantityInCurrentNode

	return (
		<div className={ styles.wrapper }>
			<SkipContentAnchor elementId={ SKIP_FILTERS_SUBCATEGORIES } />

			<ListingAsideHeader
				isOpen
				title={ t('categories.title') }
				additionalClass={ styles.header }
			/>

			<ul className={ styles.categories }>
				<ProductListCategoriesItem
					wwwUrl={ allCategoriesUrl }
					count={ allCategoriesCount }
					name={ t('categories.all') }
					isActive={ !isEmpty(childrenIds) }
					params={ params }
				/>

				{ renderCategories() }
			</ul>
		</div>
	)
}

export { ProductListCategories }
