import { IProductListState } from '~/mocks/types'

export const PRODUCT_LIST_MOCK: IProductListState = {
	advices: {
		categories: [
			{
				id: '1',
				wwwUrl: '/',
				label: 'Kalendarz ogrodnika',
			},
			{
				id: '2',
				wwwUrl: '/',
				label: 'Instalacje',
			},
			{
				id: '3',
				wwwUrl: '/',
				label: 'Budowa',
			},
			{
				id: '4',
				wwwUrl: '/',
				label: 'Ogród',
			},
			{
				id: '5',
				wwwUrl: '/',
				label: 'Prace wykończeniowe',
			},
		],
	},
	pagination: {
		perPage: 20,
		totalPages: 3,
		page: 1,
		totalItems: 30,
	},
	relatedCategories: [
		{
			id: '1',
			wwwUrl: '/',
			label: 'Kuchnia',
		},
		{
			id: '2',
			wwwUrl: '/',
			label: 'Łazienka',
		},
		{
			id: '3',
			wwwUrl: '/',
			label: 'Salon',
		},
		{
			id: '4',
			wwwUrl: '/',
			label: 'Dekoracje',
		},
		{
			id: '5',
			wwwUrl: '/',
			label: 'Oświetlenie',
		},
	],
}
