import React, { ReactElement, useCallback } from 'react'
import { isNil, truncate } from 'lodash'

import { IProductListDescriptionProps } from '~/components/productList/productListDescription'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { CollapseText } from '~/components/core/collapseText'

import styles from './ProductListDescription.module.scss'

const ProductListDescription = (props: IProductListDescriptionProps): ReactElement | null => {
	const { title, description } = props
	const { isMobile } = useMediaQuery()

	const renderDescription = useCallback(() => {
		if (!isMobile) {
			return (
				<CollapseText
					additionalClass={ styles.description }
					theme={ { collapseButton: styles.button } }
					text={ truncate(description, { length: 80 }) }
					fullText={ description }
				/>
			)
		}

		return description
	}, [isMobile, description])

	if (isNil(title)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<h1 className={ styles.title }>
				{ title }
			</h1>

			<div>
				{ renderDescription() }
			</div>
		</div>
	)
}

export { ProductListDescription }
