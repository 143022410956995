import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'

import { ICatalogSearchCategoryData } from '~/api/dataTypes/catalog'
import { ISearchResultsCategoriesChildProps } from '~/components/search'
import { Link } from '~/components/core/link'

import styles from './SearchResultsCategoriesChild.module.scss'

const SearchResultsCategoriesChild = (props: ISearchResultsCategoriesChildProps): ReactElement => {
	const { category } = props

	const { name, wwwUrl, count, children, selected, childSelected } = category

	const linkClass = classNames({
		[styles.link]: true,
		[styles.active]: selected,
	})

	const isOpen = selected || childSelected

	const renderChildren = useCallback((): ReactElement[] => (
		map(children, (childCategory: ICatalogSearchCategoryData) => {
			const { id } = childCategory

			return (
				<SearchResultsCategoriesChild key={ id } category={ childCategory } />
			)
		})
	), [children])

	return (
		<div className={ styles.wrapper }>
			<Link
				passHref
				href={ wwwUrl }
				theme={ { wrapper: linkClass } }
				ariaLabel={ `${ name } ${ count }` }
			>
				{ name }

				<span className={ styles.count }>
					{ ` (${ count })` }
				</span>
			</Link>

			{ isOpen && renderChildren() }
		</div>
	)
}

export { SearchResultsCategoriesChild }
