import React, { createContext, ReactElement, useMemo } from 'react'

import { IProductListFiltersContextProps, IProductListFiltersProviderProps } from '~/providers/productListFiltersProvider'

const initialProps: IProductListFiltersContextProps = {
	isParentOpen: false,
}

export const ProductListFiltersContext = createContext(initialProps)

export const ProductListFiltersProvider = (props: IProductListFiltersProviderProps): ReactElement => {
	const { children, isParentOpen } = props

	const providerValue: IProductListFiltersContextProps = useMemo(() => ({
		isParentOpen,
	}), [isParentOpen])

	return (
		<ProductListFiltersContext.Provider value={ providerValue }>
			{ children }
		</ProductListFiltersContext.Provider>
	)
}
