import React, { ReactElement, useState, useContext, useEffect, useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isEmpty, isNil, isNull, isUndefined } from 'lodash'

import { ISearchResultsAsideProps, SearchResultsFilters, SearchResultsCategories } from '~/components/search'
import { ListingAsideWrapper } from '~/components/core/listing'
import { IRootState } from '~/state/types'
import { ICatalogSearchProductFilterResponse } from '~/api/dataTypes/catalog'
import { ProductListFiltersModalContext } from '~/providers/productListFiltersModalProvider'

const SearchResultsAside = (props: ISearchResultsAsideProps): ReactElement | null => {
	const { params, initialFilters } = props
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const [filters, setFilters] = useState<ICatalogSearchProductFilterResponse | null>(initialFilters || null)
	const { handleSetOpen, isOpen } = useContext(ProductListFiltersModalContext)

	const handleSetInitialFilters = useCallback(async (): Promise<void> => {
		if (!isUndefined(initialFilters)) {
			setFilters(initialFilters)
		}
	}, [params, initialFilters])

	useEffect(() => {
		(async () => {
			await handleSetInitialFilters()
		})()
	}, [preferredStore?.storeCode, params, initialFilters])

	return (
		<ListingAsideWrapper isOpen={ isOpen } setVisibility={ handleSetOpen }>
			{ !isNil(initialFilters) && !isEmpty(initialFilters.searchTreeCategoryList) && (
				<SearchResultsCategories categories={ initialFilters.searchTreeCategoryList } />
			) }

			{ !isNull(filters) && (
				<SearchResultsFilters filters={ filters } params={ params } />
			) }
		</ListingAsideWrapper>
	)
}

export { SearchResultsAside }
