import { map } from 'lodash'

import { ISearchProductsParams, IProductSeriesData, IProductCatalogData } from '~/api/dataTypes/catalog'
import { ISeriesItemData } from '~/api/dataTypes/series'
import { getCatalogSearchProducts, getCatalogSearchProductsFilters, getCatalogSearchSeries } from '~/api/requests/productList'
import { IProductListData } from '~/components/productList/types'

// for external -> use productList hook
export const getProductListDataInternal = async (params: ISearchProductsParams, storeCode?: string): Promise<IProductListData> => {
	const [{ data }, { data: filtersData }] = await Promise.all([
		getCatalogSearchProducts(true, params, storeCode),
		getCatalogSearchProductsFilters(true, params, storeCode),
	])

	let productListData: IProductListData = { ...data, params, filters: filtersData, catalogChildNodeData: null }

	if (data.seriesCount > 0) {
		const { data: seriesData } = await getCatalogSearchSeries(true, params)

		productListData = { ...productListData, series: seriesData }
	}

	return productListData
}

export const tmpSeriesProductListMapper = (productListSeries: IProductSeriesData[], node: IProductCatalogData | null): ISeriesItemData[] => (
	map(productListSeries, (seriesItem: IProductSeriesData) => {
		// todo PLEDEV-3143
		const { id, wwwUrl, imageUrl, name, count } = seriesItem

		return ({
			id,
			name,
			imageUrl,
			wwwUrl,
			numberOfProducts: count,
			leafName: node?.name || null,
		})
	})
)
