import React, { ReactElement } from 'react'
import { GetServerSidePropsContext } from 'next'
import dynamic from 'next/dynamic'
import { AxiosResponse } from 'axios'
import { getCookie } from 'cookies-next'
import { isUndefined, toNumber, isEmpty } from 'lodash'

import { PagesConfig } from '~/configs/types'
import { getKobiFlag, getTurbineConfig } from '~/configs/helpers'
import { getProductListDataInternal } from '~/components/productList/helpers'
import { IProductListData } from '~/components/productList/types'
import { ProductList } from '~/components/productList'
import { Search } from '~/components/search'
import { PRODUCT_CARD_OPINIONS_PAGE_SIZE } from '~/components/productCard/productCardOpinions/constants'
import { IRegistrationData, IRegistrationProps } from '~/components/registration/types'
import { PreferredStoreCookieType } from '~/state/reducers/preferredStoreReducer'
import { COMPARISON_SITE_MOCK } from '~/mocks/comparisonMock'
import { cookieParse } from '~/utils/cookies'
import { IGetDefineData } from '~/utils/types'
import { COOKIE_KEY_ADEO_AUTOLOGIN_TOKEN, COOKIE_KEY_ADEO_KOBI_TOKEN, COOKIE_KEY_PREFERRED_STORE } from '~/utils/constants'
import { ComparisonProvider } from '~/providers/comparisonProvider'
import { TransactionProviderInitialDataType, TransactionProvider } from '~/providers/transactionProvider'
import { IPlannerProviderProps } from '~/providers/plannerProvider'
import { IOrdersStatusProps, IOrderStatusData } from '~/components/ordersStatus/types'
import { ICustomerTransformationData, ICustomerTransformationProps } from '~/components/customerTransformation/types'
import { IProductCardProps } from '~/components/productCard/types'
import { ICatalogMegaWorldProps } from '~/components/catalog/catalogMegaWorld/types'
import { ICatalogWorldProps } from '~/components/catalog/catalogWorld/types'
import { IStoreCardProps } from '~/components/storeCard'
import { IStoresProps } from '~/components/stores'
import { IStoreServiceProps } from '~/components/storeService'
import { Layout } from '~/components/core/layout'
import { LayoutCart } from '~/components/core/layout/layoutCart'
import { LayoutAccount } from '~/components/core/layout/layoutAccount'
import { LayoutOrdersStatus } from '~/components/core/layout/layoutOrdersStatus'
import { Loader } from '~/components/core/loader'
import { ICartTransferProps } from '~/components/cartTransfer'
import { Planner } from '~/components/planner'
import { IProductListProviderProps } from '~/providers/productListProvider'
import { ISearchProviderProps } from '~/providers/searchProvider'
import { ITransferProductsToCartParams } from '~/api/dataTypes/cart'
import { ISearchProductsParams, ICatalogWorldResponse } from '~/api/dataTypes/catalog'
import { IDesktopUrlDefinitionResponse } from '~/api/dataTypes/define'
import { IInspirationCategoriesPageData } from '~/api/dataTypes/inspiration'
import { IPlannerResponse } from '~/api/dataTypes/planner'
import { IProductCardResponse, IProductSimpleWithDetails } from '~/api/dataTypes/product'
import { IStoreResponse, ISimpleStoreResponse, IStoreServiceResponse } from '~/api/dataTypes/store'
import { getAuthlessOrderDetails, putConfirmCashOnDeliveryOrder } from '~/api/requests/authlessOrder'
import { getCatalogWorldData } from '~/api/requests/catalog'
import { getProduct, getProductReviews } from '~/api/requests/products'
import { getStore, getStores, getStoreService } from '~/api/requests/store'
import { getPaymentTransactionResult } from '~/api/requests/transactionManager'
import { IServiceCartCreateProps } from '~/components/serviceCart/serviceCartCreate'
import { getCatalogChildNodeProductCount } from '~/api/requests/productList'
import { ICartIdentificationData, ICartIdentificationProps } from '~/components/cart/cartIdentification'
import { ICartPreviewData, ICartPreviewProps } from '~/components/cart/cartPreview'
import { IServiceCartIdentificationData, IServiceCartIdentificationProps } from '~/components/serviceCart/serviceCartIdentification'

import styles from '~/components/core/loader/Loader.module.scss'

const DynamicCartPreview = dynamic<ICartPreviewProps>(() => import('~/components/cart/cartPreview').then((mod: any) => mod.CartPreview), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCartIdentification = dynamic<ICartIdentificationProps>(() => import('~/components/cart/cartIdentification').then((mod: any) => mod.CartIdentification), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCartCustomer = dynamic(() => import('~/components/cart/cartCustomer').then((mod: any) => mod.CartCustomer), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCartSummary = dynamic(() => import('~/components/cart/cartSummary').then((mod: any) => mod.CartSummary), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCartConfirmation = dynamic(() => import('~/components/cart/cartConfirmation').then((mod: any) => mod.CartConfirmation), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicProductListProvider = dynamic<IProductListProviderProps>(() => import('~/providers/productListProvider').then((mod: any) => mod.ProductListProvider), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicSearchProvider = dynamic<ISearchProviderProps>(() => import('~/providers/searchProvider').then((mod: any) => mod.SearchProvider), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicProductCard = dynamic<IProductCardProps>(() => import('~/components/productCard').then((mod: any) => mod.ProductCard), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountOpinionsList = dynamic(() => import('~/components/account/accountOpinions/accountOpinionsList').then((mod: any) => mod.AccountOpinionsList), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountOrders = dynamic(() => import('~/components/account/accountOrders').then((mod: any) => mod.AccountOrders), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountFavorites = dynamic(() => import('~/components/account/accountFavorites').then((mod: any) => mod.AccountFavorites), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountProjectsList = dynamic(() => import('~/components/account/accountProjects/accountProjectsList').then((mod: any) => mod.AccountProjectsList), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountAgreements = dynamic(() => import('~/components/account/accountAgreements').then((mod: any) => mod.AccountAgreements), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountReturnsList = dynamic(() => import('~/components/account/accountReturns/accountReturnsList').then((mod: any) => mod.AccountReturnsList), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountReturnsApplication = dynamic(() => import('~/components/account/accountReturns/accountReturnsApplication').then((mod: any) => mod.AccountReturnsApplication), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountReturnsSent = dynamic(() => import('~/components/account/accountReturns/accountReturnsSent').then((mod: any) => mod.AccountReturnsSent), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountReturnsApplicationOrder = dynamic(() => import('~/components/account/accountReturns/accountReturnsApplicationOrder').then((mod: any) => mod.AccountReturnsApplicationOrder), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountUserDataFooter = dynamic(() => import('~/components/account/accountUserData/accountUserDataFooter').then((mod: any) => mod.AccountUserDataFooter), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAccountUserData = dynamic(() => import('~/components/account/accountUserData').then((mod: any) => mod.AccountUserData), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicHome = dynamic(() => import('~/components/home').then((mod: any) => mod.Home), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicSignIn = dynamic(() => import('~/components/signIn').then((mod: any) => mod.SignIn), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicRegistration = dynamic<IRegistrationProps>(() => import('~/components/registration').then((mod: any) => mod.Registration), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicPlannerProvider = dynamic<IPlannerProviderProps>(() => import('~/providers/plannerProvider').then((mod: any) => mod.PlannerProvider), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCatalogMegaWorld = dynamic<ICatalogMegaWorldProps>(() => import('~/components/catalog/catalogMegaWorld').then((mod: any) => mod.CatalogMegaWorld), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCatalogWorld = dynamic<ICatalogWorldProps>(() => import('~/components/catalog/catalogWorld').then((mod: any) => mod.CatalogWorld), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicContactFooter = dynamic(() => import('~/components/contactFooter').then((mod: any) => mod.ContactFooter), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicAlternateTemporaryPage = dynamic(() => import('~/components/alternateTemporaryPage').then((mod: any) => mod.AlternateTemporaryPage), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicTransaction = dynamic(() => import('~/components/transaction').then((mod: any) => mod.Transaction), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicOrderStatus = dynamic<IOrdersStatusProps>(() => import('~/components/ordersStatus').then((mod: any) => mod.OrdersStatus), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicContact = dynamic(() => import('~/components/contact').then((mod: any) => mod.Contact), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicStores = dynamic<IStoresProps>(() => import('~/components/stores').then((mod: any) => mod.Stores), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicStoreService = dynamic<IStoreServiceProps>(() => import('~/components/storeService').then((mod: any) => mod.StoreService), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicStoreCard = dynamic<IStoreCardProps>(() => import('~/components/storeCard').then((mod: any) => mod.StoreCard), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicComparison = dynamic(() => import('~/components/comparison').then((mod: any) => mod.Comparison), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicNotFound = dynamic(() => import('~/components/core/notFound').then((mod: any) => mod.NotFound), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCustomerTransformation = dynamic<ICustomerTransformationProps>(() => import('~/components/customerTransformation').then((mod: any) => mod.CustomerTransformation), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicPasswordChanger = dynamic(() => import('~/components/password/passwordChanger').then((mod: any) => mod.PasswordChanger), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicPasswordReminder = dynamic(() => import('~/components/password/passwordReminder').then((mod: any) => mod.PasswordReminder), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicCartTransfer = dynamic<ICartTransferProps>(() => import('~/components/cartTransfer').then((mod: any) => mod.CartTransfer), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicServiceCartCreate = dynamic<IServiceCartCreateProps>(() => import('~/components/serviceCart/serviceCartCreate').then((mod: any) => mod.ServiceCartCreate), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicServiceCartPreview = dynamic(() => import('~/components/serviceCart/serviceCartPreview').then((mod: any) => mod.ServiceCartPreview), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicServiceCartCustomer = dynamic(() => import('~/components/serviceCart/serviceCartCustomer').then((mod: any) => mod.ServiceCartCustomer), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicServiceCartSummary = dynamic(() => import('~/components/serviceCart/serviceCartSummary').then((mod: any) => mod.ServiceCartSummary), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicServiceCartConfirmation = dynamic(() => import('~/components/serviceCart/serviceCartConfirmation').then((mod: any) => mod.ServiceCartConfirmation), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicServiceCartIdentification = dynamic<IServiceCartIdentificationProps>(() => import('~/components/serviceCart/serviceCartIdentification').then((mod: any) => mod.ServiceCartIdentification), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })
const DynamicNewsletter = dynamic(() => import('~/components/newsletter').then((mod: any) => mod.Newsletter), { loading: () => <Loader theme={ { wrapper: styles.pagesConfigLoader } } /> })

export const PAGES_CONFIG: PagesConfig = {
	MAIN_PAGE: {
		render: () => <DynamicHome />,
		getServerSideProps: async () => {
			return {
				pageType: 'MAIN_PAGE',
				data: {},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="MAIN_PAGE">
				{ page }
			</Layout>
		),
	},
	PRODUCT_LIST: {
		render: ({ data }: IGetDefineData<IProductListData>) => (
			<DynamicProductListProvider value={ data }>
				<ProductList />
			</DynamicProductListProvider>
		),
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query, req } = ctx
			let mappedParams: ISearchProductsParams = defineResponse?.data.queryParameterMap || query
			const userStore = cookieParse<PreferredStoreCookieType>(req.cookies[COOKIE_KEY_PREFERRED_STORE])

			if (!isUndefined(query?.noCache)) {
				mappedParams = { ...mappedParams, noCache: query?.noCache }
			}

			const productListData = await getProductListDataInternal(mappedParams, userStore?.storeCode)

			const { node } = productListData

			const mainNodeId = isEmpty(node?.childrenIds) ? node?.parentId : node?.id

			const { data: catalogChildNodeData } = await getCatalogChildNodeProductCount(mainNodeId, query?.noCache)

			const data: IProductListData = {
				...productListData,
				catalogChildNodeData,
			}

			return {
				pageType: 'PRODUCT_LIST',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="PRODUCT_LIST">
				{ page }
			</Layout>
		),
	},
	PRODUCT_CARD: {
		render: ({ data }: IGetDefineData<IProductCardResponse & IInspirationCategoriesPageData>) => <DynamicProductCard productCard={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query, req } = ctx
			const mappedId = defineResponse?.data.pathVariableMap.lmReference || query?.id as string
			const userStore = cookieParse<PreferredStoreCookieType>(req.cookies[COOKIE_KEY_PREFERRED_STORE])

			const [{ data: productCard }, { data: reviews }] = await Promise.all([
				getProduct({ lmReference: mappedId, storeCode: userStore?.storeCode, noCache: query?.noCache }),
				getProductReviews(mappedId, { page: 1, size: PRODUCT_CARD_OPINIONS_PAGE_SIZE, noCache: query?.noCache }, true),
			])

			const data: IProductCardResponse & IInspirationCategoriesPageData = {
				...productCard,
				reviews,
			}

			return {
				pageType: 'PRODUCT_CARD',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="PRODUCT_CARD">
				{ page }
			</Layout>
		),
	},
	SEARCH_PRODUCTS: {
		render: ({ data }: IGetDefineData<IProductListData>) => (
			<DynamicSearchProvider value={ data }>
				<Search />
			</DynamicSearchProvider>
		),
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query, req } = ctx
			let mappedParams: ISearchProductsParams = defineResponse?.data.queryParameterMap || query
			const userStore = cookieParse<PreferredStoreCookieType>(req.cookies[COOKIE_KEY_PREFERRED_STORE])

			if (!isUndefined(query?.noCache)) {
				mappedParams = { ...mappedParams, noCache: query?.noCache }
			}

			const productListData = await getProductListDataInternal(mappedParams, userStore?.storeCode)

			const { redirectUrl } = productListData

			return {
				pageType: 'SEARCH_PRODUCTS',
				data: productListData,
				...redirectUrl && {
					redirect: {
						destination: redirectUrl,
						permanent: false,
					},
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="SEARCH_PRODUCTS">
				{ page }
			</Layout>
		),
	},
	CART_FINALIZE: {
		render: () => <DynamicCartConfirmation />,
		getServerSideProps: async () => {
			return {
				pageType: 'CART_FINALIZE',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CART_FINALIZE">
				{ page }
			</LayoutCart>
		),
	},
	CART_CUSTOMER: {
		render: () => <DynamicCartCustomer />,
		getServerSideProps: async () => {
			return {
				pageType: 'CART_CUSTOMER',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CART_CUSTOMER">
				{ page }
			</LayoutCart>
		),
	},
	CART_IDENTIFICATION: {
		render: ({ data }: IGetDefineData<ICartIdentificationData>) => <DynamicCartIdentification data={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext) => {
			const { req, res } = ctx

			const autologinCookieSSR = getCookie(COOKIE_KEY_ADEO_AUTOLOGIN_TOKEN, { req, res })
			const kobiJwt = getCookie(COOKIE_KEY_ADEO_KOBI_TOKEN, { req, res })

			return {
				pageType: 'CART_IDENTIFICATION',
				data: {
					autologinCookieSSR: kobiJwt ? undefined : autologinCookieSSR,
					kobiJwt,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CART_IDENTIFICATION">
				{ page }
			</LayoutCart>
		),
	},
	CART: {
		render: ({ data }: IGetDefineData<ICartPreviewData>) => <DynamicCartPreview data={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext) => {
			const { req, res } = ctx

			const kobiJwt = getCookie(COOKIE_KEY_ADEO_KOBI_TOKEN, { req, res })
			const isKobiIntegrationEnabled = await getKobiFlag()

			const shouldGetCustomerData = isKobiIntegrationEnabled ? !isUndefined(kobiJwt) : true

			return {
				pageType: 'CART',
				data: {
					shouldGetCustomerData,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CART">
				{ page }
			</LayoutCart>
		),
	},
	CART_SUMMARY: {
		render: () => <DynamicCartSummary />,
		getServerSideProps: async () => {
			return {
				pageType: 'CART_SUMMARY',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CART_SUMMARY">
				{ page }
			</LayoutCart>
		),
	},
	MEGA_WORLD: {
		render: ({ data }: IGetDefineData<ICatalogWorldResponse>) => <DynamicCatalogMegaWorld megaWorld={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query } = ctx

			const mappedId = toNumber(defineResponse?.data.queryParameterMap.node || query?.id)

			const { data } = await getCatalogWorldData(mappedId, query?.noCache)

			return {
				pageType: 'MEGA_WORLD',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="MEGA_WORLD">
				{ page }
			</Layout>
		),
	},
	WORLD: {
		render: ({ data }: IGetDefineData<ICatalogWorldResponse>) => <DynamicCatalogWorld world={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query } = ctx

			const mappedId = toNumber(defineResponse?.data.queryParameterMap.node || query?.id)

			const { data } = await getCatalogWorldData(mappedId, query?.noCache)

			return {
				pageType: 'WORLD',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="WORLD">
				{ page }
			</Layout>
		),
	},
	PASSWORD_RESET: {
		render: () => <DynamicPasswordChanger />,
		getServerSideProps: async () => {
			return {
				pageType: 'PASSWORD_RESET',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout
				hideNewsletter
				pageType="PASSWORD_RESET"
				AdditionalCustomFooter={ <DynamicContactFooter /> }
			>
				{ page }
			</Layout>
		),
	},
	PASSWORD_RECOVERY: {
		render: () => <DynamicPasswordReminder />,
		getServerSideProps: async () => {
			return {
				pageType: 'PASSWORD_RECOVERY',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout
				hideNewsletter
				pageType="PASSWORD_RECOVERY"
				AdditionalCustomFooter={ <DynamicContactFooter /> }
			>
				{ page }
			</Layout>
		),
	},
	STORE_PAGE: {
		render: ({ data }: IGetDefineData<IStoreResponse>) => <DynamicStoreCard storeCard={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query } = ctx
			const mappedId = defineResponse?.data.pathVariableMap.shopCode || query?.id as string

			const { data } = await getStore({ storeCode: mappedId, isInternal: true })

			return {
				pageType: 'STORE_PAGE',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="STORE_PAGE">
				{ page }
			</Layout>
		),
	},
	STORES: {
		render: ({ data }: IGetDefineData<ISimpleStoreResponse[]>) => <DynamicStores stores={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext) => {
			const { query: { noCache } } = ctx

			const { data } = await getStores({ isInternal: true, noCache })

			return {
				pageType: 'STORES',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="STORES">
				{ page }
			</Layout>
		),
	},
	REGISTRATION: {
		render: ({ data: { fromPath } }: IGetDefineData<IRegistrationData>) => <DynamicRegistration fromPath={ fromPath } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext) => {
			const { query: { from: fromPath = null } } = ctx

			return {
				pageType: 'REGISTRATION',
				data: {
					fromPath,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout
				hideNewsletter
				pageType="REGISTRATION"
				AdditionalCustomFooter={ <DynamicContactFooter /> }
			>
				{ page }
			</Layout>
		),
	},
	LOGIN: {
		render: () => <DynamicSignIn />,
		getServerSideProps: async () => {
			return {
				pageType: 'LOGIN',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout
				hideNewsletter
				pageType="LOGIN"
				AdditionalCustomFooter={ <DynamicContactFooter /> }
			>
				{ page }
			</Layout>
		),
	},
	ACCOUNT_USER_DATA: {
		render: () => <DynamicAccountUserData />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_USER_DATA',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout
				hideNewsletter
				pageType="ACCOUNT_USER_DATA"
				AdditionalCustomFooter={ <DynamicAccountUserDataFooter /> }
			>
				<LayoutAccount pageType="ACCOUNT_USER_DATA">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_MARKETING_CONSENTS: {
		render: () => <DynamicAccountAgreements />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_MARKETING_CONSENTS',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_MARKETING_CONSENTS">
				<LayoutAccount pageType="ACCOUNT_MARKETING_CONSENTS">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_ORDERS: {
		render: () => <DynamicAccountOrders />,
		getServerSideProps: async () => {
			return {
				pageType: 'ACCOUNT_ORDERS',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_ORDERS">
				<LayoutAccount pageType="ACCOUNT_ORDERS">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_PROJECTS: {
		render: () => <DynamicAccountProjectsList />,
		getServerSideProps: async () => {
			return {
				pageType: 'ACCOUNT_PROJECTS',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_PROJECTS">
				<LayoutAccount pageType="ACCOUNT_PROJECTS">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_RETURNS: {
		render: () => <DynamicAccountReturnsList />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_RETURNS',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_RETURNS">
				<LayoutAccount pageType="ACCOUNT_RETURNS">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_RETURNS_APPLICATION_ORDER: {
		render: () => <DynamicAccountReturnsApplicationOrder />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_RETURNS_APPLICATION_ORDER',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_RETURNS_APPLICATION_ORDER">
				<LayoutAccount pageType="ACCOUNT_RETURNS_APPLICATION_ORDER">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_RETURNS_APPLICATION: {
		render: () => <DynamicAccountReturnsApplication />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_RETURNS_APPLICATION',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_RETURNS_APPLICATION">
				<LayoutAccount pageType="ACCOUNT_RETURNS_APPLICATION">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_RETURNS_APPLICATION_SENT: {
		render: () => <DynamicAccountReturnsSent />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_RETURNS_APPLICATION_SENT',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_RETURNS_APPLICATION_SENT">
				<LayoutAccount pageType="ACCOUNT_RETURNS_APPLICATION_SENT">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	ACCOUNT_REVIEWS: {
		render: () => <DynamicAccountOpinionsList />,
		getServerSideProps: async () => {
			const isKobiEnabled = await getKobiFlag()

			if (isKobiEnabled) {
				return {
					pageType: 'UNKNOWN',
					data: null,
				}
			}

			return {
				pageType: 'ACCOUNT_REVIEWS',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="ACCOUNT_REVIEWS">
				<LayoutAccount pageType="ACCOUNT_REVIEWS">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	COMPARISON: {
		// FIXME to change after api connection
		render: ({ data }: IGetDefineData<IProductSimpleWithDetails[]>) => (
			<ComparisonProvider data={ data }>
				<DynamicComparison />
			</ComparisonProvider>
		),
		getServerSideProps: async () => {
			return {
				pageType: 'COMPARISON',
				data: COMPARISON_SITE_MOCK,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="COMPARISON">
				{ page }
			</Layout>
		),
	},
	CONTACT: {
		render: () => <DynamicContact />,
		getServerSideProps: async () => {
			return {
				pageType: 'CONTACT',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="CONTACT">
				{ page }
			</Layout>
		),
	},
	SHOPPING_LIST: {
		render: () => <DynamicAccountFavorites />,
		getServerSideProps: async () => {
			return {
				pageType: 'SHOPPING_LIST',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout hideNewsletter pageType="SHOPPING_LIST">
				<LayoutAccount pageType="SHOPPING_LIST">
					{ page }
				</LayoutAccount>
			</Layout>
		),
	},
	STORE_SERVICE: {
		render: ({ data }: IGetDefineData<IStoreServiceResponse>) => (
			<DynamicStoreService data={ data } />
		),
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query } = ctx
			const mappedId = defineResponse?.data.pathVariableMap.storeService || query?.id as string

			const { data } = await getStoreService({ storeCode: mappedId, noCache: query.noCache })

			return {
				pageType: 'STORE_SERVICE',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="STORE_SERVICE">
				{ page }
			</Layout>
		),
	},
	CALENDAR_CART: {
		render: () => <DynamicAlternateTemporaryPage />,
		getServerSideProps: async () => {
			return {
				pageType: 'CALENDAR_CART',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="CALENDAR_CART">
				{ page }
			</Layout>
		),
	},
	PLANNER: {
		render: ({ data }: IGetDefineData<IPlannerResponse>) => (
			<DynamicPlannerProvider data={ data }>
				<Planner />
			</DynamicPlannerProvider>
		),
		getServerSideProps: async (_: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const number = defineResponse?.data.queryParameterMap.number || null
			const shopVersion = defineResponse?.data.queryParameterMap.shopVersion || false

			return {
				pageType: 'PLANNER',
				data: {
					number,
					shopVersion,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="PLANNER">
				{ page }
			</Layout>
		),
	},
	TEMPORARY_ALTERNATE_PAGE: {
		render: () => <DynamicAlternateTemporaryPage />,
		getServerSideProps: async () => {
			return {
				pageType: 'TEMPORARY_ALTERNATE_PAGE',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="TEMPORARY_ALTERNATE_PAGE">
				{ page }
			</Layout>
		),
	},
	PRZELEWY24_SUCCESS: {
		render: ({ data: { orderData, transactionData } }: IGetDefineData<TransactionProviderInitialDataType>) => (
			<TransactionProvider orderData={ orderData } transactionData={ transactionData }>
				<DynamicTransaction />
			</TransactionProvider>
		),
		getServerSideProps: async (ctx: GetServerSidePropsContext) => {
			const { query } = ctx

			const orderFrontId = query.orderFrontId as string
			const sessionId = query.sessionId as string
			const noCache = query.noCache as string

			const turbineConfig = await getTurbineConfig()

			const [{ data: orderData }, { data: transactionData }] = await Promise.all([
				getAuthlessOrderDetails({ orderFrontId, isInternal: true, noCache, isTurbine: turbineConfig.authlessOrderService }),
				getPaymentTransactionResult({ sessionId, isInternal: true, noCache, isTurbine: turbineConfig.transactionManagerService }),
			])

			return {
				pageType: 'PRZELEWY24_SUCCESS',
				data: { orderData, transactionData },
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="PRZELEWY24_SUCCESS">
				{ page }
			</LayoutCart>
		),
	},
	UNKNOWN: {
		render: () => <DynamicNotFound />,
		getServerSideProps: async () => {
			return {
				pageType: 'UNKNOWN',
				notFound: true,
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="ERROR">
				{ page }
			</Layout>
		),
	},
	ORDER_PAYMENT: {
		render: ({ data }: IGetDefineData<IOrderStatusData>) => <DynamicOrderStatus data={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query: { noCache } } = ctx
			const orderFrontId = defineResponse?.data.pathVariableMap.orderFrontId || ''

			const turbineConfig = await getTurbineConfig()

			const { data } = await getAuthlessOrderDetails({ orderFrontId, isInternal: true, noCache, isTurbine: turbineConfig.authlessOrderService })

			return {
				pageType: 'ORDER_PAYMENT',
				data: {
					orderData: data,
					orderFrontId,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutOrdersStatus pageType="ORDER_PAYMENT">
				{ page }
			</LayoutOrdersStatus>
		),
	},
	ORDER_CONFIRMATION: {
		render: ({ data }: IGetDefineData<IOrderStatusData>) => <DynamicOrderStatus data={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query: { noCache } } = ctx
			const orderFrontId = defineResponse?.data.pathVariableMap.orderFrontId || ''

			try {
				await putConfirmCashOnDeliveryOrder(orderFrontId, true)
			} catch (_: unknown) {}

			const turbineConfig = await getTurbineConfig()

			const { data } = await getAuthlessOrderDetails({ orderFrontId, isInternal: true, noCache, isTurbine: turbineConfig.authlessOrderService })

			return {
				pageType: 'ORDER_CONFIRMATION',
				data: {
					orderData: data,
					orderFrontId,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutOrdersStatus pageType="ORDER_CONFIRMATION">
				{ page }
			</LayoutOrdersStatus>
		),
	},
	ORDER_STATUS: {
		render: ({ data }: IGetDefineData<IOrderStatusData>) => <DynamicOrderStatus data={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { query: { noCache } } = ctx
			const orderFrontId = defineResponse?.data.pathVariableMap.orderFrontId || ''

			const turbineConfig = await getTurbineConfig()

			const { data } = await getAuthlessOrderDetails({ orderFrontId, isInternal: true, noCache, isTurbine: turbineConfig.authlessOrderService })

			return {
				pageType: 'ORDER_STATUS',
				data: {
					orderData: data,
					orderFrontId,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutOrdersStatus pageType="ORDER_STATUS">
				{ page }
			</LayoutOrdersStatus>
		),
	},
	ACCOUNT_ACTIVATION: {
		render: ({ data }: IGetDefineData<ICustomerTransformationData>) => <DynamicCustomerTransformation data={ data } />,
		getServerSideProps: async (_: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const token = defineResponse?.data.queryParameterMap.token || ''
			const emailAddress = defineResponse?.data.queryParameterMap.emailAddress || ''

			return {
				pageType: 'ACCOUNT_ACTIVATION',
				data: {
					token,
					emailAddress: emailAddress.replaceAll(/\s/g, '+'),
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutOrdersStatus pageType="ACCOUNT_ACTIVATION">
				{ page }
			</LayoutOrdersStatus>
		),
	},
	TRANSFER_CART: {
		render: ({ data }: IGetDefineData<ITransferProductsToCartParams>) => <DynamicCartTransfer data={ data } />,
		getServerSideProps: async (_: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const data = defineResponse?.data.queryParameterMap.products || {}

			return {
				pageType: 'TRANSFER_CART',
				data,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="TRANSFER_CART">
				{ page }
			</LayoutCart>
		),
	},
	CALENDAR_CART_APPOINTMENT: {
		render: () => <DynamicServiceCartPreview />,
		getServerSideProps: async () => {
			return {
				pageType: 'CALENDAR_CART_APPOINTMENT',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CALENDAR_CART_APPOINTMENT">
				{ page }
			</LayoutCart>
		),
	},
	CALENDAR_CART_LOGIN: {
		render: ({ data }: IGetDefineData<IServiceCartIdentificationData>) => <DynamicServiceCartIdentification data={ data } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext) => {
			const { req, res } = ctx

			const autologinCookieSSR = getCookie(COOKIE_KEY_ADEO_AUTOLOGIN_TOKEN, { req, res })
			const kobiJwt = getCookie(COOKIE_KEY_ADEO_KOBI_TOKEN, { req, res })

			return {
				pageType: 'CALENDAR_CART_LOGIN',
				data: {
					autologinCookieSSR: kobiJwt ? undefined : autologinCookieSSR,
					kobiJwt,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CALENDAR_CART_LOGIN">
				{ page }
			</LayoutCart>
		),
	},
	CALENDAR_CART_CUSTOMER_DATA: {
		render: () => <DynamicServiceCartCustomer />,
		getServerSideProps: async () => {
			return {
				pageType: 'CALENDAR_CART_CUSTOMER_DATA',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CALENDAR_CART_CUSTOMER_DATA">
				{ page }
			</LayoutCart>
		),
	},
	CALENDAR_CART_SUMMARY: {
		render: () => <DynamicServiceCartSummary />,
		getServerSideProps: async () => {
			return {
				pageType: 'CALENDAR_CART_SUMMARY',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CALENDAR_CART_SUMMARY">
				{ page }
			</LayoutCart>
		),
	},
	CALENDAR_CART_CONFIRMATION: {
		render: () => <DynamicServiceCartConfirmation />,
		getServerSideProps: async () => {
			return {
				pageType: 'CALENDAR_CART_CONFIRMATION',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CALENDAR_CART_CONFIRMATION">
				{ page }
			</LayoutCart>
		),
	},
	CALENDAR_CART_CREATE: {
		render: ({ data }: IGetDefineData<IServiceCartCreateProps>) => <DynamicServiceCartCreate lmReference={ data.lmReference } shouldGetCustomerData={ data.shouldGetCustomerData } />,
		getServerSideProps: async (ctx: GetServerSidePropsContext, defineResponse?: AxiosResponse<IDesktopUrlDefinitionResponse>) => {
			const { req, res } = ctx
			const lmReference = defineResponse?.data.pathVariableMap.lmReference || ''

			const kobiJwt = getCookie(COOKIE_KEY_ADEO_KOBI_TOKEN, { req, res })
			const isKobiIntegrationEnabled = await getKobiFlag()

			const shouldGetCustomerData = isKobiIntegrationEnabled ? !isUndefined(kobiJwt) : true

			return {
				pageType: 'CALENDAR_CART_CREATE',
				data: {
					lmReference,
					shouldGetCustomerData,
				},
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<LayoutCart pageType="CALENDAR_CART_CREATE">
				{ page }
			</LayoutCart>
		),
	},
	LOYALTY_PILOT: {
		render: () => <DynamicNotFound />,
		getServerSideProps: async () => {
			return {
				pageType: 'UNKNOWN',
				notFound: true,
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="ERROR">
				{ page }
			</Layout>
		),
	},
	NEWSLETTER: {
		render: () => <DynamicNewsletter />,
		getServerSideProps: async () => {
			return {
				pageType: 'NEWSLETTER',
				data: null,
			}
		},
		getLayout: (page: ReactElement): ReactElement => (
			<Layout pageType="NEWSLETTER">
				{ page }
			</Layout>
		),
	},
}
