import React, { ReactElement } from 'react'

import { ISearchNoResultsProps, SearchNoResultsInfo } from '~/components/search'
import { LastViewedProducts } from '~/components/core/lastViewedProducts'
import { DATA_TESTID } from '~/utils/dataTestId'

const SearchNoResults = (props: ISearchNoResultsProps): ReactElement => {
	const { originalQuery } = props

	return (
		<>
			<SearchNoResultsInfo originalQuery={ originalQuery } />

			<LastViewedProducts dataTestId={ DATA_TESTID.SEARCH.LAST_VIEWED_PRODUCTS } />
		</>

	)
}

export { SearchNoResults }
