import { isEmpty, map, size, split } from 'lodash'

import { IPlannerProjectInfo } from '~/hooks/plannerIframe'
import { ICustomerProjectData } from '~/api/dataTypes/customerProject'

export const mapProjectFormData = (projectInfo: IPlannerProjectInfo, email: string = ''): FormData => {
	const { image, project, params: { name } } = projectInfo
	const data: ICustomerProjectData = {
		imageFile: new Blob([image ?? new Uint8Array()], { type: 'application/octet-stream' }),
		name,
		projectFile: new Blob([project], { type: 'application/octet-stream' }),
	}

	const formData = new FormData()

	formData.append('projectFile', data.projectFile)
	formData.append('imageFile', data.imageFile)
	formData.append('name', data.name)

	if (!isEmpty(email)) {
		formData.append('email', email)
	}

	return formData
}

export const splitToNumber = (data: string): number[] => (
	map(split(data, ','), Number)
)

export const valueToUint8Array = (value: string): Uint8Array => {
	const raw = window.atob(value)
	const rawLength = size(raw)
	const array = new Uint8Array(new ArrayBuffer(rawLength))

	for (let i=0; i<rawLength; i++) {
		array[i] = raw.charCodeAt(i)
	}

	return array
}
