import React, { useCallback, ReactElement, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import { Collapse } from 'react-collapse'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { isEmpty, map } from 'lodash'

import { IProductListFiltersPromotionsProps, isAnyFilterSelected, IProductListFiltersData } from '~/components/productList/productListFilters'
import { ListingFiltersItem, ListingFiltersCategory } from '~/components/core/listing'
import { IFilterAttributePromotionData } from '~/api/dataTypes/catalog'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './ProductListFiltersPromotions.module.scss'

const ProductListFiltersPromotions = (props: IProductListFiltersPromotionsProps): ReactElement | null => {
	const { promotions } = props
	const { t } = useTranslation(['productList'])
	const [isOpen, setIsOpen] = useToggle(isAnyFilterSelected(promotions))
	const { isDesktop } = useMediaQuery()
	const { control } = useFormContext<IProductListFiltersData>()

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const renderFilters = useCallback((): ReactElement[] => {
		const tabIndex = isDesktop || isOpen ? 0 : -1

		return map(promotions, (field: IFilterAttributePromotionData): ReactElement => {
			const { name, value } = field

			return (
				<ListingFiltersItem
					key={ name }
					control={ control }
					name={ `promotionLabels.${ value }` }
					field={ field }
					tabIndex={ tabIndex }
				/>
			)
		})
	}, [promotions, isOpen, isDesktop])

	const renderContent = useCallback((): ReactElement | ReactElement[] => {
		if (isDesktop) {
			return renderFilters()
		}

		const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

		return (
			<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
				{ renderFilters() }
			</Collapse>
		)
	}, [isDesktop, isOpen, promotions])

	useEffect(() => {
		setIsOpen(isAnyFilterSelected(promotions))
	}, [promotions])

	if (isEmpty(promotions)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<ListingFiltersCategory
				title={ t('filters.promotions') }
				isOpen={ isOpen }
				onToggleOpen={ handleToggleOpen }
			/>

			{ renderContent() }
		</div>
	)
}

export { ProductListFiltersPromotions }
