import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { useMediaQuery } from '~/hooks/mediaQuery'
import { ICustomerProject } from '~/api/dataTypes/customerProject'
import { IAccountProjectsItemsProps, AccountProjectsItemsRow, AccountProjectsItemsHead } from '~/components/account/accountProjectsItems'

import styles from './AccountProjectsItems.module.scss'

const AccountProjectsItems = (props: IAccountProjectsItemsProps): ReactElement => {
	const { items } = props
	const { isDesktop } = useMediaQuery()

	const renderContent = useCallback((): ReactElement[] => (
		map(items, (item: ICustomerProject): ReactElement => (
			<AccountProjectsItemsRow key={ item.number } project={ item } />
		))
	), [items])

	return (
		<div className={ styles.wrapper }>
			{ isDesktop && <AccountProjectsItemsHead /> }

			{ renderContent() }
		</div>
	)
}

export { AccountProjectsItems }
