import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isUndefined } from 'lodash'

import { useProductList } from '~/hooks/productList'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { PerPage } from '~/components/core/perPage'
import { PaginationSmall } from '~/components/core/paginationSmall'
import { Pagination } from '~/components/core/pagination'
import { IProductListPaginationProps } from '~/components/productList/productListPagination'
import { PRODUCT_LIST_PER_PAGE_OPTIONS, PRODUCT_LIST_PER_PAGE_DEFAULT_VALUE } from '~/components/productList/constants'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'

import styles from './ProductListPagination.module.scss'

const ProductListPagination = (props: IProductListPaginationProps): ReactElement => {
	const {
		pageable, onChangePage,
		templateUrl = '',
		baseUrl = '',
		params = {},
		nextPageParams = {},
	} = props
	const { t } = useTranslation(['productList'])
	const { isTablet } = useMediaQuery()
	const { getProductListData, getPaginationUrls, getPaginationUrl } = useProductList()

	const { pageNumber, totalPages } = pageable
	const selectedPageSize: number = !isUndefined(params) && params.size ? params.size : PRODUCT_LIST_PER_PAGE_DEFAULT_VALUE

	const handleChangePageSize = useCallback(async (size: number): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			sort: nextPageParams.sort,
			size,
		}

		await getProductListData(newFilterParams)
	}, [getProductListData, nextPageParams])

	const handleTemplateUrl = useCallback((page: number): string => (
		getPaginationUrl(page, templateUrl)
	), [templateUrl])

	const renderPagination = useCallback((): ReactElement => {
		const paginationUrls = getPaginationUrls(templateUrl, baseUrl)

		if (isTablet) {
			return (
				<Pagination
					baseUrl={ baseUrl }
					templateUrl={ handleTemplateUrl }
					paginationUrls={ [paginationUrls.prev, paginationUrls.next] }
					initialPage={ pageNumber }
					totalPages={ totalPages }
					onPageChange={ onChangePage }
				/>
			)
		}

		return (
			<PaginationSmall
				paginationUrls={ [paginationUrls.prev, paginationUrls.next] }
				initialPage={ pageNumber }
				totalPages={ totalPages }
				onPageChange={ onChangePage }
			/>
		)
	}, [pageNumber, totalPages, isTablet, nextPageParams, templateUrl, baseUrl, getPaginationUrls])

	return (
		<div className={ styles.wrapper }>
			<PerPage
				label={ t('pagination.results') }
				options={ PRODUCT_LIST_PER_PAGE_OPTIONS }
				value={ selectedPageSize }
				onChange={ handleChangePageSize }
			/>

			{ renderPagination() }
		</div>
	)
}

export { ProductListPagination }
