import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

const AccountProjectsListEmpty = (): ReactElement => {
	const { t } = useTranslation(['account'])

	return (
		<div>
			{ t('projects.list.emptyListText') }
		</div>
	)
}

export { AccountProjectsListEmpty }
