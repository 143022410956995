import React, { createContext, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useToggle } from 'react-use'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { isNil, isNull, noop, toString } from 'lodash'

import { IPlannerContextProps, IPlannerProviderProps } from '~/providers/plannerProvider'
import { useModal } from '~/hooks/modal'
import { INotAddedProductToCart, ITransferProductsFromPlannerToCartParams } from '~/api/dataTypes/cart'
import { setAdeoLogout, setLogout } from '~/actions/auth'
import { useAdeoSignIn } from '~/hooks/signIn'
import { ADEO_LOGOUT_URL } from '~/utils/constants'

const initialProps: IPlannerContextProps = {
	iframeRef: { current: null },
	iframeContentWindow: null,
	isShopVersion: false,
	isScreenSaverVisible: false,
	isFullscreen: false,
	initialProjectNumber: '',
	onToggleFullscreen: noop,
	onSetScreenSaverVisible: noop,
	isOpenUnauthorizedModal: false,
	onOpenUnauthorizedModal: noop,
	onCloseUnauthorizedModal: noop,
	isOpenUserProjectsModal: false,
	onOpenOpenUserProjectsModal: noop,
	onCloseUserProjectsModal: noop,
	isOpenAssignProjectModal: false,
	onOpenAssignProjectModal: noop,
	onCloseAssignProjectModal: noop,
	isOpenAddToCartModal: false,
	onOpenAddToCartModal: noop,
	onCloseAddToCartModal: noop,
	isOpenAddEmptyCartModal: false,
	onOpenAddEmptyCartModal: noop,
	onCloseAddEmptyCartModal: noop,
	isOpenNotAddedProductsModal: false,
	onOpenNotAddedProductsModal: noop,
	onCloseNotAddedProductsModal: noop,
	isOpenUnsavedProjectModal: false,
	onOpenUnsavedProjectModal: noop,
	onCloseUnsavedProjectModal: noop,
	isOpenProjectSavingStatusModal: false,
	onOpenProjectSavingStatusModal: noop,
	onCloseProjectSavingStatusModal: noop,
	notAddedProducts: [],
	onSetNotAddedProducts: noop,
	addToCartParams: null,
	onSetAddToCartParams: noop,
	isReload: false,
	onCompleteReload: noop,
	onLogoutReload: noop,
}

export const PlannerContext = createContext(initialProps)

export const PlannerProvider = (props: IPlannerProviderProps): ReactElement => {
	const { children, data: { number: initialProjectNumber, shopVersion: isShopVersion } } = props
	const iframeRef = useRef<HTMLIFrameElement | null>(null)
	const [iframeContentWindow, setIframeContentWindow] = useState<Window | null>(null)
	const { isOpen: isOpenUnauthorizedModal, handleOpen: handleOpenUnauthorizedModal, handleClose: handleCloseUnauthorizedModal } = useModal('PlannerUnauthorizedModal')
	const { isOpen: isOpenUserProjectsModal, handleOpen: handleOpenUserProjectsModal, handleClose: handleCloseUserProjectsModal } = useModal('PlannerUserProjectsModal')
	const { isOpen: isOpenAssignProjectModal, handleOpen: handleOpenAssignProjectModal, handleClose: handleCloseAssignProjectModal } = useModal('PlannerAssignProjectModel')
	const { isOpen: isOpenAddToCartModal, handleOpen: handleOpenAddToCartModal, handleClose: handleCloseAddToCartModal } = useModal('PlannerAddToCartModal')
	const { isOpen: isOpenAddEmptyCartModal, handleOpen: handleOpenAddEmptyCartModal, handleClose: handleCloseAddEmptyCartModal } = useModal('PlannerAddEmptyCartModal')
	const { isOpen: isOpenNotAddedProductsModal, handleOpen: handleOpenNotAddedProductsModal, handleClose: handleCloseNotAddedProductsModal } = useModal('PlannerNotAddedProductsModal')
	const { isOpen: isOpenUnsavedProjectModal, handleOpen: handleOpenUnsavedProjectModal, handleClose: handleCloseUnsavedProjectModal } = useModal('PlannerUnsavedProjectModal')
	const { isOpen: isOpenProjectSavingStatusModal, handleOpen: handleOpenProjectSavingStatusModal, handleClose: handleCloseProjectSavingStatusModal } = useModal('ProjectSavingStatusModal')

	const [addToCartParams, setAddToCartParams] = useState<ITransferProductsFromPlannerToCartParams | null>(null)
	const [notAddedProducts, setNotAddedProducts] = useState<INotAddedProductToCart[]>([])
	const [isReload, setIsReload] = useState<boolean>(false)
	const [isScreenSaverVisible, setIsScreenSaverVisible] = useState<boolean>(isShopVersion)
	const [isFullscreen, toggleFullScreen] = useToggle(isShopVersion)
	const router = useRouter()
	const dispatch = useDispatch()
	const { shouldLoginByAdeo } = useAdeoSignIn()

	const handleCompleteReload = useCallback((): void => {
		setIsReload(false)
	}, [])

	const handleStartReload = useCallback((): void => {
		setIsReload(true)
	}, [])

	const handleReloadOnLogout = useCallback(async (): Promise<void> => {
		if (shouldLoginByAdeo) {
			await dispatch(setAdeoLogout())

			window.location.href=`${ ADEO_LOGOUT_URL }?redirect=${ router.asPath.split('?')[0] }`
		} else {
			const currentUrl = router.asPath.split('?')[0]

			window.location.href = currentUrl

			await dispatch(setLogout())
		}

		handleStartReload()
	}, [shouldLoginByAdeo, router])

	const handleSetScreenSaverVisible = useCallback((isVisible: boolean) => {
		setIsScreenSaverVisible(isVisible)
	}, [])

	const handleToggleFullScreen = useCallback(() => {
		if (!isShopVersion) {
			toggleFullScreen()
		}
	}, [toggleFullScreen, isShopVersion])

	const handleSetAddToCartParams = useCallback((params: ITransferProductsFromPlannerToCartParams) => {
		setAddToCartParams(params)
	}, [])

	const handleSetNotAddedProducts = useCallback((products: INotAddedProductToCart[]) => {
		setNotAddedProducts(products)
	}, [])

	useEffect(() => {
		if (!isNull(iframeRef.current)) {
			const iframeWindow = iframeRef.current.contentWindow

			!isNil(iframeWindow) && setIframeContentWindow(iframeWindow)
		}
	}, [])

	const providerValue: IPlannerContextProps = useMemo(() => ({
		iframeRef,
		iframeContentWindow,
		isShopVersion,
		isScreenSaverVisible,
		isFullscreen,
		initialProjectNumber: isNull(initialProjectNumber) ? '' : toString(initialProjectNumber),
		onToggleFullscreen: handleToggleFullScreen,
		onSetScreenSaverVisible: handleSetScreenSaverVisible,
		isOpenUnauthorizedModal,
		onOpenUnauthorizedModal: handleOpenUnauthorizedModal,
		onCloseUnauthorizedModal: handleCloseUnauthorizedModal,
		isOpenUserProjectsModal,
		onOpenOpenUserProjectsModal: handleOpenUserProjectsModal,
		onCloseUserProjectsModal: handleCloseUserProjectsModal,
		isOpenAssignProjectModal,
		onOpenAssignProjectModal: handleOpenAssignProjectModal,
		onCloseAssignProjectModal: handleCloseAssignProjectModal,
		isOpenAddToCartModal,
		onOpenAddToCartModal: handleOpenAddToCartModal,
		onCloseAddToCartModal: handleCloseAddToCartModal,
		isOpenAddEmptyCartModal,
		onOpenAddEmptyCartModal: handleOpenAddEmptyCartModal,
		onCloseAddEmptyCartModal: handleCloseAddEmptyCartModal,
		isOpenNotAddedProductsModal,
		onOpenNotAddedProductsModal: handleOpenNotAddedProductsModal,
		onCloseNotAddedProductsModal: handleCloseNotAddedProductsModal,
		notAddedProducts,
		onOpenUnsavedProjectModal: handleOpenUnsavedProjectModal,
		onCloseUnsavedProjectModal: handleCloseUnsavedProjectModal,
		isOpenUnsavedProjectModal,
		onOpenProjectSavingStatusModal: handleOpenProjectSavingStatusModal,
		onCloseProjectSavingStatusModal: handleCloseProjectSavingStatusModal,
		isOpenProjectSavingStatusModal,
		onSetNotAddedProducts: handleSetNotAddedProducts,
		addToCartParams,
		onSetAddToCartParams: handleSetAddToCartParams,
		isReload,
		onCompleteReload: handleCompleteReload,
		onLogoutReload: handleReloadOnLogout,
	}), [iframeRef, iframeContentWindow, isShopVersion, isScreenSaverVisible, isFullscreen, handleToggleFullScreen, handleSetScreenSaverVisible, initialProjectNumber, isOpenUnauthorizedModal, isOpenUserProjectsModal, isOpenAssignProjectModal, isOpenAddToCartModal, isOpenAddEmptyCartModal, isOpenNotAddedProductsModal, isOpenUnsavedProjectModal, isOpenProjectSavingStatusModal, addToCartParams, notAddedProducts, isReload])

	return (
		<PlannerContext.Provider value={ providerValue }>
			{ children }
		</PlannerContext.Provider>
	)
}
