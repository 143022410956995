import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { SectionHeader } from '~/components/core/sectionHeader'
import { IProductListCabHeaderProps } from '~/components/productList/productListCab'
import { vars } from '~/statics'

import styles from './ProductListCabHeader.module.scss'

const ProductListCabHeader = (props: IProductListCabHeaderProps): ReactElement => {
	const { title } = props
	const { t } = useTranslation(['productList'])

	return (
		<div className={ styles.wrapper }>
			<SectionHeader title={ title } />

			<div className={ styles.description }>
				<div className={ styles.logotype }>
					<Image
						className={ styles.image }
						src={ vars.images.cab }
						width={ 80 }
						height={ 80 }
						alt=""
						role="presentation"
					/>
				</div>

				<div className={ styles.text }>
					{ t('cab.description') }
				</div>
			</div>
		</div>
	)
}

export { ProductListCabHeader }
