import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { PlannerContext } from '~/providers/plannerProvider'
import { usePlannerProject } from '~/hooks/plannerIframe'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './PlannerUnsavedProjectsModal.module.scss'

const PlannerUnsavedProjectsModal = (): ReactElement => {
	const { t } = useTranslation(['planner'])
	const { onOpenAssignProjectModal, onCloseUnsavedProjectModal, isOpenUnsavedProjectModal } = useContext(PlannerContext)
	const { handleGetProjectFromStorage, handleRemoveProjectFromStorage, handleSaveProjectToStorage } = usePlannerProject()
	const { urls } = useContext(AppParametersContext)

	const projectInfo = handleGetProjectFromStorage()

	const handleRemove = useCallback((): void => {
		onCloseUnsavedProjectModal()
		handleRemoveProjectFromStorage()
	}, [onCloseUnsavedProjectModal])

	const handleEdit = useCallback((): void => {
		onCloseUnsavedProjectModal()

		window.location.replace(`${ urls.appPlanner3d }?editMode=true`)
	}, [onCloseUnsavedProjectModal, projectInfo, urls.appPlanner3d])

	const handleSave = useCallback((): void => {
		onCloseUnsavedProjectModal()
		handleSaveProjectToStorage(projectInfo)
		onOpenAssignProjectModal()
	}, [onCloseUnsavedProjectModal, handleSaveProjectToStorage, projectInfo])

	return (
		<Modal
			isOpen={ isOpenUnsavedProjectModal }
			title={ t('unsavedModal.caption', { name: projectInfo?.params.name, ns: 'planner' }) }
			additionalClass={ styles.modal }
			onClose={ onCloseUnsavedProjectModal }
		>
			<div className={ styles.wrapper }>
				<div className={ styles.description }>
					{ t('unsavedModal.description') }
				</div>

				<div className={ styles.buttons }>
					<Button
						variant="secondary"
						text={ t('unsavedModal.save') }
						onClick={ handleSave }
					/>

					<Button
						isReverse
						variant="secondary"
						text={ t('unsavedModal.edit') }
						onClick={ handleEdit }
					/>
				</div>

				<div className={ styles.info }>
					<span>
						{ t('unsavedModal.remove.info') }
					</span>

					<Button
						variant="neutral"
						text={ t('unsavedModal.remove.button') }
						additionalClass={ styles.button }
						onClick={ handleRemove }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { PlannerUnsavedProjectsModal }
