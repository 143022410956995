import { useCallback, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { isNil } from 'lodash'

import { PlannerContext } from '~/providers/plannerProvider'
import { IPlannerAddToCartFormData, IUsePlannerAddToCartData } from '~/hooks/plannerIframe'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { transferProductsFromPlannerToCart } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { postTransferProductsFromPlannerToCart } from '~/api/requests/cart'
import { useKobiHeader } from '~/hooks/kobiHeader'

export const usePlannerAddToCart = (): IUsePlannerAddToCartData => {
	const { isOpenAddToCartModal, onOpenAddToCartModal, onCloseAddToCartModal, isOpenAddEmptyCartModal, onCloseAddEmptyCartModal, onOpenAddEmptyCartModal, isOpenNotAddedProductsModal, onOpenNotAddedProductsModal, onCloseNotAddedProductsModal, onCloseAssignProjectModal, onSetNotAddedProducts, notAddedProducts } = useContext(PlannerContext)
	const { addToCartParams } = useContext(PlannerContext)
	const { preventAlert } = useAlert()
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()
	const { handleDispatchKobiCartHeader } = useKobiHeader()

	const schema: yup.SchemaOf<IPlannerAddToCartFormData> = useMemo(() => yup.object().shape({
		addToCartFromPlannerAgreement: yup.boolean().required(),
	}), [])

	const { control, watch } = useForm<IPlannerAddToCartFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			addToCartFromPlannerAgreement: false,
		},
	})

	const addToCartFromPlannerAgreement = watch('addToCartFromPlannerAgreement')

	const handleAddToCart = useCallback(async (): Promise<void> => {
		if (isNil(addToCartParams)) {
			return
		}

		onCloseAddToCartModal()

		try {
			// TODO: add DY and GA events
			const response = await postTransferProductsFromPlannerToCart(addToCartParams)
			const { data, data: { offers: { notAddedOffers }, addedAllOffers } } = response

			if (!addedAllOffers) {
				onSetNotAddedProducts(notAddedOffers)
				onOpenNotAddedProductsModal()
			}

			await dispatch(transferProductsFromPlannerToCart(data))

			handleDispatchKobiCartHeader()
		} catch (e: unknown) {
			// TODO: handle errors
			preventAlert(e)
			sendLogError(e)
		}
	}, [onCloseAddToCartModal, onOpenNotAddedProductsModal, onSetNotAddedProducts, addToCartParams])

	return {
		handleAddToCart,
		control,
		addToCartFromPlannerAgreement,
		onOpenAddToCartModal,
		onCloseAddToCartModal,
		isOpenAddToCartModal,
		isOpenAddEmptyCartModal,
		isOpenNotAddedProductsModal,
		onCloseAddEmptyCartModal,
		onOpenAddEmptyCartModal,
		onCloseNotAddedProductsModal,
		onCloseAssignProjectModal,
		notAddedProducts,
	}
}
