import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Icon } from '~/components/core/icon'

import styles from './AccountProjectsListInfo.module.scss'

const AccountProjectsListInfo = (): ReactElement | null => {
	const { t } = useTranslation(['account'])

	return (
		<div className={ styles.wrapper }>
			<Icon
				name="infoCircle"
				width={ 25 }
				color="black"
			/>

			<div className={ styles.info }>
				{ t('panel.latestProjectsSection.projectsInformation') }
			</div>
		</div>
	)
}

export { AccountProjectsListInfo }
