import React, { useCallback, ReactElement } from 'react'
import { useRouter } from 'next/router'

import { ProductListCabStepText, IProductListCabStepProps, ProductListCabStepImage } from '~/components/productList/productListCab'
import { ICabStepVarianceNavigation } from '~/api/dataTypes/catalog'

import styles from './ProductListCabStep.module.scss'

const ProductListCabStep = (props: IProductListCabStepProps): ReactElement => {
	const { step } = props
	const router = useRouter()

	const { name, cabStepVarianceNavigationList, cabPresentationType } = step

	const handleVarianceClick = useCallback(async (variance: ICabStepVarianceNavigation): Promise<void> => {
		const { wwwUrl } = variance

		await router.push(wwwUrl)
	}, [])

	const renderOptions = useCallback((): ReactElement => {
		switch (cabPresentationType) {
			case 'IMAGE':
			case 'TEXT_IMAGE':
				return <ProductListCabStepImage variances={ cabStepVarianceNavigationList } onVarianceClick={ handleVarianceClick } />
			case 'TEXT':
			default:
				return <ProductListCabStepText variances={ cabStepVarianceNavigationList } onVarianceClick={ handleVarianceClick } />
		}
	}, [step])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.name }>
				{ name }
			</div>

			{ renderOptions() }
		</div>
	)
}

export { ProductListCabStep }
