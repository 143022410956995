import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { ProductListCabHeader, ProductListCabSteps, IProductListCabProps } from '~/components/productList/productListCab'

import styles from './ProductListCab.module.scss'

const ProductListCab = (props: IProductListCabProps): ReactElement | null => {
	const { cabNavigation } = props

	if (isNull(cabNavigation)) {
		return null
	}

	const { header, cabStepNavigationList } = cabNavigation

	return (
		<div className={ styles.wrapper }>
			<ProductListCabHeader title={ header } />

			<ProductListCabSteps steps={ cabStepNavigationList } />
		</div>
	)
}

export { ProductListCab }
