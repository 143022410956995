import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { noop } from 'lodash'

import { IPaginationSmallProps, PageInput, PaginationSmallNextPageButton, PaginationSmallPrevPageButton } from '~/components/core/paginationSmall'

import styles from './PaginationSmall.module.scss'

export const PaginationSmall = (props: IPaginationSmallProps): ReactElement => {
	const {
		totalPages,
		initialPage = 1,
		firstPage = false,
		lastPage = false,
		paginationUrls = [],
		additionalClass = '',
		onPageChange = noop,
	} = props
	const { t } = useTranslation(['common'])
	const [currentPage, setCurrentPage] = useState<number>(initialPage)

	const isFirstPage = firstPage ?? currentPage === 1
	const isLastPage = lastPage ?? currentPage === totalPages

	const handlePageChange = useCallback((page: number): void => {
		setCurrentPage(page)
		onPageChange(page)
	}, [setCurrentPage, onPageChange])

	const handlePrevPageClick = useCallback((): void => {
		if (currentPage > 1) {
			handlePageChange(currentPage - 1)
		}
	}, [currentPage, handlePageChange])

	const handleNextPageClick = useCallback((): void => {
		if (currentPage < totalPages) {
			handlePageChange(currentPage + 1)
		}
	}, [currentPage, totalPages, handlePageChange])

	const handlePageInputChange = useCallback((page: number): void => {
		handlePageChange(page)
	}, [handlePageChange])

	useEffect((): void => {
		if (initialPage !== currentPage) {
			handlePageChange(initialPage)
		}
	}, [initialPage])

	const paginationClasses = classNames(styles.paginationSmall, additionalClass)

	return (
		<div className={ paginationClasses }>
			{ !isFirstPage && (
				<PaginationSmallPrevPageButton url={ paginationUrls[0] } onClick={ handlePrevPageClick } />
			) }

			<PageInput
				initialValue={ currentPage }
				totalPages={ totalPages }
				onPageChange={ handlePageInputChange }
			/>

			<span className={ styles.totalPagesText }>
				{ t('pagination.of') }
			</span>

			<span>
				{ totalPages }
			</span>

			{ !isLastPage && (
				<PaginationSmallNextPageButton url={ paginationUrls[1] } onClick={ handleNextPageClick } />
			) }
		</div>
	)
}
