import { filter, map, join } from 'lodash'

import { IProductSimpleWithDetails, IProductSimple, IProductDetailData } from '~/api/dataTypes/product'
import { IComparisonTableColumn } from '~/providers/comparisonProvider'

// FIXME to fix after backend add url to appParams
const comparisonSiteUrl = (ids: string): string => `porownanie-produktow,cp:${ ids }.html`

export const getComparisonProducts = (data: IProductSimpleWithDetails[]): IProductSimple[] => (
	map(data, (item: IProductSimpleWithDetails) => item.productSimple)
)

export const getComparisonTableColumns = (data: IProductSimpleWithDetails[]): IComparisonTableColumn[] => (
	map(data, (item: IProductSimpleWithDetails) => {
		const { productDetailList, productSimple } = item
		const { id } = productSimple

		return ({
			id,
			cells: productDetailList, // FIXME: To fix after add differ flag
		})
	})
)

export const getComparisonLabelColumn = (data: IProductSimpleWithDetails[]): string[] => (
	map(data[0].productDetailList, (item: IProductDetailData) => item.name)
)

export const removeComparisonProduct = (data: IProductSimpleWithDetails[], id: number): IProductSimpleWithDetails[] => (
	filter(data, (product: IProductSimpleWithDetails) => product.productSimple.id !== id)
)

export const getComparisonProductsIds = (data: IProductSimpleWithDetails[]): number[] => (
	map(data, (product: IProductSimpleWithDetails) => product.productSimple.id)
)

export const getComparisonPageUrl = (data: IProductSimpleWithDetails[]): string => {
	const ids = getComparisonProductsIds(data)
	const stringIds = join(ids, ':')

	return comparisonSiteUrl(stringIds)
}
