import React, { ReactElement, useContext, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { ProductListFiltersModalContext } from '~/providers/productListFiltersModalProvider'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './ProductListFiltersButton.module.scss'

const ProductListFiltersButton = (): ReactElement => {
	const { t } = useTranslation(['productList'])
	const { handleSetOpen } = useContext(ProductListFiltersModalContext)
	const { isDesktop } = useMediaQuery()

	const buttonTabIndex = isDesktop ? -1 : 0

	const handleShowProductListFiltersModal = useCallback((): void => {
		handleSetOpen(true)
	}, [handleSetOpen])

	return (
		<div className={ styles.wrapper }>
			<Button
				isReverse
				variant="secondary"
				additionalClass={ styles.button }
				text={ t('filters.button') }
				tabIndex={ buttonTabIndex }
				onClick={ handleShowProductListFiltersModal }
			/>
		</div>
	)
}

export { ProductListFiltersButton }
