import React, { MouseEvent, ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isEmpty, noop, toString } from 'lodash'

import { IPaginationItemProps } from '~/components/core/pagination'
import { Button } from '~/components/core/button'
import { Link } from '~/components/core/link'
import { useMozaic } from '~/hooks/mozaic'

import styles from './PaginationItem.module.scss'

export const PaginationItem = (props: IPaginationItemProps): ReactElement => {
	const {
		value, isActive,
		onClick = noop,
		url = '',
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const handlePageClick = useCallback((): void => {
		onClick(value)
	}, [value, onClick])

	const handleClickUrl = useCallback((event: MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault()
		handlePageClick()
	}, [handlePageClick])

	const pageItemClasses = classNames(styles.wrapper, {
		[styles.active]: isActive,
		[styles.isMozaic]: shouldUseMozaic,
	})

	if (!isEmpty(url)) {
		return (
			<Link
				href={ url }
				additionalClass={ pageItemClasses }
				ariaLabel={ toString(value) }
				onClick={ handleClickUrl }
			>
				{ value }
			</Link>
		)
	}

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ pageItemClasses }
			ariaLabel={ `${ value }` }
			onClick={ handlePageClick }
		>
			{ value }
		</Button>
	)
}
