import React, { createContext, useState, ReactElement, useMemo, useCallback } from 'react'
import { noop } from 'lodash'

import { IProductListFilterPriceContextProps, IProductListFilterPriceProviderProps, IProductListFilterPriceValues } from '~/providers/productListFilterPriceProvider'

const initialProps: IProductListFilterPriceContextProps = {
	priceFrom: undefined,
	priceTo: undefined,
	handleSetPriceValues: noop,
}

export const ProductListFiltersPriceContext = createContext(initialProps)

export const ProductListFilterPriceProvider = (props: IProductListFilterPriceProviderProps): ReactElement => {
	const { children, value } = props
	const [priceValues, setPriceValues] = useState<IProductListFilterPriceValues>(value)

	const handleSetPriceValues = useCallback(async (priceValues: IProductListFilterPriceValues): Promise<void> => {
		await setPriceValues(priceValues)
	}, [])

	const providerValue: IProductListFilterPriceContextProps = useMemo(() => ({
		...priceValues,
		handleSetPriceValues,
	}), [value, priceValues, handleSetPriceValues])

	return (
		<ProductListFiltersPriceContext.Provider value={ providerValue }>
			{ children }
		</ProductListFiltersPriceContext.Provider>
	)
}
