import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { PlannerContext } from '~/providers/plannerProvider'

import styles from './PlannerProjectSavingStatusModal.module.scss'

const PlannerProjectSavingStatusModal = (): ReactElement => {
	const { isOpenProjectSavingStatusModal, onCloseProjectSavingStatusModal } = useContext(PlannerContext)
	const { t } = useTranslation(['planner'])

	return (
		<Modal
			isOpen={ isOpenProjectSavingStatusModal }
			title={ t('projectSavingStatusModal.success') }
			additionalClass={ styles.modal }
			onClose={ onCloseProjectSavingStatusModal }
		>
			<div className={ styles.wrapper }>
				<Button
					text={ t('ok', { ns: 'common' }) }
					variant="secondary"
					additionalClass={ styles.button }
					onClick={ onCloseProjectSavingStatusModal }
				/>
			</div>
		</Modal>
	)
}

export { PlannerProjectSavingStatusModal }
