import React, { ReactElement } from 'react'

import { IListingContentEmptyProps } from '~/components/core/listing'
import { Button } from '~/components/core/button'

import styles from './ListingContentEmpty.module.scss'

const ListingContentEmpty = (props: IListingContentEmptyProps): ReactElement => {
	const { tip, title, buttonLabel, onClick } = props

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.title }>
				{ title }
			</div>

			<div className={ styles.tip }>
				{ tip }
			</div>

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.showAll }
				role="link"
				ariaLabel={ buttonLabel }
				onClick={ onClick }
			>
				{ buttonLabel }
			</Button>
		</div>
	)
}

export { ListingContentEmpty }
