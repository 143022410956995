import { AxiosResponse } from 'axios'

import { ICatalogFlatTreeResponse, ICatalogWorldResponse } from '~/api/dataTypes/catalog'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const getCatalogFlatTreeData = async (depth: number): Promise<AxiosResponse<ICatalogFlatTreeResponse>> => (
	await request.get('/v1/catalog/flat-tree', {
		isInternal: true,
		params: {
			depth,
		},
	})
)

export const getCatalogWorldData = async (worldId: number, noCache?: string | string[]): Promise<AxiosResponse<ICatalogWorldResponse>> => (
	await request.get(`/v1/catalog/world/${ worldId }`, {
		isInternal: true,
		params: getNoCacheParam(noCache),
	})
)
