import { isEqual, isFunction } from 'lodash'

export const getPagesSliceStart = (currentPage: number, totalPages: number, windowSize: number): number => {
	const middlePoint = Math.ceil(windowSize / 2)

	if (currentPage > totalPages - middlePoint) {
		return totalPages - windowSize
	}

	if (currentPage > middlePoint) {
		return currentPage - middlePoint
	}

	return 0
}

export const getPagesSliceEnd = (sliceStart: number, totalPages: number, windowSize: number): number => {
	const sliceEnd = windowSize >= totalPages ? totalPages : sliceStart + windowSize

	return sliceEnd > 0 ? sliceEnd : 1
}

export const getTemplateUrl = (page: number, baseUrl: string, generateUrlFun?: (page: number) => string): string => {
	if (isEqual(page, 1)) {
		return baseUrl
	}

	return isFunction(generateUrlFun) ? generateUrlFun(page) : ''
}
