import React, { ChangeEvent, ReactElement, useCallback, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { Collapse } from 'react-collapse'
import classNames from 'classnames'
import { isEqual, isNil, isNumber } from 'lodash'

import { ISearchResultsFiltersPriceProps } from '~/components/search'
import { ListingFiltersCategory } from '~/components/core/listing'
import { FilterPriceValueType, RangeFromToSlider } from '~/components/core/rangeFromToSlider'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ProductListFiltersPriceContext } from '~/providers/productListFilterPriceProvider'
import { convertInputValueToPreventNaNs } from '~/utils/string'

import styles from './SearchResultsFiltersPrice.module.scss'

const SearchResultsFiltersPrice = (props: ISearchResultsFiltersPriceProps): ReactElement => {
	const { price, params } = props
	const { priceFrom, priceTo } = params
	const { min, max } = price
	const { handleSetPriceValues, priceFrom: tmpPriceFrom, priceTo: tmpPriceTo } = useContext(ProductListFiltersPriceContext)
	const { t } = useTranslation(['search'])
	const [priceValue, setPriceValue] = useState<FilterPriceValueType>([priceFrom || min, priceTo || max])
	const [inputPriceFrom, setInputPriceFrom] = useState<number>(priceFrom || min)
	const [inputPriceTo, setInputPriceTo] = useState<number>(priceTo || max)
	const [isOpen, setIsOpen] = useToggle(isNumber(priceFrom) || isNumber(priceTo))
	const { isDesktop } = useMediaQuery()

	const handleResetPriceFilter = useCallback((): void => {
		if (isEqual(tmpPriceFrom, min) && isEqual(tmpPriceTo, max)) {
			setPriceValue([priceFrom || min, priceTo || max])
			setInputPriceFrom(isNil(priceFrom) ? min : priceFrom)
			setInputPriceTo(isNil(priceTo) ? max : priceTo)
		}
	}, [tmpPriceFrom, tmpPriceTo, min, max])

	useEffect(() => {
		setPriceValue([priceFrom || min, priceTo || max])
		setInputPriceFrom(isNil(priceFrom) ? min : priceFrom)
		setInputPriceTo(isNil(priceTo) ? max : priceTo)
	}, [priceFrom, priceTo])

	useEffect(() => {
		handleResetPriceFilter()
	}, [tmpPriceFrom, tmpPriceTo, min, max, priceFrom, priceTo])

	const handleChangeFilter = useCallback((value: FilterPriceValueType): void => {
		handleSetPriceValues({
			priceFrom: value[0],
			priceTo: value[1],
		})
	}, [])

	const onChangePriceFrom = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
		const value = convertInputValueToPreventNaNs(event.target.value, min)

		setInputPriceFrom(value)
	}, [min])

	const onChangePriceTo = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
		const value = convertInputValueToPreventNaNs(event.target.value, max)

		setInputPriceTo(value)
	}, [max])

	const handleChangeSliderValue = useCallback((value: FilterPriceValueType): void => {
		setPriceValue(value)
		setInputPriceFrom(value[0])
		setInputPriceTo(value[1])
	}, [])

	const onBlurPriceFrom = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
		const inputValue = Number(event.target.value)
		const priceFrom = inputValue < min ? min : inputValue > priceValue[1] ? priceValue[1] : inputValue

		setInputPriceFrom(priceFrom)
		setPriceValue([priceFrom, priceValue[1]])
		handleChangeFilter([priceFrom, priceValue[1]])
	}, [min, priceValue])

	const onBlurPriceTo = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
		const inputValue = Number(event.target.value)
		const priceTo = inputValue > max ? max : inputValue < priceValue[0] ? priceValue[0] : inputValue

		setInputPriceTo(priceTo)
		setPriceValue([priceValue[0], priceTo])
		handleChangeFilter([priceValue[0], priceTo])
	}, [max, priceValue])

	const handleAfterChange = useCallback((value: FilterPriceValueType): void => {
		handleChangeFilter(value)
	}, [])

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const renderPriceFilter = useCallback((): ReactElement => {
		const tabIndex = isDesktop || isOpen ? 0 : -1

		return (
			<>
				<div className={ styles.price }>
					<input
						name="priceFrom"
						value={ inputPriceFrom }
						className={ styles.input }
						tabIndex={ tabIndex }
						maxLength={ 5 }
						onBlur={ onBlurPriceFrom }
						onChange={ onChangePriceFrom }
					/>

					<span className={ styles.spacer } />

					<input
						name="priceTo"
						value={ inputPriceTo }
						className={ styles.input }
						tabIndex={ tabIndex }
						maxLength={ 5 }
						onBlur={ onBlurPriceTo }
						onChange={ onChangePriceTo }
					/>
				</div>

				<RangeFromToSlider
					min={ min }
					max={ max }
					value={ priceValue }
					additionalClass={ styles.slider }
					tabIndex={ tabIndex }
					onChange={ handleChangeSliderValue }
					onAfterChange={ handleAfterChange }
				/>
			</>
		)
	}, [priceValue, min, max, inputPriceFrom, inputPriceTo, isOpen, isDesktop, onBlurPriceFrom, onChangePriceFrom, onChangePriceTo, handleChangeSliderValue, handleAfterChange])

	const renderContent = useCallback((): ReactElement => {
		if (isDesktop) {
			return renderPriceFilter()
		}

		return (
			<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
				{ renderPriceFilter() }
			</Collapse>
		)
	}, [isDesktop, isOpen, renderPriceFilter, priceFrom, priceTo, price])

	const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

	useEffect(() => {
		setPriceValue([priceFrom || min, priceTo || max])
		handleSetPriceValues({
			priceFrom: priceFrom || min,
			priceTo: priceTo || max,
		})
	}, [params, price])

	return (
		<div className={ styles.wrapper }>
			<ListingFiltersCategory
				title={ t('results.filters.price') }
				isOpen={ isOpen }
				onToggleOpen={ handleToggleOpen }
			/>

			{ renderContent() }
		</div>
	)
}

export { SearchResultsFiltersPrice }
