import { AxiosResponse } from 'axios'

import { request } from '~/api/requests/axios'
import { getAuthlessCustomerPlannerServiceForPath, getAuthlessServiceForCreatePlannerProject } from '~/utils/planner'
import { IPostCustomerProjectsResponse } from '~/api/dataTypes/customerProject'
import { IPlannerProductSimpleResponse } from '~/api/dataTypes/planner'

// @data   IPlannerProjectData
export const createPlannerProject = async (data: FormData): Promise<AxiosResponse<IPostCustomerProjectsResponse>> => (
	await request.post(getAuthlessCustomerPlannerServiceForPath(), data, {
		service: getAuthlessServiceForCreatePlannerProject(),
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
)

export const getPlannerProductsSimple = async (query: string[]): Promise<AxiosResponse<IPlannerProductSimpleResponse>> => {
	return await request.get(`/offers/v1?lmReference=${ query }`, {
		service: 'OFFER_MANAGER_SERVICE',
		withStoreCode: true,
	})
}
