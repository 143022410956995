import React, { useEffect, useState, useContext, useCallback, ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isNull, isUndefined } from 'lodash'

import { IProductListAsideProps } from '~/components/productList/productListAside'
import { ProductListCategories } from '~/components/productList/productListCategories'
import { ProductListFilters } from '~/components/productList/productListFilters'
import { ListingAsideWrapper } from '~/components/core/listing'
import { SkipContentList } from '~/components/core/skipContent'
import { IRootState } from '~/state/types'
import { ICatalogSearchProductFilterResponse } from '~/api/dataTypes/catalog'
import { ProductListFiltersModalContext } from '~/providers/productListFiltersModalProvider'
import { useSkipElements } from '~/hooks/skipElements'

const ProductListAside = (props: IProductListAsideProps): ReactElement => {
	const { node, params, initialFilters } = props
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const [filters, setFilters] = useState<ICatalogSearchProductFilterResponse | null>(initialFilters || null)
	const { handleSetOpen, isOpen } = useContext(ProductListFiltersModalContext)
	const { filtersListSkip } = useSkipElements()

	const handleSetInitialFilters = useCallback((): void => {
		if (!isUndefined(initialFilters)) {
			setFilters(initialFilters)
		}
	}, [params, initialFilters])

	useEffect(() => {
		(async () => {
			await handleSetInitialFilters()
		})()
	}, [preferredStore?.storeCode, node, params, initialFilters])

	return (
		<ListingAsideWrapper setVisibility={ handleSetOpen } isOpen={ isOpen }>
			<SkipContentList skipElements={ filtersListSkip } />

			{ !isNull(node) && (
				<ProductListCategories node={ node } params={ params } />
			) }

			{ !isNull(filters) && (
				<ProductListFilters filters={ filters } params={ params } />
			) }
		</ListingAsideWrapper>
	)
}

export { ProductListAside }
