import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { Checkbox } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { usePlannerAddToCart } from '~/hooks/plannerIframe'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './PlannerAddToCartModal.module.scss'

const PlannerAddToCartModal = (): ReactElement => {
	const { planner: { marketingAgreementContent } } = useContext(AppParametersContext)
	const { handleAddToCart, control, addToCartFromPlannerAgreement, isOpenAddToCartModal, onCloseAddToCartModal } = usePlannerAddToCart()
	const { t } = useTranslation(['planner'])

	return (
		<Modal
			isOpen={ isOpenAddToCartModal }
			additionalClass={ styles.modal }
			onClose={ onCloseAddToCartModal }
		>
			<div className={ styles.wrapper }>
				<Checkbox
					control={ control }
					name="addToCartFromPlannerAgreement"
				>
					<span className={ styles.label } dangerouslySetInnerHTML={ { __html: marketingAgreementContent } } />
				</Checkbox>

				<div className={ styles.buttons }>
					<Button
						text={ t('addToCartModal.title') }
						additionalClass={ styles.button }
						isDisabled={ !addToCartFromPlannerAgreement }
						onClick={ handleAddToCart }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { PlannerAddToCartModal }
