import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { ISeriesBlockProps } from '~/components/core/seriesBlock'
import { ArrowLink } from '~/components/core/arrowLink'
import { Link } from '~/components/core/link'

import styles from './SeriesBlock.module.scss'

const SeriesBlock = (props: ISeriesBlockProps): ReactElement => {
	const {
		series,
		dataTestId = '',
	} = props
	const { t } = useTranslation(['common'])

	const { numberOfProducts, imageUrl, name, leafName, wwwUrl } = series

	return (
		<div className={ styles.item }>
			<Link
				href={ wwwUrl }
				theme={ { wrapper: styles.link } }
				dataTestId={ dataTestId }
				ariaLabel={ name }
				tabIndex={ -1 }
			>
				<Image
					fill
					className={ styles.image }
					src={ imageUrl || '' }
					alt={ name }
				/>
			</Link>

			<div className={ styles.seriesName }>
				{ leafName }
			</div>

			<h3 className={ styles.name }>
				<Link href={ wwwUrl } ariaLabel={ name }>
					{ name }
				</Link>
			</h3>

			<div className={ styles.counter }>
				{ `${ numberOfProducts } ${ t('products', { count: numberOfProducts }) }` }
			</div>

			<div className={ styles.moreButton }>
				<Link href={ wwwUrl } ariaLabel={ `${ t('showAll') } ${ leafName } ${ name }` }>
					<ArrowLink text={ t('showAll') } />
				</Link>
			</div>
		</div>
	)
}

export { SeriesBlock }
