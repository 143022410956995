import { camelCase } from 'lodash'

import { IFilterLinkListData } from '~/api/dataTypes/catalog'
import { ICategoryCarousel } from '~/components/core/categoryCarousel'

export const mapFilterLinkToCategory = (data: IFilterLinkListData): ICategoryCarousel => {
	const { name, wwwUrl } = data

	return {
		id: camelCase(name),
		label: name,
		wwwUrl,
	}
}
