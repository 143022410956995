import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { PlannerContext } from '~/providers/plannerProvider'
import { HeaderTopBarUserAccountNotLogged } from '~/components/core/layout'
import { Modal } from '~/components/core/modal'
import { IPlannerUserUnauthorizedModalProps } from '~/components/planner'

import styles from './PlannerUserUnauthorizedModal.module.scss'

const PlannerUserUnauthorizedModal = (props: IPlannerUserUnauthorizedModalProps): ReactElement => {
	const { isShopVersion } = props
	const { isOpenUnauthorizedModal, onCloseUnauthorizedModal } = useContext(PlannerContext)
	const { t } = useTranslation(['planner'])

	return (
		<Modal
			isOpen={ isOpenUnauthorizedModal }
			title={ t('unauthorizedModal.title') }
			onClose={ onCloseUnauthorizedModal }
		>
			<div className={ styles.wrapper }>
				<div className={ styles.description }>
					{ t('unauthorizedModal.description') }
				</div>

				<HeaderTopBarUserAccountNotLogged
					isShopVersion={ isShopVersion }
					theme={ { wrapper: styles.notLogged } }
					onClose={ onCloseUnauthorizedModal }
				/>
			</div>
		</Modal>
	)
}

export { PlannerUserUnauthorizedModal }
