import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { ICabStepVarianceNavigation } from '~/api/dataTypes/catalog'
import { ProductListCabStepTextItem, IProductListCabStepTextProps } from '~/components/productList/productListCab'

import styles from './ProductListCabStepText.module.scss'

const ProductListCabStepText = (props: IProductListCabStepTextProps): ReactElement => {
	const { variances, onVarianceClick } = props

	const renderSteps = useCallback((): ReactElement[] => (
		map(variances, (variance: ICabStepVarianceNavigation) => (
			<ProductListCabStepTextItem
				key={ variance.id }
				variance={ variance }
				onVarianceClick={ onVarianceClick }
			/>
		))
	), [variances, onVarianceClick])

	return (
		<div className={ styles.wrapper }>
			{ renderSteps() }
		</div>
	)
}

export { ProductListCabStepText }
