import React, { ReactElement, useMemo } from 'react'
import { isEmpty, map } from 'lodash'

import { IFilterLinkListData } from '~/api/dataTypes/catalog'
import { CategoryCarousel, ICategoryCarousel } from '~/components/core/categoryCarousel'
import { IProductListSeoFiltersCloudProps, mapFilterLinkToCategory } from '~/components/productList/productListSeoFiltersCloud'

import styles from './ProductListSeoFiltersCloud.module.scss'

const ProductListSeoFiltersCloud = (props: IProductListSeoFiltersCloudProps): ReactElement | null => {
	const { items } = props

	const categories = useMemo((): ICategoryCarousel[] => (
		map(items, (item: IFilterLinkListData): ICategoryCarousel => mapFilterLinkToCategory(item))
	), [items])

	if (isEmpty(categories)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<CategoryCarousel pageType="PRODUCT_LIST" categories={ categories } />
		</div>
	)
}

export { ProductListSeoFiltersCloud }
