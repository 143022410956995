import React, { ReactElement } from 'react'

import { ISearchResultsWrapperProps } from '~/components/search'

import styles from './SearchResultsWrapper.module.scss'

const SearchResultsWrapper = (props: ISearchResultsWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { SearchResultsWrapper }
