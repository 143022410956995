import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

import { useMozaic } from '~/hooks/mozaic'
import { IAccountHeaderProps } from '~/components/account/accountHeader'

import styles from './AccountHeader.module.scss'

const AccountHeader = (props: IAccountHeaderProps): ReactElement => {
	const {
		title,
		counter = undefined,
		Badge = undefined,
		additionalClass = '',
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const isCounterVisible = !isUndefined(counter)
	const isBadgeVisible = !isUndefined(Badge)

	const wrapperClass = classNames(styles.wrapper, additionalClass, { [styles.isMozaic]: shouldUseMozaic })
	const titleClass = shouldUseMozaic ? classNames('mt-heading mt-heading--underline mt-heading--s') : classNames(
		styles.title, additionalClass,
		{ [styles.isMozaic]: shouldUseMozaic }
	)

	return (
		<div className={ wrapperClass }>
			<div className={ titleClass }>
				{ title }

				{ isCounterVisible && <span className={ styles.counter }>({ counter })</span> }
			</div>

			{ isBadgeVisible && Badge }
		</div>
	)
}

export { AccountHeader }
