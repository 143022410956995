import React, { ReactElement, useCallback } from 'react'
import { map, noop } from 'lodash'

import { ISearchResultsCategoriesItemProps, SearchResultsCategoriesChild, SearchResultsCategoriesHeader } from '~/components/search'
import { ICatalogSearchCategoryData } from '~/api/dataTypes/catalog'

import styles from './SearchResultsCategoriesItem.module.scss'

const SearchResultsCategoriesItem = (props: ISearchResultsCategoriesItemProps): ReactElement => {
	const { category } = props

	const { children, childSelected, selected } = category

	const isOpen = childSelected || selected

	const renderChildren = useCallback((): ReactElement[] => (
		map(children, (childCategory: ICatalogSearchCategoryData) => {
			const { id } = childCategory

			return (
				<SearchResultsCategoriesChild key={ id } category={ childCategory } />
			)
		})
	), [children])

	return (
		<div className={ styles.wrapper }>
			<SearchResultsCategoriesHeader
				category={ category }
				isOpen={ isOpen }
				toggleOpen={ noop }
			/>

			{ isOpen && (
				<ul>
					{ renderChildren() }
				</ul>
			) }
		</div>
	)
}

export { SearchResultsCategoriesItem }
