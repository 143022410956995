import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { ProductListProductBlock } from '~/components/productList/productListProductBlock'
import { ProductListBannerBlock } from '~/components/productList/productListBannerBlock'
import { IProductListItemsProps } from '~/components/productList/productListItems'
import { ICatalogSearchProductItemData } from '~/api/dataTypes/catalog'

import styles from './ProductListItems.module.scss'

const ProductListItems = (props: IProductListItemsProps): ReactElement => {
	const { items } = props

	const renderItems = useCallback((): (ReactElement | null)[] => (
		map(items, (item: ICatalogSearchProductItemData, index: number) => {
			const { catalogItemType, listingProduct, listingBanner } = item

			switch (catalogItemType) {
				case 'PRODUCT':
					return (
						<ProductListProductBlock
							key={ `p-${ listingProduct?.id }` }
							product={ listingProduct }
							position={ index }
						/>
					)
				case 'BANNER':
					return (
						<ProductListBannerBlock
							key={ listingBanner?.name }
							banner={ listingBanner }
							position={ index }
						/>
					)
				default:
					return null
			}
		})
	), [items])

	return (
		<div className={ styles.wrapper }>
			{ renderItems() }
		</div>
	)
}

export { ProductListItems }
