import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, isEqual, isNull } from 'lodash'

import { Button } from '~/components/core/button'
import { SkipContentAnchor } from '~/components/core/skipContent'
import { IProductListFiltersActionsProps, getFiltersCount, getFilterParams } from '~/components/productList/productListFilters'
import { PRODUCT_LIST_PER_PAGE_DEFAULT_VALUE } from '~/components/productList/constants'
import { SKIP_FILTERS_BUTTON } from '~/hooks/skipElements'
import { useProductList } from '~/hooks/productList'
import { ISearchProductsParams } from '~/api/dataTypes/catalog'
import { ProductListFiltersPriceContext } from '~/providers/productListFilterPriceProvider'
import { ProductListFiltersModalContext } from '~/providers/productListFiltersModalProvider'

import styles from './ProductListFiltersActions.module.scss'

const ProductListFiltersActions = (props: IProductListFiltersActionsProps): ReactElement => {
	const { filterValues, params, onResetTmpFilters, price } = props
	const { handleSetPriceValues, priceFrom, priceTo } = useContext(ProductListFiltersPriceContext)
	const { isOpen: isModalOpen, handleSetOpen: handleSetOpenModal } = useContext(ProductListFiltersModalContext)
	const { t } = useTranslation(['productList'])
	const { getProductListData, isPending } = useProductList()

	const countFilters: number = getFiltersCount(filterValues, price, priceFrom, priceTo)

	const handleResetTmpPriceFilter = useCallback(() => {
		const { priceFrom, priceTo } = params
		const { min, max } = price
		const initialPriceFrom = !isNull(priceFrom) ? priceFrom : min
		const initialPriceTo = !isNull(priceTo) ? priceTo : max

		handleSetPriceValues({
			priceFrom: initialPriceFrom,
			priceTo: initialPriceTo,
		})
	}, [params, price])

	const handleCleanFilters = useCallback(async (): Promise<void> => {
		const { availabilities, promotionLabels, filterAttribute, priceFrom, priceTo } = params
		const isAnyFilterSelected = !isEmpty(availabilities) || !isEmpty(promotionLabels) || !isEmpty(filterAttribute) || !isNull(priceFrom) || !isNull(priceTo)

		onResetTmpFilters()
		handleResetTmpPriceFilter()

		if (isAnyFilterSelected) {
			const newFilterParams: ISearchProductsParams = {
				promotionLabels: [],
				availabilities: [],
				filterAttribute: '',
				priceFrom: undefined,
				priceTo: undefined,
				page: 1,
			}

			await getProductListData(newFilterParams)
		}
	}, [onResetTmpFilters, getProductListData, params])

	const handleSetFilters = useCallback(async (): Promise<void> => {
		const newFilterParams: ISearchProductsParams = {
			...getFilterParams(filterValues),
			priceFrom: !isNull(params.priceFrom) ? priceFrom : undefined,
			priceTo: !isNull(params.priceTo) ? priceTo : undefined,
			page: 1,
			size: PRODUCT_LIST_PER_PAGE_DEFAULT_VALUE,
		}

		if (isModalOpen) {
			handleSetOpenModal(false)
		}

		await getProductListData(newFilterParams)
	}, [filterValues, priceFrom, priceTo, getProductListData, isModalOpen, params])

	const handleKeyDownEnter = useCallback((event: KeyboardEvent): void => {
		const { key } = event

		if (key === 'Enter') {
			event.stopPropagation()

			handleSetFilters()
		}
	}, [handleSetFilters])

	const resetClass = classNames(styles.reset, {
		[styles.invisible]: isEqual(countFilters, 0),
	})

	return (
		<div className={ styles.wrapper }>
			<SkipContentAnchor elementId={ SKIP_FILTERS_BUTTON } />

			<Button
				text={ t('filters.action') }
				variant="secondary"
				isDisabled={ isPending }
				onClick={ handleSetFilters }
				onKeyDown={ handleKeyDownEnter }
			/>

			<Button
				text={ t('filters.clean', { value: countFilters }) }
				variant="neutral"
				size="inherit"
				additionalClass={ resetClass }
				onClick={ handleCleanFilters }
			/>
		</div>
	)
}

export { ProductListFiltersActions }
