import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Modal } from '~/components/core/modal'
import { usePlannerAddToCart } from '~/hooks/plannerIframe'

import styles from './PlannerAddEmptyCartModal.module.scss'

const PlannerAddEmptyCartModal = (): ReactElement => {
	const { isOpenAddEmptyCartModal, onCloseAddEmptyCartModal } = usePlannerAddToCart()
	const { t } = useTranslation(['planner'])

	return (
		<Modal
			isOpen={ isOpenAddEmptyCartModal }
			additionalClass={ styles.modal }
			onClose={ onCloseAddEmptyCartModal }
		>
			<div className={ styles.wrapper }>
				{ t('addEmptyCartModal.message') }
			</div>
		</Modal>
	)
}

export { PlannerAddEmptyCartModal }
