import { NextRouter } from 'next/router'
import { isNull } from 'lodash'

import { ITransactionOrderBasicData } from '~/providers/transactionProvider/types'
import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'
import { IPaymentTransactionResultResponse } from '~/api/dataTypes/transactionManager'

const isPickupOrder = (orderData: IOrderDetailsResponse): boolean => {
	const { pickupOrderDetailsData } = orderData

	return (
		!isNull(pickupOrderDetailsData)
	)
}

const isShopDeliveryOrder = (orderData: IOrderDetailsResponse): boolean => {
	const { pickupOrderDetailsData } = orderData

	return !isNull(pickupOrderDetailsData?.transportFromStoreDetailsData)
}

export const getTransactionProviderData = (orderData: IOrderDetailsResponse, router: NextRouter): ITransactionOrderBasicData => {
	const { pickupOrderDetailsData, orderNumber, orderStatusUrl } = orderData
	const orderFrontId = router.query.orderFrontId as string

	return {
		orderNumber,
		orderFrontId,
		orderStatusUrl,
		promiseDate: pickupOrderDetailsData?.promisedDate || null,
		isPickupOrder: isPickupOrder(orderData),
		isShopDeliveryOrder: isShopDeliveryOrder(orderData),
	}
}

export const isTransactionSuccess = (transactionData: IPaymentTransactionResultResponse): boolean => {
	const { status } = transactionData

	return status === 'COMPLETED'
}
