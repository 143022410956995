import { AxiosResponse } from 'axios'

import { IAppParametersResponse } from '~/api/dataTypes/app'
import { request } from '~/api/requests/axios'

export const getAppParametersData = async (): Promise<AxiosResponse<IAppParametersResponse>> => (
	await request.get('/v1/app/parameters', {
		isInternal: true,
	})
)
