import React, { ReactElement, forwardRef, ForwardedRef } from 'react'

import { ProductBlock } from '~/components/core/productBlock'
import { IListingBannerBlockProductProps } from '~/components/core/listing'

import styles from './ListingBannerBlockProduct.module.scss'

const ListingBannerBlockProduct = forwardRef<HTMLDivElement, IListingBannerBlockProductProps>((props: IListingBannerBlockProductProps, forwardedRef: ForwardedRef<HTMLDivElement>): ReactElement => {
	const { banner, position } = props

	const { product, imagePath } = banner

	return (
		<div ref={ forwardedRef } className={ styles.wrapper }>
			<ProductBlock
				product={ product }
				overrideImageSrc={ imagePath }
				position={ position }
			/>
		</div>
	)
})

export { ListingBannerBlockProduct }
