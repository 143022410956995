import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Pagination } from '~/components/core/pagination'
import { PaginationSmall } from '~/components/core/paginationSmall'
import { PerPage } from '~/components/core/perPage'
import { useAccountProjectsPagination } from '~/hooks/accountPagination'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './AccountProjectsListPagination.module.scss'

const AccountProjectsListPagination = (): ReactElement => {
	const { t } = useTranslation(['account'])
	const { isTablet } = useMediaQuery()
	const { pageable, perPageOptions, onChangePageNumber, onChangePageSize } = useAccountProjectsPagination()

	const { pageNumber, pageSize, totalPages, firstPage, lastPage } = pageable

	const renderPagination = useCallback((): ReactElement => {
		if (isTablet) {
			return (
				<Pagination
					initialPage={ pageNumber + 1 }
					totalPages={ totalPages }
					firstPage={ firstPage }
					lastPage={ lastPage }
					onPageChange={ onChangePageNumber }
				/>
			)
		}

		return (
			<PaginationSmall
				initialPage={ pageNumber + 1 }
				totalPages={ totalPages }
				firstPage={ firstPage }
				lastPage={ lastPage }
				onPageChange={ onChangePageNumber }
			/>
		)
	}, [pageNumber, totalPages, firstPage, lastPage])

	return (
		<div className={ styles.wrapper }>
			<PerPage
				label={ t('opinions.list.perPage') }
				options={ perPageOptions }
				value={ pageSize }
				onChange={ onChangePageSize }
			/>

			{ renderPagination() }
		</div>
	)
}

export { AccountProjectsListPagination }
