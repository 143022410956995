import React, { ReactElement, useCallback, useContext, KeyboardEvent } from 'react'
import classNames from 'classnames'

import { IListingFiltersCategoryProps } from '~/components/core/listing'
import { Icon } from '~/components/core/icon'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ProductListFiltersContext } from '~/providers/productListFiltersProvider'

import styles from './ListingFiltersCategory.module.scss'

const ListingFiltersCategory = (props: IListingFiltersCategoryProps): ReactElement => {
	const { title, isOpen, onToggleOpen } = props
	const { isDesktop } = useMediaQuery()
	const { isParentOpen } = useContext(ProductListFiltersContext)

	const handleKeyDownEnter = useCallback((event: KeyboardEvent): void => {
		event.stopPropagation()

		if (event.key === 'Enter') {
			onToggleOpen()
		}
	}, [onToggleOpen])

	if (isDesktop) {
		return (
			<div className={ styles.wrapper }>
				{ title }
			</div>
		)
	}

	const buttonTabIndex = isParentOpen ? 0 : -1

	const wrapperClass = classNames(styles.wrapper, {
		[styles.open]: isOpen,
	})

	return (
		<div className={ wrapperClass }>
			<div
				role="button"
				className={ styles.headerWrapper }
				tabIndex={ buttonTabIndex }
				aria-label={ title }
				aria-expanded={ isOpen }
				aria-hidden={ isParentOpen }
				onClick={ onToggleOpen }
				onKeyDown={ handleKeyDownEnter }
			>
				<span className={ styles.header }>
					{ title }
				</span>

				<Icon
					name="arrowFull"
					color="green"
					width={ 12 }
					height={ 10 }
					additionalClass={ styles.icon }
				/>
			</div>
		</div>
	)
}

export { ListingFiltersCategory }
