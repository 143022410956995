import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import axios, { AxiosError } from 'axios'
import { isUndefined } from 'lodash'

import { Image } from '~/components/core/image'
import { IAccountProjectsItemsRemoveModalProps } from '~/components/account/accountProjectsItems'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { usePlannerProject } from '~/hooks/plannerIframe'
import { useFormError } from '~/hooks/formError'
import { useDate } from '~/hooks/date'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './AccountProjectsItemsRemoveModal.module.scss'

const AccountProjectsItemsRemoveModal = (props: IAccountProjectsItemsRemoveModalProps): ReactElement => {
	const { isOpen, onClose, project } = props
	const { t } = useTranslation(['account', 'common'])
	const { formatDateUnix, getDateFullTimeFormat } = useDate()
	const { handleRemoveProject, handleGetAllProjects } = usePlannerProject()
	const { planner } = useContext(AppParametersContext)
	const { newAlert } = useAlert()
	const { getErrorMessage } = useFormError()
	const { sendLogError } = useLogError()

	const { name, createdDate, number, image, imageUrl } = project

	const imageSrc = planner.plsAsPlannerSourceEnabled ? `data:image/jpeg;base64,${ image }` : imageUrl

	const handleDelete = useCallback(async (): Promise<void> => {
		try {
			await handleRemoveProject(number)
			await handleGetAllProjects()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		} finally {
			onClose()
		}
	}, [project])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.modal }
			onClose={ onClose }
		>
			<div className={ styles.title }>
				{ t('projects.list.removeModal.header') }
			</div>

			<div className={ styles.item }>
				<Image
					src={ imageSrc }
					width={ 100 }
					height={ 75 }
					alt={ name }
				/>

				<div className={ styles.itemDescription }>
					<div>
						<span className={ styles.label }>
							{ `${ t('projects.list.name') }: ` }
						</span>

						<span>
							{ name }
						</span>
					</div>

					<div>
						<span className={ styles.label }>
							{ `${ t('projects.list.createDate') }: ` }
						</span>

						<span>
							{ formatDateUnix(createdDate, getDateFullTimeFormat()) }
						</span>
					</div>

					<div>
						<span className={ styles.label }>
							{ `${ t('projects.list.modifyDate') }: ` }
						</span>

						<span>
							{ formatDateUnix(createdDate, getDateFullTimeFormat()) }
						</span>
					</div>
				</div>
			</div>

			<div className={ styles.buttons }>
				<Button
					variant="secondary"
					text={ t('projects.list.removeModal.confirm') }
					additionalClass={ styles.button }
					onClick={ handleDelete }
				/>

				<Button
					isReverse
					variant="secondary"
					text={ t('cancel', { ns: 'common' }) }
					additionalClass={ styles.button }
					onClick={ onClose }
				/>
			</div>
		</Modal>
	)
}

export { AccountProjectsItemsRemoveModal }
