import { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'next-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { isNull, isUndefined, join } from 'lodash'

import { useFormError } from '~/hooks/formError'
import { useAlert } from '~/hooks/alert'
import { IUsePlannerProjectData, PROJECT_PLANNER_LS_KEY, IPlannerStorageProject, splitToNumber, IPlannerProjectInfo, IPlannerCreatedProjectData, mapProjectFormData } from '~/hooks/plannerIframe'
import { useLogError } from '~/hooks/logError'
import { deleteCustomerPlannerProject, getCustomerPlannerProject, getCustomerPlannerProjects, patchCustomerPlannerProject, postCustomerPlannerProject, putCustomerPlannerProject } from '~/actions/account'
import { localStorageGet, localStorageSet, localStorageRemove } from '~/utils/storage'
import { AppDispatch } from '~/state/store'
import { PlannerContext } from '~/providers/plannerProvider'
import { IAccountProjectsListAddingFormData } from '~/components/account/accountProjects'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { ICustomerProjectItem, IPostCustomerProjectsResponse } from '~/api/dataTypes/customerProject'
import { createPlannerProject } from '~/api/requests/planner'
import { validation } from '~/utils/validation'
import { UserContext } from '~/providers/userProvider'
import { INITIAL_PAGINATION_PARAMS } from '~/hooks/pagination'

export const usePlannerProject = (): IUsePlannerProjectData => {
	const { isLogged } = useContext(UserContext)
	const { onLogoutReload, onOpenProjectSavingStatusModal } = useContext(PlannerContext)
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()
	const { getErrorMessage } = useFormError()
	const { newAlert } = useAlert()
	const { t, i18n } = useTranslation(['account', 'form'])

	const schema: yup.SchemaOf<IAccountProjectsListAddingFormData> = useMemo(() => yup.object().shape({
		projectNumber: yup.string().required(t('required', { ns: 'form' })).matches(validation.regex.uuid, t('validation.uuidValidation', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, setError, reset, setValue } = useForm<IAccountProjectsListAddingFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			projectNumber: '',
		},
	})

	const handleAddProjectFormSubmit = useCallback(handleSubmit(async (formData: IAccountProjectsListAddingFormData) => {
		const { projectNumber } = formData

		try {
			await handleAssignProject(projectNumber)
			await handleGetAllProjects()

			reset()
		} catch (e: unknown) {
			setError('projectNumber', { message: t('projects.list.projectNumberValidationMessage') })
			sendLogError(e)
		}
	}), [])

	const handleGetAllProjects = useCallback(async (): Promise<void> => {
		try {
			await dispatch(getCustomerPlannerProjects(INITIAL_PAGINATION_PARAMS))
		} catch (e: AxiosError | unknown) {
			const error = e as AxiosError

			if (error?.response?.status === 401) {
				onLogoutReload()
			}

			sendLogError(e)
		}
	}, [onLogoutReload])

	const handleGetProject = useCallback(async (projectNumber: string): Promise<ICustomerProjectItem | undefined> => {
		try {
			const { value }: { value: AxiosResponse<ICustomerProjectItem> } = await dispatch(getCustomerPlannerProject(projectNumber))

			return value.data
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	const handleUpdateProject = useCallback(async (projectNumber: string, data: FormData): Promise<void> => {
		try {
			await dispatch(putCustomerPlannerProject(projectNumber, data))
			onOpenProjectSavingStatusModal()
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	const handleRemoveProject = useCallback(async (projectNumber: string): Promise<void> => {
		try {
			await dispatch(deleteCustomerPlannerProject(projectNumber))
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	const handleAssignProject = useCallback(async (projectNumber: string): Promise<void> => {
		try {
			await dispatch(patchCustomerPlannerProject(projectNumber))
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		}
	}, [])

	const handleCreateProject = useCallback(async (projectInfo: IPlannerProjectInfo, email: string = ''): Promise<IPlannerCreatedProjectData | undefined> => {
		try {
			const { params: { name } } = projectInfo
			const formData = mapProjectFormData(projectInfo, email)

			if (isLogged) {
				const { value }: { value: AxiosResponse<IPostCustomerProjectsResponse> } = await dispatch(postCustomerPlannerProject(formData))

				onOpenProjectSavingStatusModal()

				return {
					name,
					number: value.data.projectNumber,
				}
			}

			const { data: { projectNumber } } = await createPlannerProject(formData)

			handleRemoveProjectFromStorage()
			onOpenProjectSavingStatusModal()

			return {
				name,
				number: projectNumber,
			}
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [isLogged])

	const handleGetProjectFromStorage = useCallback((): IPlannerProjectInfo | null => {
		const projectData: IPlannerStorageProject | null = localStorageGet(PROJECT_PLANNER_LS_KEY)

		if (isNull(projectData)) {
			return null
		}

		const { image, params, project } = projectData

		return {
			image: new Uint8Array(splitToNumber(image)),
			params,
			project: new Uint8Array(splitToNumber(project)),
		}
	}, [])

	const handleRemoveProjectFromStorage = useCallback((): void => {
		localStorageRemove(PROJECT_PLANNER_LS_KEY)
	}, [])

	const handleSaveProjectToStorage = useCallback((projectInfo: IPlannerProjectInfo | null): void => {
		if (isNull(projectInfo)) {
			return
		}

		const { image, params, project } = projectInfo

		localStorageSet(PROJECT_PLANNER_LS_KEY, {
			image: join(image, ','),
			params,
			project: join(project, ','),
		})
	}, [])

	return {
		handleGetAllProjects,
		handleGetProject,
		handleUpdateProject,
		handleRemoveProject,
		handleAssignProject,
		handleCreateProject,
		handleGetProjectFromStorage,
		handleRemoveProjectFromStorage,
		handleSaveProjectToStorage,
		handleAddProjectFormSubmit,
		addProjectFormControl: control,
		setProjectFormValue: setValue,
	}
}
