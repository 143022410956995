import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { useToggle } from 'react-use'
import { isNull } from 'lodash'

import { ISearchResultsBannerBlockProps } from '~/components/search'
import { ListingBannerBlockCampaign, ListingBannerBlockSubpages, ListingBannerBlockProduct } from '~/components/core/listing'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useOnScreen } from '~/hooks/onScreen'

const SearchResultsBannerBlock = (props: ISearchResultsBannerBlockProps): ReactElement | null => {
	const { banner, position } = props
	const ref = useRef<HTMLDivElement>(null)
	const isVisible = useOnScreen({ ref })
	const [ isAlreadyAddedToAnalytics, setIsAlreadyAddedToAnalytics] = useToggle(false)
	const { GA_promotionView } = useGoogleAnalytics()

	const renderBanner = useCallback((): ReactElement | null => {
		if (isNull(banner)) {
			return null
		}

		switch (banner.listingBannerType) {
			case 'PRODUCT':
				return (
					<ListingBannerBlockProduct
						ref={ ref }
						banner={ banner }
						position={ position }
					/>
				)
			case 'PROMOTING_SUBPAGES':
				return (
					<ListingBannerBlockSubpages
						ref={ ref }
						banner={ banner }
						position={ position }
					/>
				)
			case 'CAMPAIGN':
				return (
					<ListingBannerBlockCampaign
						ref={ ref }
						banner={ banner }
						position={ position }
					/>
				)
			default:
				return null
		}
	}, [banner, position])

	useEffect(() => {
		if (isVisible && !isAlreadyAddedToAnalytics && !isNull(banner)) {
			const { name, listingBannerType } = banner

			GA_promotionView({ name, id: `${ listingBannerType }-${ name }` }, position)

			setIsAlreadyAddedToAnalytics(true)
		}
	}, [isVisible, isAlreadyAddedToAnalytics, banner])

	return renderBanner()
}

export { SearchResultsBannerBlock }
