import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { Button } from '~/components/core/button'

import styles from './AccountProjectsListCreateProject.module.scss'

const AccountProjectsListCreateProject = (): ReactElement => {
	const { t } = useTranslation(['account'])
	const { isSmallDesktop } = useMediaQuery()
	const { urls } = useContext(AppParametersContext)
	const router = useRouter()

	const handleGoToPlannerPage = useCallback(async (): Promise<void> => {
		await router.push(urls.appPlanner3d)
	}, [urls.appPlanner3d])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.title }>{ t('projects.list.createProjectUsingPlanner') }</div>

			<Button
				text={ t('projects.list.createNewProject') }
				additionalClass={ styles.button }
				isDisabled={ !isSmallDesktop }
				variant="secondary"
				onClick={ handleGoToPlannerPage }
			/>

			<div className={ styles.information }>{ t('projects.list.plannerInformation') }</div>
		</div>
	)
}

export { AccountProjectsListCreateProject }
