import React, { ReactElement, useCallback, useRef } from 'react'
import { useToggle } from 'react-use'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, isNull } from 'lodash'

import { useScrollToElementByRef } from '~/hooks/scrollElementIntoView'
import { SectionHeader } from '~/components/core/sectionHeader'
import { CollapseTextButton } from '~/components/core/collapseText'
import { ISeoDescriptionProps } from '~/components/core/seoDescription'

import styles from './SeoDescription.module.scss'

const SeoDescription = (props: ISeoDescriptionProps): ReactElement | null => {
	const { text } = props
	const descriptionRef = useRef<HTMLDivElement | null>(null)
	const [isFullTextVisible, setIsFullTextVisible] = useToggle(false)
	const scrollToElement = useScrollToElementByRef<HTMLDivElement>()
	const { t } = useTranslation(['common'])

	const handleToggleText = useCallback((): void => {
		setIsFullTextVisible()

		if (isFullTextVisible && !isNull(descriptionRef.current)) {
			scrollToElement(descriptionRef.current)
		}
	}, [isFullTextVisible])

	const contentClass = classNames(styles.content, {
		[styles.fullText]: isFullTextVisible,
	})

	const buttonClass = classNames(styles.toggleButton, {
		[styles.fullText]: isFullTextVisible,
	})

	if (isEmpty(text)) {
		return null
	}

	return (
		<div ref={ descriptionRef } className={ styles.wrapper }>
			<SectionHeader title={ t('learnMore') } />

			<div className={ contentClass } dangerouslySetInnerHTML={ { __html: text } } />

			<CollapseTextButton
				additionalClass={ buttonClass }
				isOpened={ isFullTextVisible }
				onShowFullText={ handleToggleText }
			/>
		</div>
	)
}

export { SeoDescription }
