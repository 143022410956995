import React, { useCallback, ReactElement } from 'react'

import { IProductListCabStepTextItemProps } from '~/components/productList/productListCab'
import { ButtonBoxWrapper } from '~/components/core/buttonBoxWrapper'

import styles from './ProductListCabStepTextItem.module.scss'

const ProductListCabStepTextItem = (props: IProductListCabStepTextItemProps): ReactElement => {
	const { variance, onVarianceClick } = props

	const { name, productCount, chosen, disabled } = variance

	const handleVarianceClick = useCallback((): void => {
		onVarianceClick(variance)
	}, [variance, onVarianceClick])

	return (
		<ButtonBoxWrapper
			isSelected={ chosen }
			isDisabled={ disabled }
			additionalClass={ styles.wrapper }
			onClick={ handleVarianceClick }
		>
			<span className={ styles.name }>
				{ name }
			</span>

			<span className={ styles.count }>
				({ productCount })
			</span>
		</ButtonBoxWrapper>
	)
}

export { ProductListCabStepTextItem }
