import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSetState } from 'react-use'
import { isEmpty, isNull, isUndefined } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { PlannerContext } from '~/providers/plannerProvider'
import { Button } from '~/components/core/button'
import { Input } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { Alert } from '~/components/core/notifications'
import { IAssignProjectAlertState, IAssignProjectForm } from '~/components/planner'
import { usePlannerProject } from '~/hooks/plannerIframe'

import styles from './PlannerAssignProjectModal.module.scss'

const PlannerAssignProjectModal = (): ReactElement => {
	const { isOpenAssignProjectModal, onCloseAssignProjectModal } = useContext(PlannerContext)
	const { handleCreateProject, handleGetProjectFromStorage, handleRemoveProjectFromStorage } = usePlannerProject()
	const [state, setState] = useSetState<IAssignProjectAlertState>({ isInvalid: false, info: '' })
	const { planner: { plannerMarketingAgreementContent } } = useContext(AppParametersContext)
	const { t, i18n } = useTranslation(['planner', 'form', 'common'])

	const schema: yup.SchemaOf<IAssignProjectForm> = useMemo(() => yup.object().shape({
		email: yup.string().required(t('required', { ns: 'form' })).email(t('validation.emailFormat', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, formState: { isSubmitting, isValid, isDirty }, reset } = useForm<IAssignProjectForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
		},
		mode: 'onChange',
	})

	const handleCloseAlert = useCallback((): void => {
		setState({
			isInvalid: false,
			info: '',
		})
	}, [])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: IAssignProjectForm): Promise<void> => {
		const { email } = formData
		const projectInfo = handleGetProjectFromStorage()

		if (!isNull(projectInfo)) {
			const data = await handleCreateProject(projectInfo, email)

			if (!isUndefined(data)) {
				const { name, number } = data

				setState({ isInvalid: false, info: t('assignProjectModal.form.success', { name, number, ns: 'planner' }) })

				handleRemoveProjectFromStorage()
			} else {
				setState({ isInvalid: true, info: t('assignProjectModal.form.error', { ns: 'planner' }) })
			}

			reset()
		}
	}), [handleCreateProject])

	const isSubmitDisabled = !isValid || !isDirty
	const { isInvalid, info } = state

	return (
		<Modal
			isOpen={ isOpenAssignProjectModal }
			title={ t('assignProjectModal.title', { ns: 'planner' }) }
			additionalClass={ styles.modal }
			onClose={ onCloseAssignProjectModal }
			onOpen={ handleCloseAlert }
		>
			{ !isEmpty(info) && (
				<Alert
					canClose
					icon={ isInvalid ? 'errorIcon' : 'checkCircle' }
					type={ isInvalid ? 'danger' : 'success' }
					onClose={ handleCloseAlert }
				>
					{ info }
				</Alert>
			) }

			<form className={ styles.wrapper } onSubmit={ handleFormSubmit }>
				<div className={ styles.description }>
					{ t('assignProjectModal.description', { ns: 'planner' }) }
				</div>

				<Input
					control={ control }
					name="email"
					label={ t('assignProjectModal.form.mailLabel', { ns: 'planner' }) }
				/>

				<Button
					type="submit"
					variant="secondary"
					text={ t('send', { ns: 'common' }) }
					additionalClass={ styles.submitButton }
					isLoading={ isSubmitting }
					isDisabled={ isSubmitDisabled }
				/>

				<div className={ styles.agreement }>
					{ plannerMarketingAgreementContent }
				</div>
			</form>
		</Modal>
	)
}

export { PlannerAssignProjectModal }
