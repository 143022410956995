import React, { useCallback, ReactElement } from 'react'
import { isUndefined } from 'lodash'

import { Image } from '~/components/core/image'
import { IProductListCabStepImageItemProps } from '~/components/productList/productListCab'
import { ButtonBoxWrapper } from '~/components/core/buttonBoxWrapper'

import styles from './ProductListCabStepImageItem.module.scss'

const ProductListCabStepImageItem = (props: IProductListCabStepImageItemProps): ReactElement | null => {
	const { variance, onVarianceClick } = props

	const { name, productCount, chosen, disabled, imageUrl } = variance

	const handleVarianceClick = useCallback((): void => {
		onVarianceClick(variance)
	}, [variance, onVarianceClick])

	if (isUndefined(imageUrl)) {
		return null
	}

	return (
		<ButtonBoxWrapper
			isSelected={ chosen }
			isDisabled={ disabled }
			additionalClass={ styles.wrapper }
			onClick={ handleVarianceClick }
		>
			<div className={ styles.imageWrapper }>
				<Image
					src={ imageUrl }
					width={ 72 }
					height={ 72 }
					alt={ name }
				/>
			</div>

			<span className={ styles.name }>
				<span>
					{ name }
				</span>

				<span className={ styles.count }>
					({ productCount })
				</span>
			</span>
		</ButtonBoxWrapper>
	)
}

export { ProductListCabStepImageItem }
