import React, { ReactElement, memo, useContext } from 'react'
import { isEmpty } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './PlannerScreenSaver.module.scss'

const PlannerScreenSaver = memo((): ReactElement | null => {
	const { planner: { plannerScreensaver } } = useContext(AppParametersContext)

	if (isEmpty(plannerScreensaver)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<video
				loop
				muted
				autoPlay
				id="planner-screensaver"
				className={ styles.screenSaver }
			>
				<source src={ plannerScreensaver } type="video/mp4" />
			</video>
		</div>
	)
})

export { PlannerScreenSaver }
