import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { ISearchNoResultsInfoProps } from '~/components/search'
import { Button } from '~/components/core/button'

import styles from './SearchNoResultsInfo.module.scss'

const SearchNoResultsInfo = (props: ISearchNoResultsInfoProps): ReactElement => {
	const { originalQuery } = props
	const { t } = useTranslation(['search', 'common'])
	const router = useRouter()

	const handleGoBack = useCallback((): void => {
		router.back()
	}, [])

	const handleGoToHome = useCallback(async (): Promise<void> => {
		await router.push('/')
	}, [])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.title }>
				<span>{ t('noResults.header') }{ ': ' }</span>

				<span className={ styles.name }>{ originalQuery }</span>
			</div>

			<div className={ styles.infoWrapper }>
				<div className={ styles.title }>
					<span>{ t('noResults.title') }</span>

					<span className={ styles.name }>{ ` ”${ originalQuery }”` }</span>
				</div>

				<div className={ styles.listTitle }>
					{ t('noResults.solutionsHeader') }
				</div>

				<ul className={ styles.list }>
					<li className={ styles.listElement }>
						{ t('noResults.typoDescription') }
					</li>

					<li className={ styles.listElement }>
						{ t('noResults.differentWordsDescription') }
					</li>

					<li className={ styles.listElement }>
						{ t('noResults.goToHomePageDescription') }
					</li>

					<li className={ styles.listElement }>
						{ t('noResults.recommendationDescription') }
					</li>
				</ul>

				<div className={ styles.buttonsWrapper }>
					<Button
						isReverse
						variant="secondary"
						text={ t('goBack', { ns: 'common' }) }
						additionalClass={ styles.button }
						onClick={ handleGoBack }
					/>

					<Button
						additionalClass={ styles.button }
						variant="secondary"
						text={ t('homeTitle', { ns: 'common' }) }
						onClick={ handleGoToHome }
					/>
				</div>
			</div>
		</div>
	)
}

export { SearchNoResultsInfo }
