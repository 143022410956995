import { find, isEmpty, map } from 'lodash'

import { IFilterAttributeData, IFilterAttributeValueData, IFilterAttributePromotionData } from '~/api/dataTypes/catalog'
import { IIsAnyFilterSelectedFilter, IProductListFiltersDefaultValues } from '~/components/productList/productListFilters'

export const buildAttributesDefaultValues = (filterAttributes: IFilterAttributeData[]): IProductListFiltersDefaultValues => {
	const productDefaultsTmpObj = {}

	map(filterAttributes, (item: IFilterAttributeData) => {
		map(item.values, (value: IFilterAttributeValueData) => {
			if (!productDefaultsTmpObj[item.key]) {
				productDefaultsTmpObj[item.key] = {}
			}

			productDefaultsTmpObj[item.key][value.id] = value.selected
		})
	})

	return productDefaultsTmpObj
}

export const buildAvailabilitiesDefaultValues = (filterAttributeValueAvailabilityList: IFilterAttributeValueData[]): IProductListFiltersDefaultValues => {
	const productDefaultsTmpObj = {}

	map(filterAttributeValueAvailabilityList, (item: IFilterAttributeValueData) => {
		productDefaultsTmpObj[item.id] = item.selected
	})

	return productDefaultsTmpObj
}

export const buildPromotionLabelsDefaultValues = (filterAttributePromotionList: IFilterAttributePromotionData[]): IProductListFiltersDefaultValues => {
	const productDefaultsTmpObj = {}

	map(filterAttributePromotionList, (item: IFilterAttributePromotionData) => {
		productDefaultsTmpObj[ item.value ] = item.selected
	})

	return productDefaultsTmpObj
}

export const sortProductListFiltersPosition = (filters: IFilterAttributeValueData[]): IFilterAttributeValueData[] => filters.sort((a: IFilterAttributeValueData, b: IFilterAttributeValueData) => (a.position > b.position) ? 1 : -1)

export const isAnyFilterSelected = (filters: IIsAnyFilterSelectedFilter[]): boolean => !isEmpty(find(filters, 'selected'))
