import { isUndefined, filter } from 'lodash'

import { ICatalogSearchProductFilterResponse, IFilterAttributePromotionData } from '~/api/dataTypes/catalog'

export const getUpdatedFilters = (storeCode: string | undefined, filters: ICatalogSearchProductFilterResponse | undefined): ICatalogSearchProductFilterResponse | undefined => {
	if (!isUndefined(storeCode) && !isUndefined(filters)) {
		const { filterAttributePromotionList } = filters
		const updatedFilterAttributePromotionList = filter(filterAttributePromotionList, ({ value }: IFilterAttributePromotionData) => value !== 'SALE' && value !== 'OUTLET')

		return {
			...filters,
			filterAttributePromotionList: updatedFilterAttributePromotionList,
		}
	}

	return filters
}
