import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { useMozaic } from '~/hooks/mozaic'

import styles from './AccountProjectsItemsHead.module.scss'

const AccountProjectsItemsHead = (): ReactElement => {
	const { t } = useTranslation(['account'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const cellClass = classNames(styles.cell, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.row }>
				<div className={ cellClass }>{ t('projects.list.thumbnail') }</div>

				<div className={ cellClass }>{ t('projects.list.name') }</div>

				<div className={ cellClass }>{ t('projects.list.createDate') }</div>

				<div className={ cellClass }>{ t('projects.list.modifyDate') }</div>

				<div className={ cellClass } />

				<div className={ cellClass } />
			</div>
		</div>
	)
}

export { AccountProjectsItemsHead }
