import queryString from 'query-string'

import { ISearchProductsParams } from '~/api/dataTypes/catalog'

const SERIALIZER_CONFIG = {
	skipNull: true,
	skipEmptyString: true,
}

export const productListParamsSerializer = (params: ISearchProductsParams): string => queryString.stringify(params, SERIALIZER_CONFIG)
