import React, { MouseEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { IPaginationSmallButtonProps } from '~/components/core/paginationSmall'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { Link } from '~/components/core/link'

import styles from './PaginationSmallPaginationButton.module.scss'

export const PaginationSmallPrevPageButton = (props: IPaginationSmallButtonProps): ReactElement => {
	const {
		onClick,
		url = '',
	} = props
	const { t } = useTranslation(['common'])

	const handleClickUrl = useCallback((event: MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault()
		onClick()
	}, [onClick])

	const renderContent = useCallback((): ReactElement => {
		return (
			<Icon
				name="arrow"
				width={ 8 }
				height={ 12 }
				color="navy"
				additionalClass={ styles.pageButtonIcon }
			/>
		)
	}, [])

	if (!isEmpty(url)) {
		return (
			<Link
				href={ url }
				additionalClass={ styles.prevPageButton }
				ariaLabel={ `${ t('pagination.page') } ${ t('pagination.prevPage') }` }
				onClick={ handleClickUrl }
			>
				{ renderContent() }
			</Link>
		)
	}

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ styles.prevPageButton }
			ariaLabel={ `${ t('pagination.page') } ${ t('pagination.prevPage') }` }
			onClick={ onClick }
		>
			{ renderContent() }
		</Button>
	)
}
