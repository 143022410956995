import React, { ReactElement } from 'react'

import { ISeriesCarouselWrapperProps } from '~/components/core/seriesCarousel'

import styles from './SeriesCarouselWrapper.module.scss'

const SeriesCarouselWrapper = (props: ISeriesCarouselWrapperProps): ReactElement => {
	const {
		children,
		dataTestId = '',
	} = props

	return (
		<section className={ styles.wrapper } data-testid={ dataTestId }>
			{ children }
		</section>
	)
}

export { SeriesCarouselWrapper }
