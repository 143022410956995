import React, { ReactElement } from 'react'

import { Loader } from '~/components/core/loader'
import { IProductListLoaderProps } from '~/components/productList/productListLoader'

import styles from './ProductListLoader.module.scss'

const ProductListLoader = (props: IProductListLoaderProps): ReactElement | null => {
	const { isLoading } = props

	if (!isLoading) {
		return null
	}

	return (
		<Loader fullPage theme={ { overlay: styles.overlay } } />
	)
}

export { ProductListLoader }
